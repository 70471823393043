import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

const API = (store, logger) => {
  const errorLink = onError(({ networkError }) => {
    if (
      networkError?.result?.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED' ||
      networkError?.statusCode === 401
    ) {
      logger.error(`Apollo client network error code: ${networkError?.statusCode}`);
      store.forceLogout(true);
    }
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      credentials: 'include',
      uri: process.env.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    credentials: 'include',
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};

export default API;
