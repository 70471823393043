import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';

import CrmSimpleModal from 'components/common/CrmSimpleModal';
import CrmInputCheckbox from 'components/common/CrmInputCheckbox';
import CrmInputRadioButton from 'components/common/CrmInputRadioButton';
import CrmButton from 'components/common/CrmButton';
import useNewsletter from 'hooks/useNewsletter';
import './newsletter-modal.scss';

const NewsletterModal = () => {
  const {
    isOpenModal,
    subscriptionValue,
    privacyPolicyValue,
    showErrorPrivacy,
    isLoading,
    isDisabledConfirmAction,
    isDisabledPrivacy,
    privacyDocumentHref,
    onClickConfirmAction,
    onClickCancelAction,
    onChangeSubscription,
    onChangePrivacy,
  } = useNewsletter();
  const intl = useIntl();

  return (
    <CrmSimpleModal
      open={isOpenModal}
      title={intl.formatMessage({
        id: 'newsletter.title',
        defaultMessage: 'TECMA Business Platform Updates & News',
      })}
      extraTitleClasses="newsletter-modal__title"
      cancelLabel={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
      dialogClasses={{ paper: 'newsletter-modal' }}
      cancelAction={onClickCancelAction}
      onClose={onClickCancelAction}
      customConfirmButton={
        <CrmButton
          onClick={onClickConfirmAction}
          disabled={isDisabledConfirmAction}
          className="modal-action-button newsletter-modal__button save"
        >
          {isLoading ? (
            <CircularProgress color="secondary" className="newsletter-modal__loader" />
          ) : (
            intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })
          )}
        </CrmButton>
      }
    >
      <div className="newsletter-modal__content">
        <span className="newsletter-modal__description">
          <FormattedMessage
            id="newsletter.description"
            values={{
              br: <br />,
              b: (word) => <b>{word}</b>,
            }}
            defaultMessage="Stay updated on the latest news! {br} Would you like to subscribe to our <b>newsletter</b>?"
          />
        </span>
        <CrmInputRadioButton
          onChange={onChangeSubscription}
          value={subscriptionValue}
          canResetChoice={false}
          extraClass="newsletter-modal__radio-buttons"
          options={[
            { value: true, label: intl.formatMessage({ id: 'label.si', defaultValue: 'Yes' }) },
            { value: false, label: intl.formatMessage({ id: 'label.no', defaultValue: 'No' }) },
          ]}
        />
        <div className="newsletter-modal__agreement">
          <CrmInputCheckbox
            extraClass="newsletter-modal__privacy"
            error={showErrorPrivacy}
            disabled={isDisabledPrivacy}
            onChange={onChangePrivacy}
            required
            value={privacyPolicyValue}
          />
          <span className="newsletter-modal__agreement__text">
            <FormattedMessage
              id="newsletter.policy-agreement"
              defaultMessage="I have read the <a>Privacy Policy</a> and agree to the processing of my personal data."
              values={{
                a: (text) => (
                  <a rel="noreferrer" target="_blank" href={privacyDocumentHref}>
                    {text}
                  </a>
                ),
              }}
            />
          </span>
        </div>
        {showErrorPrivacy && (
          <span className="newsletter-modal__error">
            {intl.formatMessage({
              id: 'newsletter.privacy-error',
              defaultMessage: 'You must accept the processing of your personal data.',
            })}
          </span>
        )}
      </div>
    </CrmSimpleModal>
  );
};

export default observer(NewsletterModal);
