import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';

import { useStore } from 'store/storeUtils';
import UserService from 'client/api/user';
import Client from 'client/Client';

const useNewsletter = () => {
  const userService = new UserService();
  const store = useStore();
  const intl = useIntl();
  const userNewsletterConsents = store.loggedUser?.consents?.newsletter;

  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState(userNewsletterConsents?.subscription);
  const [privacyPolicy, setPrivacyPolicy] = useState(userNewsletterConsents?.privacyPolicy);
  const [showErrorPrivacy, setShowErrorPrivacy] = useState(false);

  const loadUserInfo = useQuery(Client.GET_USER_INFO, {
    ...Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId),
    skip: true,
  });

  const isSubscriptionNotChosen = typeof subscription !== 'boolean';
  const isPrivacyNotChosen = typeof privacyPolicy !== 'boolean';

  const getIsDisabledPrivacy = () => {
    return (
      isSubscriptionNotChosen || subscription === false || userNewsletterConsents?.privacyPolicy
    );
  };

  const getIsDisabledSave = () => {
    if (isSubscriptionNotChosen && isPrivacyNotChosen) return true;
    return !!(
      subscription === userNewsletterConsents?.subscription &&
      privacyPolicy === userNewsletterConsents?.privacyPolicy
    );
  };

  const needResetShowError = () => {
    if (showErrorPrivacy) {
      setShowErrorPrivacy(false);
    }
  };

  const handleOnChangeSubscription = (_, val) => {
    if (isLoading) return;
    if (val === 'false' && !userNewsletterConsents?.privacyPolicy) {
      setPrivacyPolicy(false);
    }
    needResetShowError();
    setSubscription(val === 'true');
  };

  const handleOnChangePrivacy = () => {
    if (isLoading) return;
    needResetShowError();
    setPrivacyPolicy((curr) => !curr);
  };

  const handleOnConfirmNewsletter = async () => {
    if (isLoading) return;
    if (subscription && !privacyPolicy) {
      setShowErrorPrivacy(true);
      return;
    }
    setIsLoading(true);
    try {
      const response = await userService.subscribeToNewsletterApi(
        store?.loggedUser?.id,
        store?.projectId,
        {
          projectHostKey: store.hostKey,
          privacyPolicy,
          subscription,
          projectDefaultLang: store.defaultLang ?? 'en-GB',
        },
      );
      if (response.ok) {
        const response = await loadUserInfo.refetch();
        store.setSnackbar(true, intl.formatMessage({ id: 'snackbar.successOperation' }));
        store.setLoggedConsentsData(response?.data?.getUserByJWT?.consents);
        store.setOpenNewsletterModal(false);
      } else {
        store.setSnackbarError(
          true,
          `${intl.formatMessage({ id: 'snackbar.failedOperation' })}, ${response?.message}`,
        );
      }
    } catch (e) {
      console.error(e);
      store.setSnackbarError(
        true,
        `${intl.formatMessage({ id: 'snackbar.failedOperation' })}, ${e?.message}`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClickCancel = () => {
    setSubscription(userNewsletterConsents?.subscription);
    setPrivacyPolicy(userNewsletterConsents?.privacyPolicy);
    setShowErrorPrivacy(false);
    store.setOpenNewsletterModal(false);
  };

  const getPrivacyDocumentHref = () => {
    const currentLanguage = intl.locale.split('-')?.[0] !== 'it' ? 'en' : 'it';
    const documentUrl = `${process.env.REACT_APP_BUCKET_BASEURL}businessplatform/pdf/newsletterPrivacyPolicy/${currentLanguage}/Privacy Policy Newsletter.pdf`;
    return documentUrl;
  };

  return {
    isOpenModal: store.openNewsletterModal,
    subscriptionValue: subscription,
    privacyPolicyValue: privacyPolicy,
    showErrorPrivacy,
    isLoading,
    isDisabledConfirmAction: getIsDisabledSave(),
    isDisabledPrivacy: getIsDisabledPrivacy(),
    privacyDocumentHref: getPrivacyDocumentHref(),
    onClickCancelAction: handleOnClickCancel,
    onClickConfirmAction: handleOnConfirmNewsletter,
    onChangeSubscription: handleOnChangeSubscription,
    onChangePrivacy: handleOnChangePrivacy,
  };
};

export default useNewsletter;
