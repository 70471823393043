import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils.js';
import { useLazyQuery } from '@apollo/client';
import Client from 'client/Client';

const JwtChecker = () => {
  const store = useStore();
  const skipCondition = window.location.pathname.includes('resetPassword');
  const [loadUserInfo, userInfo] = useLazyQuery(
    Client.GET_USER_INFO,
    Client.GET_USER_INFO_DEFAULT_OPTIONS_V2(store.projectId, skipCondition),
  );

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called && userInfo.data) {
      store.setLoggedUserJwtData({ userData: userInfo.data.getUserByJWT, checkingJwt: false });
    }
  }, [userInfo, !userInfo.loading, userInfo.called, userInfo.data]);

  function handleVisibilityChange() {
    if (!document.hidden && !skipCondition) {
      store.setCheckingJwt(true);
      loadUserInfo();
    }
  }

  React.useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return <></>;
};

export default JwtChecker;
