const Initials = (firstName, lastName) => {
  if (firstName && lastName) {
    return firstName[0] + lastName[0];
  }
  if (firstName) {
    return firstName[0];
  }
  if (lastName) {
    return lastName[0];
  }
  return '-';
};

export default Initials;
