export const CalculateAptStatus = (status, enabledStatus, label) => {
  var defaultLabel = label ? label : status;
  return enabledStatus
    ? enabledStatus[status] && enabledStatus[status].enabled
      ? enabledStatus[status].label
        ? enabledStatus[status].label
        : defaultLabel
      : null
    : defaultLabel;
};

export const FilterAptStatus = (statusList, enabledStatus) => {
  return statusList
    .filter((opt) => {
      return enabledStatus
        ? enabledStatus[opt.value] && enabledStatus[opt.value].enabled
          ? true
          : false
        : true;
    })
    .map((res) => {
      return { value: res.value, label: CalculateAptStatus(res.value, enabledStatus, res.label) };
    });
};
