import React from 'react';
import PropTypes from 'prop-types';

/**
 * react suspense wrapper with default fallback
 * @component
 */
const CrmSuspense = ({ children }) => {
  return <React.Suspense fallback={<div>Loading...</div>}>{children}</React.Suspense>;
};

/** prop types */
CrmSuspense.propTypes = {
  /** children: required */
  children: PropTypes.node.isRequired,
};

export default CrmSuspense;
