import React from 'react';
import PropTypes from 'prop-types';
import CrmCard from 'components/common/CrmCard';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import 'styles/userInfoManager.scss';

/**
 * Default appBar
 * based on material-ui appBar
 * Unknown props are passed down to the MUI underlying component
 * @component
 * @see {@link https://material-ui.com/api/app-bar/ AppBar API}
 */
const CrmAppBar = (props) => {
  const { extraClasses, handleChange, value, tabList, section, variant } = props;
  const intl = useIntl();

  const useStyles = makeStyles((theme) => ({
    indicator: {
      backgroundColor: 'white',
    },
    tabRoot: {
      backgroundColor: 'white',
      maxWidth: '12.5rem',
      lineHeight: '1.33rem',
      textAlign: 'left',
    },
  }));

  const classes = useStyles();

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      scrollButtons="off"
      variant={variant}
      classes={{ indicator: 'indicator-style' }}
      className={classes.root}
    >
      {tabList.map((tab, idx) => [
        <Tab
          value={idx}
          icon={tab.icon && <CrmSvgLoader data={tab.icon} alt={tab.name} />}
          label={intl.formatMessage({
            id: `label.tab.${tab.name}`,
            defaultMessage: tab.name,
          })}
          id={`${section} ${tab.id}`}
          key={idx}
          disableRipple={true}
          classes={{ root: classes.tabRoot }}
        />,
        value !== idx && <Badge badgeContent={tab.badgeValue} showZero={false} />,
      ])}
    </Tabs>
  );
};

CrmAppBar.defaultProps = {
  extraClasses: '',
  variant: 'scrollable',
};

/** prop types */
CrmAppBar.propTypes = {
  /** extraClass: additional className */
  extraClasses: PropTypes.string,
  section: PropTypes.string.isRequired,
};

export default CrmAppBar;
