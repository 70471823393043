import React, { createRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Skeleton from '@material-ui/lab/Skeleton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import CrmCard from 'components/common/CrmCard';
import CrmAppBar from 'components/common/CrmAppBar'; 
import CrmLoader from 'components/common/CrmLoader';
import CrmTable from 'components/common/CrmTable';
import ListaPreventiviDashboardTableConfig from 'tableConfig/ListaPreventiviDashboardTableConfig';
import ListaClientiDashboardTableConfig from 'tableConfig/ListaClientiDashboardTableConfig';
import ChartConstants from 'constants/ChartConstants';
import DoughnutReChart from 'components/charts/DoughnutReChart';
import VerticalBarReChart from 'components/charts/VerticalBarReChart';
import HorizontalBarReChart from 'components/charts/HorizontalBarReChart';
import ListaNotificationsTableConfig from 'tableConfig/ListaNotificationsTableConfig';
import ListaServicesTableConfig from 'tableConfig/ListaServicesTableConfig';
import {
  clientDetailRoute,
  clientiRoute,
  addNotificationForClientRoute,
  addNotificationForEveryoneRoute,
} from 'routes/Routes';
import Client from 'client/Client';
import { useStore } from 'store/storeUtils.js';
import Capitalize from 'utils/Capitalize';
import getFilteredArray from 'utils/GetFilteredArray';
import findInMap from 'utils/findInMap';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import receivedMail from 'images/assets/receivedMail.svg';
import calendarAppointments from 'images/assets/calendarAppointments.svg';
import managedAppointment from 'images/assets/managedAppointment.svg';
import CrmScreenshotButton from 'components/common/CrmScreenshotButton';
import CrmContainer from 'components/common/CrmContainer';
import SendToClient from 'images/assets/send-to-client.svg';
import { ADMIN, BUILDING_MANAGER, VENDOR } from 'constants/PagePermission';

const TAB_LIST = [
  {
    name: 'Overall',
    id: 'Overall',
  },
];

const NOTIFICATION_TAB_LIST = [
  {
    name: 'Incoming',
    id: 'Incoming',
  },
  {
    name: 'Alerts',
    id: 'Alerts',
  },
];

/**
 * Home page
 * Contiene le tabelle clienti e appartamenti, oltre ai grafici
 * @component
 * @see CrmTable
 * @see ListaClienti
 * @see ListaAppartamenti
 * @see DoughnutReChart
 * @see HorizontalBarReChart
 * @see VerticalBarReChart
 * @see FatturatoWrapper
 */
const Home = () => {
  const intl = useIntl();
  const history = useHistory();
  const store = useStore();

  const screenshotRef = createRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [typologiesSorted, setTypologiesSorted] = React.useState([]);
  const [activeNotificationTab, setActiveNotificationTab] = React.useState(0);
  const crmContainerClasses = {
    containerStyle: 'add-notification-dashboard',
    imageStyle: 'add-notification-image-container-dashboard',
    titleStyle: 'add-notification-text-dashboard',
    subtitleStyle: 'add-notification-subtitle-dashboard',
    buttonStyle: 'notification-table-button-dashboard',
    containerMargin: 'add-notification-margin',
    buttonContainer: 'button-container-notifications',
  };
  const [appartmentsCall, appartments] = useLazyQuery(
    Client.GET_ALL_APPARTMENTS,
    Client.GET_ALL_APPARTMENTS_DEFAULT_OPTIONS(store.projectId),
  );
  const [clientsCall, clients] = useLazyQuery(
    Client.GET_ALL_CLIENTS,
    Client.GET_ALL_CLIENTS_DEFAULT_OPTIONS(store.projectId),
  );
  const [typologiesCall, typologies] = useLazyQuery(
    Client.GET_TYPOLOGIES,
    Client.GET_TYPOLOGIES_DEFAULT_OPTIONS(store.projectId),
  );
  const [projectInfoCall, projectInfo] = useLazyQuery(
    Client.GET_PROJECT_INFORMATIONS,
    Client.GET_PROJECT_INFORMATIONS_DEFAULT_OPTIONS(store.projectId),
  );
  const [communityCalendarsCall, communityCalendars] = useLazyQuery(
    Client.GET_COMMUNITY_CALENDAR_BY_PROJECT,
    Client.GET_COMMUNITY_CALENDAR_BY_PROJECT_DEFAULT_OPTIONS(store.projectId),
  );
  const [newNotificationsCall, newNotifications] = useLazyQuery(
    Client.GET_ALL_NOTIFICATIONS,
    Client.GET_ALL_NOTIFICATIONS_OPTIONS({
      project_id: store.projectId,
      filters: {
        states: ['SENT'],
        from: 'CLIENT',
        archived: false,
      },
    }),
  );
  const [allAlertsDataCall, allAlertsData] = useLazyQuery(
    Client.GET_ALL_NOTIFICATIONS,
    Client.GET_ALL_NOTIFICATIONS_OPTIONS({
      project_id: store.projectId,
      filters: {
        states: ['IN_PROGRESS', 'SENT', 'READ'],
        from: 'CLIENT',
        archived: false,
      },
    }),
  );

  React.useEffect(() => {
    const userCanSeeNotifications = [BUILDING_MANAGER, ADMIN, VENDOR].includes(
      store.loggedUser.role,
    ) && store.isMyLivingActive;
    if (userCanSeeNotifications) {
      communityCalendarsCall();
      newNotificationsCall();
      allAlertsDataCall();
    }

    if (
      store.projectId &&
      !appartments.called &&
      !clients.called &&
      !typologies.called &&
      !projectInfo.called &&
      store.loggedUser.role !== BUILDING_MANAGER
    ) {
      appartmentsCall();
      typologiesCall();
      clientsCall();
      projectInfoCall();
    }
  }, [store.isMyLivingActive]);

  React.useEffect(() => {
    if (
      appartments.error &&
      !appartments.error.message.includes('Observable cancelled prematurely')
    ) {
      store.setSnackbarError(
        true,
        intl.formatMessage({
          id: 'snackbar.errorUploadApartment',
          defaultMessage: 'Error while loading the apartments',
        }),
      );
    }
    // eslint-disable-next-line
  }, [appartments.error]);

  React.useEffect(() => {
    if (
      newNotifications.error &&
      !newNotifications.error.message.includes('Observable cancelled prematurely')
    ) {
      store.setSnackbarError(
        true,
        intl.formatMessage({
          id: 'snackbar.errorUploadNotifications',
          defaultMessage: 'Error while loading the notifications',
        }),
      );
    }
    // eslint-disable-next-line
  }, [newNotifications.error]);

  React.useEffect(() => {
    if (
      communityCalendars.error &&
      !communityCalendars.error.message.includes('Observable cancelled prematurely')
    ) {
      store.setSnackbarError(
        true,
        intl.formatMessage({
          id: 'snackbar.errorUploadCommunityCalendar',
          defaultMessage: 'Error while loading the services prenotations',
        }),
      );
    }
    // eslint-disable-next-line
  }, [communityCalendars.error]);

  React.useEffect(() => {
    if (
      allAlertsData.error &&
      !allAlertsData.error.message.includes('Observable cancelled prematurely')
    ) {
      store.setSnackbarError(
        true,
        intl.formatMessage({
          id: 'snackbar.errorUploadAlerts',
          defaultMessage: 'Error while loading the alerts',
        }),
      );
    }
    // eslint-disable-next-line
  }, [allAlertsData.error]);

  React.useEffect(() => {
    if (clients.error && !clients.error.message.includes('Observable cancelled prematurely')) {
      store.setSnackbarError(
        true,
        intl.formatMessage({
          id: 'snackbar.errorUploadClient_',
          defaultMessage: 'Error while loading clients',
        }),
      );
    }
    // eslint-disable-next-line
  }, [clients.error]);

  React.useEffect(() => {
    if (
      typologies.error &&
      !typologies.error.message.includes('Observable cancelled prematurely')
    ) {
      store.setSnackbarError(
        true,
        intl.formatMessage({
          id: 'snackbar.errorUploadTypologyApart',
          defaultMessage: 'Error while loading apartment types',
        }),
      );
    }
    // eslint-disable-next-line
  }, [typologies.error]);

  React.useEffect(() => {
    if (typologies.data) {
      setTypologiesSorted(
        typologies.data.getAllTypologies.slice(0).sort((a, b) => {
          if (a.rooms > b.rooms) {
            return 1;
          }
          if (a.rooms < b.rooms) {
            return -1;
          }
          return 0;
        }),
      );
    }
    // eslint-disable-next-line
  }, [typologies.data]);

  const handleChangeTab = (_, newValue) => {
    setActiveTab(newValue);
  };

  const handleNotificationChangeTab = (event, newValue) => {
    setActiveNotificationTab(newValue);
  };

  const clickSendNotification = (destination) => {
    store.setRealLocation(destination.to);
    history.push(destination.to);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const graphsData = appartments.data
    ? appartments.data.getAllAppartments.map((apt) => ({
        ...apt,
        status: apt.status === 'temporaneamente_sospeso' ? 'libero' : apt.status,
      }))
    : [];
  const allNotifications = newNotifications?.data?.getAllNotifications?.data
    ? newNotifications.data.getAllNotifications.data.sort(
        (a, b) => a.createdOn.valueOf() - b.createdOn.valueOf(),
      )
    : [];
  const allAlerts = allAlertsData?.data?.getAllNotifications?.data
    ? allAlertsData.data.getAllNotifications.data.filter((el) => {
        const date = new Date(el.createdOn);
        const diff = moment().diff(moment(date), 'days');
        return diff >= store.alertDays;
      })
    : [];
  const servicesData = communityCalendars.data
    ? [...communityCalendars.data.getCommunityCalendarsByProject]
    : [];
  const servicesDataOrder = servicesData
    ? servicesData.filter((a) => moment(a.startDate).isAfter(moment())).sort((a, b) => a - b)
    : [];

  const verticalGraphsData = (arrData) => {
    return arrData
      ? typologiesSorted.map((size) => {
          const res = {
            label: Capitalize(findInMap(ChartConstants.shortNames, size.name)),
          };
          const appartmentsBySize = arrData.filter((app) => app.plan.typology.name === size.name);
          ChartConstants.availableStatus.map(
            (stat) => (res[stat] = getFilteredArray(appartmentsBySize, 'status', stat).length),
          );
          return res;
        })
      : [];
  };

  const goToClient = (id) => {
    store.setClientId(id);
    store.setBreadCrumbs(clientiRoute.title);
    const destination = clientDetailRoute.to.replace(':id', id);
    store.setRealLocation(destination);
    history.push(destination);
  };

  const deleteEmptyVertical = (data) => {
    const toReturnArray = [];
    for (let i = 0; i < data.length; i++) {
      const myElement = data[i];
      let toDelete = true;
      for (const property in myElement) {
        if (myElement[property] > 0) {
          toDelete = false;
          break;
        }
      }
      if (!toDelete) {
        toReturnArray.push(myElement);
      }
    }
    return toReturnArray;
  };

  const horizontalGraphData = (arrayData) => {
    return arrayData
      ? ChartConstants.availableStatus.map((stat) => {
          const res = { label: stat };
          res.data = getFilteredArray(arrayData, 'status', stat).length;
          return [res];
        })
      : [];
  };

  const pieGraphData = graphsData
    ? ChartConstants.availableStatus.map((stat) => {
        const res = { label: stat };
        res.data = getFilteredArray(graphsData, 'status', stat)
          .map((item) => item.price)
          .reduce(function (a, b) {
            return a + b;
          }, 0);

        return res;
      })
    : [];

  const saveSearchClienti = (value) => {
    store.setSearchClientiHome(value);
  };
  const saveSearchAppartamenti = (value) => {
    store.setSearchAppartamentiHome(value);
  };

  const fatturato = graphsData
    ? graphsData
        .map((item) => item.price)
        .reduce(function (a, b) {
          return a + b;
        }, 0)
    : 0;

  let barSize = 140;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const matchesMobile = useMediaQuery('(max-width:600px)');

  if (matches) {
    barSize = 60;
  }

  const getProjectInfoCounter = (data) => {
    if (projectInfo?.data?.getProjectInformations) {
      if (data === 'appointments') {
        const activeAppointments =
          projectInfo.data.getProjectInformations.find((info) => info.name === 'event').numero || 0;
        const deletedAppointments =
          projectInfo.data.getProjectInformations.find((info) => info.name === 'deletedEvent')
            .numero || 0;
        return activeAppointments + deletedAppointments;
      }
      return projectInfo.data.getProjectInformations.find((info) => info.name === data).numero || 0;
    }
    return 0;
  };

  return (
    <div className="main-container" ref={screenshotRef}>
      <CrmScreenshotButton screenshotClassName="main-container" screenshotRef={screenshotRef} />

      <div className="dashboard-info">
        <div className="flex-column" style={{ lineHeight: '25px' }}>
          <div className="dashboard-info-title">
            {intl.formatMessage(
              {
                id: 'dashboard.goodMorning',
                defaultMessage: 'Good Morning {user}',
              },
              { user: store.loggedUser.firstName.toLowerCase() },
            )}
          </div>
          <div className="small-font-14">
            <FormattedMessage
              id="dashboard.salesTrend"
              defaultMessage="Explore the rents trend of your property"
            />
          </div>
        </div>
      </div>

      {(store.loggedUser.role === BUILDING_MANAGER || store.loggedUser.role === ADMIN) && store.isMyLivingActive && (
        <Grid
          className="dashboard-container"
          container
          style={{ paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}
        >
          <Grid item xs={12}>
            <CrmAppBar
              handleChange={handleChangeTab}
              value={activeTab}
              tabList={TAB_LIST}
              extraClasses="white"
              section="dashboard"
            />
          </Grid>
          <Grid item xs={12}>
            <CrmCard style={{ overflow: 'visible', padding: '0px', height: '40% !important' }}>
              {activeTab === 0 && (
                <>
                  <Grid className="notification-container grey-border" container>
                    <Grid item xs={12} sm={6} className="add-notification-sub-container-top">
                      <CrmContainer
                        containerClasses={crmContainerClasses}
                        buttonClick={() => clickSendNotification(addNotificationForEveryoneRoute)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="add-notification-sub-container-top">
                      <CrmContainer
                        containerClasses={crmContainerClasses}
                        svgImage={SendToClient}
                        title={{
                          label: 'notification.sendToClient',
                          defaultMessage: 'Send To Client',
                        }}
                        subtitle={{
                          label: 'notification.sendToClientMessage',
                          defaultMessage: 'Notify specific customer',
                        }}
                        buttonClick={() => clickSendNotification(addNotificationForClientRoute)}
                      />
                    </Grid>
                  </Grid>

                  <div className="elements-spacing grey-border-table">
                    <div style={{ fontFamily: 'SagoeBold', marginBottom: '10px' }}>
                      <FormattedMessage
                        id="dashboard.notifications"
                        defaultMessage="Notification"
                      />
                    </div>
                    <div
                      style={{ fontFamily: 'SagoeRegular', fontSize: '14px', marginBottom: '8px' }}
                    >
                      {intl.formatMessage(
                        {
                          id: 'dashboard.newNotificationsAndAlerts',
                          defaultMessage:
                            'You have {notifications} unread notifications and {alerts} alerts',
                        },
                        {
                          notifications: newNotifications?.data?.getAllNotifications?.count,
                          alerts: allAlerts.length > 99 ? '+99' : allAlerts.length,
                        },
                      )}
                    </div>
                    <Grid item xs={12} style={{ position: 'relative', zIndex: '2' }}>
                      <CrmAppBar
                        handleChange={handleNotificationChangeTab}
                        value={activeNotificationTab}
                        tabList={NOTIFICATION_TAB_LIST}
                        extraClasses="white"
                        style={{ backgroundColor: 'white !important' }}
                        section="dashboard"
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} md={12} lg={12} style={{ position: 'relative' }}>
                      {newNotifications.loading && !allNotifications.length && (
                        <CrmLoader loading hasBackdrop={false} />
                      )}
                      <CrmTable
                        data={activeNotificationTab === 0 ? allNotifications : allAlerts}
                        paging
                        pageSize={5}
                        hideHeader={true}
                        columns={ListaNotificationsTableConfig(goToClient)}
                      />
                    </Grid>
                  </div>
                  <div className="elements-spacing grey-border-table">
                    <div style={{ fontFamily: 'SagoeBold', marginBottom: '10px' }}>
                      <FormattedMessage
                        id="dashboard.latestBookings"
                        defaultMessage="Latest bookings on the services"
                      />
                    </div>
                    <Grid item xs={10} sm={10} md={12} lg={12} style={{ position: 'relative' }}>
                      {communityCalendars.loading && !servicesDataOrder.length && (
                        <CrmLoader loading hasBackdrop={false} transparency />
                      )}
                      <CrmTable
                        data={servicesDataOrder}
                        paging
                        pageSize={5}
                        hideHeader={true}
                        columns={ListaServicesTableConfig(goToClient)}
                      />
                    </Grid>
                  </div>
                </>
              )}
            </CrmCard>
          </Grid>
        </Grid>
      )}

      {store.loggedUser.role !== BUILDING_MANAGER && (
        <Grid container>
          <div className="dashboard-info">
            <div className="dashboard-info-items">
              <div className="flex" style={{ marginRight: '16px' }}>
                <CrmSvgLoader data={receivedMail} alt="receivedMail" />
                <div className="small-font-12" style={{ paddingLeft: '8px', paddingTop: '7px' }}>
                  {!matchesMobile && (
                    <span className="semi-bold">
                      <FormattedMessage
                        id="dashboard.contacts.contactTypology.mail_recived"
                        defaultMessage="Received Emails: "
                      />
                    </span>
                  )}
                  {projectInfo.loading ? (
                    <Skeleton width={16} style={{ display: 'inline-block' }} />
                  ) : (
                    <span className="font-bold black">{getProjectInfoCounter('mail')}</span>
                  )}
                </div>
              </div>
              <div className="flex" style={{ marginRight: '16px' }}>
                <CrmSvgLoader data={calendarAppointments} alt="calendarAppointments" />
                <div
                  aria-describedby={id}
                  className="small-font-12"
                  style={{ paddingLeft: '8px', paddingTop: '7px', cursor: 'pointer' }}
                  onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                >
                  {!matchesMobile && (
                    <span className="semi-bold">
                      <FormattedMessage
                        id="dashboard.contacts.contactTypology.appointments_fix"
                        defaultMessage="Scheduled Appointments: "
                      />
                    </span>
                  )}
                  {projectInfo.loading ? (
                    <Skeleton width={16} style={{ display: 'inline-block' }} />
                  ) : (
                    <span className="font-bold black">{getProjectInfoCounter('appointments')}</span>
                  )}
                </div>
              </div>
              <Popper id={id} open={open} anchorEl={anchorEl} transition>
                {() => (
                  <>
                    <CrmCard className="small-font-12 " style={{ padding: '8px' }}>
                      <span className="">
                        <FormattedMessage
                          id="dashboard.contacts.contactTypology.appointments_deleted"
                          defaultMessage="Deleted Appointments: "
                        />
                      </span>
                      {projectInfo.loading ? (
                        <Skeleton width={16} style={{ display: 'inline-block' }} />
                      ) : (
                        <span className="font-bold black">
                          {getProjectInfoCounter('deletedEvent')}
                        </span>
                      )}
                    </CrmCard>
                  </>
                )}
              </Popper>
              <div className="flex">
                <CrmSvgLoader data={managedAppointment} alt="managedAppointment" />
                <div className="small-font-12" style={{ paddingLeft: '8px', paddingTop: '7px' }}>
                  {!matchesMobile && (
                    <span className="semi-bold">
                      <FormattedMessage
                        id="dashboard.contacts.contactTypology.appointments_mgmt"
                        defaultMessage="Managed Appointments: "
                      />
                    </span>
                  )}
                  {projectInfo.loading ? (
                    <Skeleton width={16} style={{ display: 'inline-block' }} />
                  ) : (
                    <span className="font-bold black">{getProjectInfoCounter('movement')}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {(!store.dashboardConfig ||
            (store.dashboardConfig && store.dashboardConfig.status_economico)) && (
            <Grid item xs={10} sm={10} md={12} lg={6} className="border-grid">
              <CrmCard style={{ overflow: 'visible', padding: '0px', position: 'relative' }}>
                {(!store.projectId ||
                  (appartments.loading && !appartments.data?.getAllAppartments)) && (
                  <CrmLoader loading hasBackdrop={false} />
                )}
                <>
                  <div className={!matches ? 'flex-row' : 'flex-column'}>
                    <div
                      className={
                        !matches
                          ? 'flex-1 flex-container-start flex-row elements-spacing semi-bold small-font-14'
                          : 'elements-spacing-title-small flex-1 flex-container-start semi-bold small-font-14'
                      }
                    >
                      <FormattedMessage
                        id="dashboard.apartmentStatus"
                        defaultMessage="Apartments Status"
                      />
                    </div>
                    <div
                      className={
                        !matches
                          ? 'flex-row elements-spacing flex-container-end flex-1'
                          : 'flex-row elements-spacing-small flex-container-start flex-1'
                      }
                    >
                      <span className="small-font-14" style={{ textTransform: 'capitalize' }}>
                        Total Number:
                      </span>
                      <span className="semi-bold small-font-14" style={{ paddingLeft: '10px' }}>
                        {appartments.data && appartments.data.getAllAppartments
                          ? appartments.data.getAllAppartments.filter((apt) => apt.forRent).length
                          : 0}
                      </span>
                    </div>
                  </div>
                  <DoughnutReChart
                    data={horizontalGraphData(graphsData)}
                    innerRadius={50}
                    outerRadius={75}
                    height={250}
                  />
                </>
              </CrmCard>
            </Grid>
          )}

          {(!store.dashboardConfig ||
            (store.dashboardConfig.status_apartment &&
              !store.dashboardConfig.status_apartment_venditeAffitto)) && (
            <Grid item xs={10} sm={10} md={12} lg={6} className="border-grid">
              <CrmCard style={{ overflow: 'visible', padding: '0px', position: 'relative' }}>
                {(!store.projectId ||
                  (appartments.loading && !appartments.data?.getAllAppartments)) && (
                  <CrmLoader loading hasBackdrop={false} />
                )}
                <div className="flex-row">
                  <div
                    className="flex-1 flex-container-start flex-row elements-spacing semi-bold small-font-14"
                    style={{ paddingLeft: '5px' }}
                  >
                    <FormattedMessage
                      id="dashboard.economicStatus"
                      defaultMessage="Economic Status"
                    />
                  </div>
                  <div className="flex-row elements-spacing flex-container-end flex-1">
                    <span className="small-font-14" style={{ minWidth: 'fit-content' }}>
                      {' '}
                      <FormattedMessage
                        id="dashboard.economicStatus.totalTurnover"
                        defaultMessage="Total Turnover"
                      />
                      {':'}
                    </span>
                    <span
                      className="semi-bold small-font-14"
                      style={{ paddingLeft: '10px', paddingRight: '7px' }}
                    >
                      {CurrencyFormatter(store.currency, store.locale, fatturato)}
                    </span>
                  </div>
                </div>
                <div className="elements-spacing">
                  <HorizontalBarReChart data={pieGraphData} />
                </div>
              </CrmCard>
            </Grid>
          )}
          {store.dashboardConfig &&
            store.dashboardConfig.status_apartment &&
            store.dashboardConfig.status_apartment_venditeAffitto && (
              <Grid item xs={10} sm={10} md={12} lg={12} className="border-grid">
                <CrmCard style={{ overflow: 'visible', padding: '0px', position: 'relative' }}>
                  {(!store.projectId ||
                    (appartments.loading && !appartments.data?.getAllAppartments)) && (
                    <CrmLoader loading hasBackdrop={false} />
                  )}
                  <div className="flex-row">
                    <div
                      className="flex-1 flex-container-start flex-row elements-spacing semi-bold small-font-14"
                      style={{ paddingLeft: '5px' }}
                    >
                      <FormattedMessage
                        id="dashboard.apartmentStatusRent"
                        defaultMessage="Rent Apartments Status"
                      />
                    </div>
                    <div className="flex-row elements-spacing flex-container-end flex-1">
                      <span className="small-font-14" style={{ minWidth: 'fit-content' }}>
                        {' '}
                        <FormattedMessage
                          id="dashboard.economicStatus.totalTurnover"
                          defaultMessage="Total Turnover"
                        />
                        {':'}
                      </span>
                      <span
                        className="semi-bold small-font-14"
                        style={{ paddingLeft: '10px', paddingRight: '7px' }}
                      >
                        {CurrencyFormatter(store.currency, store.locale, fatturato)}
                      </span>
                    </div>
                  </div>
                  <div className="elements-spacing">
                    <HorizontalBarReChart data={pieGraphData} />
                  </div>
                </CrmCard>
              </Grid>
            )}

          {(!store.dashboardConfig ||
            (store.dashboardConfig.status_tagli &&
              !store.dashboardConfig.status_tagli_venditeAffitto)) && (
            <Grid item xs={10} sm={10} md={12} lg={12} className="border-grid">
              <CrmCard style={{ overflow: 'visible', padding: '0px', position: 'relative' }}>
                {(!store.projectId ||
                  (appartments.loading && !appartments.data?.getAllAppartments)) && (
                  <CrmLoader loading hasBackdrop={false} />
                )}
                <div className="elements-spacing semi-bold small-font-14">
                  <FormattedMessage
                    id="dashboard.typologyStatus"
                    defaultMessage="Apartment Typology Status"
                  />
                </div>
                <div className="elements-spacing">
                  <VerticalBarReChart
                    data={deleteEmptyVertical(verticalGraphsData(graphsData))}
                    size={barSize}
                  />
                </div>
              </CrmCard>
            </Grid>
          )}
          {store.dashboardConfig &&
            store.dashboardConfig.status_tagli &&
            store.dashboardConfig.status_tagli_venditeAffitto && (
              <Grid item xs={10} sm={10} md={12} lg={12} className="border-grid">
                <CrmCard style={{ overflow: 'visible', padding: '0px', position: 'relative' }}>
                  {(!store.projectId ||
                    (appartments.loading && !appartments.data?.getAllAppartments)) && (
                    <CrmLoader loading hasBackdrop={false} />
                  )}
                  <div className="elements-spacing semi-bold small-font-14">
                    <FormattedMessage
                      id="dashboard.typologyStatus"
                      defaultMessage="Rent Dimension Status" //Status Tagli Affitto
                    />
                  </div>
                  <div className="elements-spacing">
                    <VerticalBarReChart
                      data={deleteEmptyVertical(
                        verticalGraphsData(appartments?.data?.getAllAppartments),
                      )}
                      size={barSize}
                    />
                  </div>
                </CrmCard>
              </Grid>
            )}
          {
            <Grid
              item
              xs={10}
              sm={10}
              md={12}
              lg={12}
              className="bottom-card main-container"
              ref={screenshotRef}
            >
              <CrmCard style={{ padding: '0px', position: 'relative' }}>
                {(!store.projectId || (clients.loading && !clients.data?.getAllClients)) && (
                  <CrmLoader loading hasBackdrop={false} />
                )}
                <div className="elements-spacing">
                  <CrmTable
                    data={
                      clients.data
                        ? clients.data.getAllClients
                            .slice(0)
                            .sort((a, b) => b.createdOn.localeCompare(a.createdOn))
                        : []
                    }
                    title={
                      <div className="semi-bold small-font-14 table-title">
                        <FormattedMessage
                          id="dashboard.lastMovement"
                          defaultMessage="Last Movements"
                        />
                      </div>
                    }
                    columns={ListaClientiDashboardTableConfig}
                    paging
                    search
                    saveSearch={saveSearchClienti}
                    searchText={store.searchClientiHome}
                  />
                </div>
              </CrmCard>
            </Grid>
          }
          <Grid item xs={10} sm={10} md={12} lg={12} className="bottom-card">
            <CrmCard style={{ padding: '0px', position: 'relative' }}>
              {(!store.projectId ||
                (appartments.loading && !appartments.data?.getAllAppartments)) && (
                <CrmLoader loading hasBackdrop={false} />
              )}
              <div className="elements-spacing">
                <CrmTable
                  data={
                    appartments.data
                      ? appartments.data.getAllAppartments
                          .slice(0)
                          .sort((a, b) => b.updatedOn.localeCompare(a.updatedOn))
                      : []
                  }
                  title={
                    <div className="semi-bold small-font-14 table-title">
                      <FormattedMessage
                        id="dashboard.lastVariations"
                        defaultMessage="Latest Changes In Apartment Status"
                      />
                    </div>
                  }
                  columns={ListaPreventiviDashboardTableConfig(store.currency, store.locale)}
                  paging
                  search
                  searchText={store.searchAppartamentiHome}
                  saveSearch={saveSearchAppartamenti}
                />
              </div>
            </CrmCard>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Home;
