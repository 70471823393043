import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { logger, injectContextHeaders } from '@tecma/logs-fe';
import { WinstonProvider } from 'winston-react/dist/context';
import { ApolloProvider } from '@apollo/client';
import { create } from 'mobx-persist';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { App } from 'App';
import ErrorPage from 'pages/ErrorPage';
import Store from 'store/store.js';
import { StoreProvider } from 'store/storeUtils.js';
import { CrmMUITheme } from 'styles/crmMUITheme';
import API from 'client/GraphqlClient';
import 'styles/main.scss';

const theme = createMuiTheme(CrmMUITheme);

const hydrate = create({});
injectContextHeaders();

let store = new Store();

hydrate('store_followup', store)
  .catch((e) => {
    logger.error(e);
    delete localStorage['store_followup'];
    store = new Store();
    hydrate('store_followup', store);
  })
  .finally(() => {
    document.title =
      store && store.pageTitles && store.pageTitles.followup
        ? store.pageTitles.followup
        : 'Follow Up';

    ReactDOM.render(
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <StoreProvider value={store}>
          <ApolloProvider client={API(store, logger)}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <WinstonProvider logger={logger}>
                  <App />
                </WinstonProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </ApolloProvider>
        </StoreProvider>
      </ErrorBoundary>,
      document.getElementById('root'),
    );
  });
