import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CrmAvatar from 'components/common/CrmAvatar';
import CrmSupport from 'components/common/CrmSupport';
import CrmAlert from 'components/common/CrmAlert';
import CrmNotification from 'components/common/CrmNotification';
import { FormattedMessage } from 'react-intl';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useStore } from 'store/storeUtils';
import 'styles/header.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import LogoTecma from 'images/assets/logo_TECMA_white.svg';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ADMIN, BUILDING_MANAGER, VENDOR_MANAGER } from 'constants/PagePermission';

/**
 * Page Header
 * based on material-ui BreadCrumbs
 * Unknown props are simply ignored
 * @component
 * @see {@link https://material-ui.com/api/breadcrumbs/  BreadCrumbs API}
 */
const CrmHeader = observer((props) => {
  const { setLanguage } = props;

  const store = useStore();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDrawer = () => {
    store.setOpenDrawer(!store.openDrawer);
  };

  const handleChangeLanguage = (e) => {
    setLanguage(e);
    handleClose();
  };
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  if (!store.loggedUser.language) {
    store.setLoggedUserLang('it');
  }

  return (
    <div className="header-wrapper">
      <div className="tecma-elements">
        <div className="image-tecma-wrapper">
          <CrmSvgLoader data={LogoTecma} alt="Tecma" className="image-tecma" />
        </div>
        {!matchesPhone && <div className="crm-wrapper">FOLLOW UP</div>}
      </div>
      <div className="desktop-elements">
        {[BUILDING_MANAGER, ADMIN, VENDOR_MANAGER].includes(store.loggedUser.role) &&
          store.isMyLivingActive && (
            <>
              <CrmAlert />
              <CrmNotification />
            </>
          )}
        <CrmSupport />
        <CrmAvatar />
        {!matchesPhone ? (
          <div>
            <Button
              aria-controls="menu"
              className="language-selector"
              onClick={handleClick}
              disableRipple={true}
            >
              {store.loggedUser.language}
              <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
            </Button>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              classes={{
                paper: 'no-border corner-positioning language-option-selection',
              }}
            >
              {store.loggedUser.language === 'it' ? (
                <div>
                  <MenuItem
                    onClick={() => {
                      handleChangeLanguage('it');
                    }}
                    selected={store.loggedUser.language === 'it'}
                  >
                    <FormattedMessage id="switch.it" defaultMessage="Italian" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleChangeLanguage('en');
                    }}
                    selected={store.loggedUser.language === 'en'}
                  >
                    <FormattedMessage id="switch.en" defaultMessage="English" />
                  </MenuItem>
                </div>
              ) : (
                <div>
                  <MenuItem
                    onClick={() => {
                      handleChangeLanguage('en');
                    }}
                    selected={store.loggedUser.language === 'en'}
                  >
                    <FormattedMessage id="switch.en" defaultMessage="English" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleChangeLanguage('it');
                    }}
                    selected={store.loggedUser.language === 'it'}
                  >
                    <FormattedMessage id="switch.it" defaultMessage="Italian" />
                  </MenuItem>
                </div>
              )}
            </Menu>
          </div>
        ) : (
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={openDrawer}
            className="mobile-drawer"
          >
            <MenuIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
});

CrmHeader.propTypes = {
  breadcrumb: PropTypes.string,
  /** title: page title */
  title: PropTypes.string,
  /** goBack: boolean. If true, current page is a subPath. TODO: change this prop name */
  goBack: PropTypes.bool,
  setLanguage: PropTypes.func,
};

CrmHeader.defaultProps = {
  title: '',
  goBack: false,
};

export default CrmHeader;
