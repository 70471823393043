import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from 'recharts';
import ChartConstants from 'constants/ChartConstants';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import Capitalize from 'utils/Capitalize';
import { useIntl } from 'react-intl';
import { useStore } from 'store/storeUtils';

import 'styles/charts.scss';
import { toJS } from 'mobx';

/**
 * HorizontalBarReChart
 * based on recharts
 * @component
 * @see {@link http://recharts.org/en-US/api/BarChart  BarChart API}
 */
const HorizontalBarReChart = (props) => {
  const { data, availableStatuses, availableColors, backgroundFill, height, width, radius } = props;
  let ourData = toJS(data);

  const intl = useIntl();
  const format = (num) => (num ? num.toLocaleString('it') : num);

  let total = 0;
  let percentuals = [];
  ourData.forEach((dataElement) => {
    dataElement.data = +dataElement.data;
    total = total + dataElement.data;
  });
  const xAxisTotal = total; //data.reduce((total, el) => +total + +el[0].data, 0);

  ourData.forEach((dataElement) => {
    let dataPercent = Math.round((dataElement.data * 1000) / total) / 10;
    let label = dataElement.label;
    percentuals.push({ label, dataPercent });
  });

  return (
    <>
      {data.map((el) => {
        const color = availableColors[availableStatuses.indexOf(el.label)];
        let percentual = percentuals.find((perc) => perc.label === el.label);
        return (
          <div key={el.label}>
            <div className="horizontal-bar-label">
              <div className="horizontal-bar-label-name">
                {Capitalize(
                  intl.formatMessage({
                    id: `label.${el.label}`,
                    defaultMessage: el.label,
                  }),
                )}
              </div>
              <div className="horizontal-bar-stats">
                <span style={{ marginRight: '5px' }}>{'(' + percentual.dataPercent + '%)'}</span>
                <span className="horizontal-bar-percentual-stat">{format(el.data)} €</span>
              </div>
            </div>
            <div className="flex width-100">
              <ResponsiveContainer width="100%" height={height}>
                <BarChart
                  layout="vertical"
                  width={width}
                  height={height}
                  data={[el]}
                  // className="tooltip-centrato-wrapper"
                >
                  <XAxis
                    style={{
                      fontSize: 11,
                      fontWeigth: '300',
                      fontFamily: 'Sagoe',
                    }}
                    domain={[0, xAxisTotal]}
                    dataKey="data"
                    hide={true}
                    type="number"
                  />
                  <YAxis
                    style={{
                      fontSize: 11,
                      fontWeigth: '300',
                      fontFamily: 'Sagoe',
                    }}
                    hide={true}
                    dataKey="label"
                    type="category"
                  />
                  <Bar
                    dataKey={'data'}
                    radius={radius}
                    fill={color}
                    background={{ fill: backgroundFill, radius: radius }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        );
      })}
    </>
  );
};

HorizontalBarReChart.propTypes = {
  /** availableStatuses: list of all statuses to display */
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  /** availableColors: colors of each bar, depending on statuses */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /** data */
  data: PropTypes.arrayOf(PropTypes.object),
  /** backgroundFill: the background color of the bars */
  backgroundFill: PropTypes.string,
  /** height: of each single bar */
  height: PropTypes.number,
  /** width: of all the chart */
  width: PropTypes.number,
  /** radius: a 4 elements array defining the border radius of bars */
  radius: PropTypes.arrayOf(PropTypes.number),
};

HorizontalBarReChart.defaultProps = {
  availableStatuses: ChartConstants.availableStatus,
  availableColors: ChartConstants.defaultColors,
  backgroundFill: '#eeefef',
  data: [],
  height: 25,
  width: 500,
  radius: [0, 0, 0, 0],
};

export default HorizontalBarReChart;
