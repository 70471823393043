import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useWinstonLogger } from 'winston-react';

import { useStore } from 'store/storeUtils';

const LogoutListener = observer(() => {
  const store = useStore();
  const history = useHistory();
  const logger = useWinstonLogger();
  const [loggingOut, setLoggingOut] = useState(false);

  const clearStore = () => {
    try {
      store.logOut();
    } catch (e) {
      logger.error(e);
    }
  };

  if (!loggingOut && store.redirectionToSsoNeeded) {
    setLoggingOut(true);
    clearStore();
    history.push('/login');
  }

  return <></>;
});

export default LogoutListener;
