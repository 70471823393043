import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { useStore } from 'store/storeUtils.js';
import { Button } from '@material-ui/core';
import { appDetailRoute, notificationDetailRoute } from 'routes/Routes';
import { useHistory } from 'react-router-dom';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import Appartamenti from 'images/assets/appartamenti.svg';
import Warning from 'images/assets/warning.svg';
import Cancelled from 'images/assets/cancelled.svg';
import AttachmentIcon from 'images/assets/attachment-icon.svg';
import CrmSimpleAvatar from 'components/common/CrmSimpleAvatar';
import deleteNotificationIcon from 'images/assets/delete-notification.svg';
import { sortClient } from 'utils/customSort';
import { getFormattedClientName } from 'utils/getFormattedClientName';
/**
 * configuration for the appartments list with the following columns:
 * - Appartamento (appartment name)
 * - Data creazione preventivo (last update, rendered as time from now)
 * - Valore appartamento (formatted appartment price)
 * - Valore Varianti(???)
 * - Scelta finiture(???)
 * - Stato preventivo (appartment status in uppercase)
 */

const ListaNotificationsTableConfig = (
  goToClient,
  rowLoading,
  deleteNotificationOption = false,
  deleteNotificationCall,
) => {
  const store = useStore();
  const intl = useIntl();
  const history = useHistory();

  const quoteApartment = {
    title: <FormattedMessage id="apartments.apartment" defaultMessage="Apartment" />,
    field: 'appartment',
    customFilterAndSearch: (value, rowData) => {
      return (
        rowData?.appartment?.name.toLowerCase().includes(value.toLowerCase()) ||
        rowData?.appartment?.name
          .replace(/\_/g, '-')
          .replace(/\./g, '-')
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    },
    render: (rowData) => {
      const isApartmentExist = (data) => data?.appartment?.id && data?.appartment?.name;
      if (!isApartmentExist(rowData)) {
        console.log(rowData.id);
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CrmSvgLoader data={Appartamenti} alt="icon" />
          {isApartmentExist(rowData) ? (
            <a
              className={
                rowData?.status?.[0].state === 'SENT'
                  ? 'black small-font-14 capitalize font-bold'
                  : 'black small-font-14 capitalize'
              }
              style={{
                paddingLeft: '8px',
                paddingTop: '4px',
                color: '#6BC1B4',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                const handleClick = (rowData) => {
                  if (rowData?.appartment?.id) {
                    store.setSelectedAppartment(rowData?.appartment);
                    const destination = appDetailRoute.to.replace(':id', rowData?.appartment?.id);
                    store.setBreadCrumbs('title.apartments');
                    store.setRealLocation(destination);
                    history.push(destination);
                  }
                };
                handleClick(rowData);
              }}
            >
              {rowData?.appartment?.name
                ? rowData?.appartment?.name.replace(/\_/g, '-').replace(/\./g, '-')
                : rowData?.appartment}
            </a>
          ) : (
            <div style={{ marginLeft: '8px' }}>
              <FormattedMessage
                id="notification.apartmentNotExist"
                defaultMessage="Apartment does not exist"
              />
            </div>
          )}
        </div>
      );
    },
  };

  const client = {
    title: <FormattedMessage id="apartments.client" defaultMessage="Client" />,
    field: 'client',
    sorting: true,
    customSort: (a, b) => {
      const clientA = a.status[0].client;
      const clientB = b.status[0].client;
      return sortClient(clientA, clientB);
    },
    customFilterAndSearch: (value, rowData) => {
      return (
        value &&
        rowData.status[0].client &&
        rowData.status[0].client.id &&
        (rowData.status[0].client.firstName + ' ' + rowData.status[0].client.lastName)
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    },
    render: (rowData) => {
      const date = new Date(rowData.createdOn);
      const diff = moment().diff(moment(date), 'days');

      const isClientExist = (data) =>
        data?.client?.id && data?.client?.firstName && data?.client?.lastName;

      if (isClientExist(rowData.status[0])) {
        const clientName = getFormattedClientName(
          rowData.status[0].client.firstName,
          rowData.status[0].client.lastName,
        );
        return (
          <div
            className="flex-centered pointer"
            onClick={() => goToClient(rowData.status[0].client.id)}
          >
            <CrmSimpleAvatar src={rowData.status[0].client.avatar}></CrmSimpleAvatar>
            {rowData.cancelled === true ? (
              <span
                style={{
                  color: '#F1B34B',
                  textDecoration: 'underline',
                  textDecorationColor: '#F1B34B',
                }}
              >
                {clientName}
              </span>
            ) : diff >= store.alertDays &&
              rowData.status[0].state !== 'CLOSED' &&
              rowData.status[0].state !== 'SENT' ? (
              <span
                style={{
                  color: '#DB7A7D',
                  textDecoration: 'underline',
                  textDecorationColor: '#DB7A7D',
                }}
              >
                {clientName}
              </span>
            ) : rowData.status[0].state === 'SENT' && diff >= store.alertDays ? (
              <span
                style={{
                  color: '#DB7A7D',
                  textDecoration: 'underline',
                  textDecorationColor: '#DB7A7D',
                  fontFamily: 'SagoeBold',
                }}
              >
                {clientName}
              </span>
            ) : rowData.status[0].state === 'SENT' && diff < store.alertDays ? (
              <span
                style={{
                  color: '#6BC1B4',
                  textDecoration: 'underline',
                  textDecorationColor: '#6BC1B4',
                  fontFamily: 'SagoeBold',
                }}
              >
                {clientName}
              </span>
            ) : (
              <span
                style={{
                  color: '#6BC1B4',
                  textDecoration: 'underline',
                  textDecorationColor: '#6BC1B4',
                }}
              >
                {clientName}
              </span>
            )}
          </div>
        );
      } else
        return <FormattedMessage id="notification.userDeleted" defaultMessage="User deleted" />;
    },
  };

  const createdOn = {
    title: <FormattedMessage id="calendar.date" defaultMessage="Date" />,
    field: 'createdOn',
    width: '10%',
    customFilterAndSearch: (value, rowData) => {
      return moment(new Date(rowData.createdOn)).fromNow().includes(value.toLowerCase());
    },
    render: (rowData) => {
      const date = new Date(rowData.createdOn);
      const diff = moment().diff(moment(date), 'days');
      return (
        <>
          <div
            style={
              rowData.status[0].state === 'SENT'
                ? { fontFamily: 'SagoeBold' }
                : { fontFamily: 'Sagoe' }
            }
          >
            <div>
              <span
                style={
                  diff >= store.alertDays &&
                  rowData.status[0].state !== 'CLOSED' &&
                  rowData.cancelled !== true
                    ? { color: '#DB7A7D' }
                    : {}
                }
              >
                {moment(date).format('L')}
              </span>
            </div>
            <div>
              <span
                style={
                  diff >= store.alertDays &&
                  rowData.status[0].state !== 'CLOSED' &&
                  rowData.cancelled !== true
                    ? { color: '#DB7A7D' }
                    : {}
                }
              >
                {moment(date).format('HH:mm')}
              </span>
            </div>
          </div>
        </>
      );
    },
  };

  const warning = {
    field: 'warning',
    customFilterAndSearch: (value, rowData) => {
      return moment(new Date(rowData.createdOn)).fromNow().includes(value.toLowerCase());
    },
    width: '10%',
    render: (rowData) => {
      const date = new Date(rowData.createdOn);
      const diff = moment().diff(moment(date), 'days');
      return rowData.cancelled === true ? (
        <>
          <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <CrmSvgLoader data={Cancelled} alt="cancelled" />
          </div>
        </>
      ) : diff >= store.alertDays && rowData.status[0].state !== 'CLOSED' ? (
        <>
          <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <CrmSvgLoader data={Warning} alt="warning" />
            <span style={{ fontSize: '9px', color: '#DB7A7D' }}>{diff} days ago</span>
          </div>
        </>
      ) : (
        <span></span>
      );
    },
  };

  const attachment = {
    field: 'attachment',
    customFilterAndSearch: (value, rowData) => {
      return moment(new Date(rowData.createdOn)).fromNow().includes(value.toLowerCase());
    },
    width: '5%',
    render: (rowData) => {
      const date = new Date(rowData.createdOn);
      return (
        rowData.attachments.length > 0 && (
          <>
            <div style={{ textAlign: 'center', verticalAlign: 'middle', marginRight: '-45px' }}>
              <CrmSvgLoader data={AttachmentIcon} alt="attachment" />
            </div>
          </>
        )
      );
    },
  };

  const notificationTitle = {
    title: <FormattedMessage id="subject" defaultMessage="Notification" />,
    field: 'subject',
    customFilterAndSearch: (value, rowData) => {
      return value && rowData.subject.toLowerCase().includes(value.toLowerCase());
    },
    render: (rowData) => {
      const date = new Date(rowData.createdOn);
      const diff = moment().diff(moment(date), 'days');
      return (
        <div
          style={
            rowData.status[0].state === 'SENT'
              ? { fontFamily: 'SagoeBold', minWidth: '90px' }
              : { fontFamily: 'Sagoe', minWidth: '90px' }
          }
        >
          <span
            style={
              diff >= store.alertDays &&
              rowData.status[0].state !== 'CLOSED' &&
              rowData.cancelled !== true
                ? { color: '#DB7A7D' }
                : {}
            }
          >
            {rowData.subject ? `${rowData.subject}` : ''}
          </span>
        </div>
      );
    },
  };

  const openDetails = {
    field: 'openDetails',
    render: (rowData) => {
      return (
        <Button
          className="export-apartment-button"
          onClick={() => {
            const handleClick = (rowData) => {
              store.setClientId(rowData.status[0].client.id);
              store.setSelectedAppartment(rowData.appartment);
              store.setSelectedNotificationStatus(rowData.status[0].state);
              store.setSelectedNotification(rowData);
              store.setBreadCrumbs('title.apartments');
              const destination = notificationDetailRoute.to.replace(':id', rowData.appartment.id);
              store.setRealLocation(destination);
              history.push(destination);
            };
            handleClick(rowData);
          }}
        >
          <FormattedMessage
            id="apartments.open"
            defaultMessage="Open"
            style={{ marginLeft: '7px' }}
          />
        </Button>
      );
    },
  };

  const deleteNotification = {
    field: 'deleteNotification',
    width: '10%',
    render: (rowData) => {
      return (
        <CrmSvgLoader
          data={deleteNotificationIcon}
          alt="delete"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            deleteNotificationCall(rowData);
          }}
        ></CrmSvgLoader>
      );
    },
  };

  return deleteNotificationOption
    ? [
        quoteApartment,
        warning,
        client,
        createdOn,
        attachment,
        notificationTitle,
        openDetails,
        deleteNotification,
      ]
    : [quoteApartment, warning, client, createdOn, attachment, notificationTitle, openDetails];
};

export default ListaNotificationsTableConfig;
