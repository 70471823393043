import React from 'react';
import moment from 'utils/MyMoment';
import CrmSimpleAvatar from 'components/common/CrmSimpleAvatar';
import { FormattedMessage } from 'react-intl';
import { defaultSort } from 'utils/customSort';
import { getFormattedClientName } from 'utils/getFormattedClientName';

/**
 * configuration for the clients list with the following columns:
 * - #User (first and last name)
 * - Data registrazione (client creation date, rendered as time from now)
 * - Status (client status in uppercase)
 * - N. Appuntamenti Fatti (counter of done appointments)
 * - N. Preventivi richiesti (counter of done price quotations)
 */
const ListaClientiDashboardTableConfig = [
  {
    title: <FormattedMessage id="client.user" defaultMessage="User" />,
    field: 'lastName',
    customSort: (a, b) => {
      return defaultSort(a.firstName, b.firstName);
    },
    cellStyle: {
      padding: '12px',
    },
    customFilterAndSearch: (value, rowData) => {
      return (rowData.firstName + ' ' + rowData.lastName)
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    sorting: true,
    render: (rowData) =>
      rowData.firstName && rowData.lastName ? (
        <div className="flex-centered">
          <CrmSimpleAvatar src={rowData.avatar}></CrmSimpleAvatar>

          <div className="black small-font-14 font-bold">
            {getFormattedClientName(rowData.firstName, rowData.lastName)}
          </div>
        </div>
      ) : (
        <CrmSimpleAvatar src={rowData.avatar}></CrmSimpleAvatar>
      ),
  },
  {
    title: <FormattedMessage id="client.createdOn" defaultMessage="Created On" />,
    field: 'createdOn',
    customFilterAndSearch: (value, rowData) => {
      return moment(new Date(rowData.createdOn))
        .format('DD MMM YYYY')
        .includes(value.toLowerCase());
    },
    sorting: true,
    render: (rowData) => {
      const date = new Date(rowData.createdOn);
      return <>{moment(date).format('DD MMM YYYY')}</>;
    },
  },
  {
    title: <FormattedMessage id="client.status" defaultMessage="Status" />,
    field: 'status',
    sorting: true,
    render: (rowData) => <span className="capitalize">{rowData.status}</span>,
  },
  {
    title: <FormattedMessage id="client.nAppuntamenti_" defaultMessage="Appointments made" />,
    field: 'nAppuntamenti',
    sorting: true,
  },
  {
    title: <FormattedMessage id="client.nPreventiviRichiesti" defaultMessage="Requested quotes" />,
    field: 'nPreventiviRichiesti',
    sorting: true,
  },
];

export default ListaClientiDashboardTableConfig;
