import { gql } from '@apollo/client';
import { LEAN_QUOTE } from './quote';

export const GET_PROPOSALS = gql`
  query getProposals($project_id: ID!) {
    getProposals(project_id: $project_id) {
      ${LEAN_QUOTE}
    }
  }
`;

export const GET_PROPOSALS_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'cache-and-network',
  };

  return options;
};

export const GET_PROPOSALS_IN_PROGRESS = gql`
  query getProposalsInProgress($project_id: ID!) {
    getProposalsInProgress(project_id: $project_id) {
      ${LEAN_QUOTE}
    }
  }
`;

export const GET_PROPOSALS_IN_PROGRESS_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'cache-and-network',
  };

  return options;
};

export const GET_PROPOSALS_REJECTED = gql`
  query getProposalsRejected($project_id: ID!) {
    getProposalsRejected(project_id: $project_id) {
      ${LEAN_QUOTE}
    }
  }
`;

export const GET_PROPOSALS_REJECTED_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'cache-and-network',
  };

  return options;
};

export const GET_PROPOSALS_TO_COUNTERSIGN = gql`
  query getProposalsToCountersign($project_id: ID!) {
    getProposalsToCountersign(project_id: $project_id) {
      ${LEAN_QUOTE}
    }
  }
`;

export const GET_PROPOSALS_TO_COUNTERSIGN_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'cache-and-network',
  };

  return options;
};