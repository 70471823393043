import { gql } from '@apollo/client';
import { pollInterval } from 'client/ClientConstants';
import * as aptModules from './modules/apartment';
import * as quoteModules from './modules/quote';
import * as productCategoryModules from './modules/productCategory';
import * as proposalModules from './modules/proposal';
import { APARTMENT_DATA_TO_RETRIEVE } from './fragments';

export const Platforms = {
  FLOORPLANNING: 'FLOORPLANNING',
  MYLIVING: 'MYLIVING',
};

export const ServiceReservationType = {
  WHOLE_STRUCTURE: 'WHOLE_STRUCTURE',
  SINGLE: 'SINGLE',
};

const NOTIFICATIONS_LIST_DATA_TO_RETRIEVE = `
  data{
    id
    subject
    message
    type
    notice
    priority
    breakdown
    state
    client
    read
    buildingOnly
    validated
    archived
    cancelled
    createdOn
    createdBy
    updatedOn
    deletedOn
    attachments{
      type
      base64
    }
    appartment
    building
    project_id
  }
  count
`;

const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    id
    subject
    message
    type
    notice
    priority
    breakdown
    state
    read
    buildingOnly
    validated
    archived
    cancelled
    createdOn
    createdBy
    updatedOn
    deletedOn
    attachments {
      type
      base64
    }
    appartment
    building
    project_id
  }
`;

const ALERT_FRAGMENT = gql`
  fragment AlertFragment on Alert {
    id
    client {
      id
      firstName
      lastName
      status
      avatar
      city
      birthdate
      cookies
      note
      createdOn
    }
    status
    subject
    message
    breakdown
    notice
    isMessage
    isReply
    createdOn
  }
`;

const MYLIVING_ERROR_FRAGMENT = gql`
  fragment MyLivingErrorFragment on MyLivingError {
    message
    error
  }
`;

const FULL_NOTIFICATION_FRAGMENT = gql`
  fragment FullNotificationFragment on FullNotification {
    id
    subject
    message
    type
    notice
    priority
    breakdown
    status {
      state
      client {
        id
        firstName
        lastName
        email
        avatar
      }
      createdOn
      updatedOn
    }
    read
    buildingOnly
    validated
    archived
    cancelled
    createdOn
    createdBy
    updatedOn
    deletedOn
    attachments {
      type
    }
    chat {
      id
      message
      from
      state
    }
    appartment {
      id
      name
    }
    building
    project_id
  }
`;

const SET_NOTIFICATION_DATA = `
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
  ... on GenericResponse {
    done
  }
`;

const BUILDING = `
  id
  name
  floors
  address
`;

const FEATURE_DATA_TO_RETRIEVE = `
      id
      name
      type
      price
      floor
      notes
      code
      meters
      width
      length
      available
      createdOn
      status
      sub
      expenses
      extraInfo
      building {
        id
        name
        complex
      }
      selectedBy{
        client {
          id
          firstName
          lastName
          email
        }
        status
        expiryOn
      }
`;

const CUSTOM_QUOTE_DATA_TO_RETRIEVE = `
boxes{
  box
  price
  meters
  floor
}
basements{
  basement
  price
  meters
  floor
}
parkingSpace_cars{
  parkingSpace_car
  price
  meters
  floor
}
parkingSpace_bykes{
  parkingSpace_byke
  price
  meters
  floor
}
features{
  id
  name
  type
  price
  floor
  notes
  code
  meters
  width
  length
  visible
  available
  status
  extraInfo
  sub
  selectedBy{
    client{
      id
    }
    status
    expiryOn
  }
}
expenses{
  name
  price
  percentage
  }
  iva{
  percentage
  name
  }
  promoPrice
  totalPrice
  fixedRate{
  percentage
  value
  }
  variableRate{
  percentage
  value
  }
  rate
  freeNotes
`;

const DELETE_CALENDAR_DATA = `
... on BooleanResult {
    ...BooleanResultFragment
  }
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
}
`;

const BOOLEAN_RESULT_FRAGMENT = gql`
  fragment BooleanResultFragment on BooleanResult {
    done
  }
`;

const COMMUNITY_SERVICE_FRAGMENT = gql`
  fragment CommunityServiceFragment on CommunityService {
    id
    name
    description
    address
    images
    max_reservations
    time_slot
    allow_multiple_slot
    private_service
    opening_hours {
      day
      ranges {
        from {
          hours
          minutes
        }
        to {
          hours
          minutes
        }
      }
    }
    project_id
  }
`;

const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    firstName
    lastName
    email
    status
    avatar
    tel
    city
    birthdate
    cookies
    note
    createdOn
  }
`;

const GET_QUOTES_BY_CLIENT_DATA = `
    id
    status
    quoteNumber
    reserved
    pdf
    client{
      id
      firstName
      lastName
    }
    customQuote {
      ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
    }
    proposals
    appartment{
      ${aptModules.GET_APARTMENT_DATA}
    }
    savedDocuments {
      name
      path
      lastModified
    }
    idDocuments {
      name
      type
      base64
    }
    vendor
    createdOn
    expiryOn
  `;

const QUOTE_DATA_TO_RETRIVE = `
    id
    status
    quoteNumber
    reserved
    pdf
    client{
      id
      firstName
      lastName
    }
    customQuote {
      ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
    }
    proposals
    appartment{
      ${APARTMENT_DATA_TO_RETRIEVE}
    }
    savedDocuments {
      name
      path
      lastModified
    }
    idDocuments {
      name
      type
      base64
    }
    vendor
    createdOn
    expiryOn
  `;

const CLIENT_DATA_CALENDAR_TO_RETRIEVE = `
  id
  firstName
  lastName
  email
  tel
  `;

const CLIENT_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      status
      avatar
      nAppuntamenti
      nPreventiviRichiesti
      appuntamenti{
        id
        startDate
        typology
        vendor {
          id
        }
      }
      email
      tel
      city
      birthdate
      trattamento
      marketing
      profilazione
      note
      interested_appartments {
        appartment
        status
      }
      selected_appartments {
        appartment
        status
      }
      createdOn
      date_lead
      info{
        budget
        source
        motivazione
        tempo_ricerca
        stato_civile
        caratteristiche
        mutuo
        leva
        arredo
        alternative
        prima_casa
        family{
          adulti
          bambini
          animali
        }
      }
      myhome_version
      coniuge{
        nome
        cognome
        mail
        indirizzo
        tel
      }
      user
      additionalInfo
      reserved
      privacyInformation
`;

const TYPOLOGY_DATA_TO_RETRIEVE = `
      id
      rooms
      name
      active
`;

const VENDOR_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      title
      avatar
      project_names
      language
`;

const VENDOR_DATA_TO_RETRIEVE_ALL = `
      id
      firstName
      lastName
      email
      project_ids
      language
`;

const USER_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      project_ids
      language
      role
      hasGoogleToken
      consents{
        newsletter{
          privacyPolicy
          subscription
        }
      }
`;

const IMPORT_DATA_TO_RETRIEVE = `
      id
      title
      binary
      createdOn
`;

const EXPORT_DATA_TO_RETRIEVE = `
      title
      binary
      createdOn
`;

const PROJECT_DATA_TO_RETRIEVE = `
      id
      name
      logo
      vendorLogo
      assetLogin
      hostKey
      assetKey
      feVendorKey
      pdf
      assetsUrl
      displayName
      pageTitles {
        followup
        tuning
      }
      followupRentConfig
      floorPlanningRentConfig
      enabledTools
      currency
      locale
      myhomeConfig
      policyFlags
      area
      defaultLang
`;

const PROJECT_DATA_CONFIG = `
      formAppointmentConfig
      myhomeConfig
`;

const CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE = `
      id
      type
      label
      options
      disabled
      dimension
      required
      defaultValue
      multiple
      position
      path
      name
      tab
      subSection
      project_id
      hiddenProjects
      helperText
      period
      min
      max
      minLength
      maxLength
      step
`;

const CALENDAR_DATA_TO_RETRIEVE = `
      id
      startDate
      endDate
      client {
        id
        tel
        email
        lastName
        firstName
      }
      vendor {
        id
        lastName
        firstName
      }
      info
      address
      typology
      title
      full
      proposta
      opzione
      interesse
      detAcquisto
      note
      comment
      motivo
      esito
      google_id
      infoText
      createdBy
      recurringEvent
      allCompany
`;

const COMMUNITY_CALENDAR_FRAGMENT = gql`
  fragment CommunityCalendarFragment on CommunityCalendar {
    id
    title
    startDate
    endDate
    service {
      ...CommunityServiceFragment
    }
    reservation_type
    google_id
    microsoft_id
    notes
    client {
      ...ClientFragment
    }
    project_id
  }
`;

const ERRORS_FRAGMENT = gql`
  fragment MyLivingErrorFragment on MyLivingError {
    message
    error
  }
`;

const CLIENT_APPARTMENTS_DATA = `
    id
    name
    floor
    code
    order
    price
    sides {
      id
      building{
        id
      }
    }
    discounted_price
    enabled_web
    enabled_desk
    active
    status
    updatedOn
    forRent
    extraInfo
    availableQuadrants
`;

const COMMUNITY_CALENDAR_DATA_TO_RETRIEVE = `
  id
  title
  startDate
  endDate
  service {
    id
    name
    description
    address
    max_reservations
    time_slot
    allow_multiple_slot
    private_service
    project_id
    open
    opening_hours{
      day
      ranges{
        from{
          hours
          minutes
        }
        to{
          hours
          minutes
        }
      }
    }
  }
  reservation_type
  google_id
  microsoft_id
  notes
  client {
    id
    tel
    email
    lastName
    firstName
    avatar
    appartments {
      appartment
      status
    }
  }
  project_id
  apartment {
    name
    id
  }
  isDeleted
`;

const COMMUNITY_SERVICE_DATA_TO_RETRIEVE = `
  id
  name
  description
  address
  images
  max_reservations
  time_slot
  allow_multiple_slot
  private_service
  open
  opening_hours{
    day
    ranges{
      from{
        hours
        minutes
      }
      to{
        hours
        minutes
      }
    }
  }
  project_id
`;

const CALENDAR_DATA = `
... on CommunityCalendar {
    ...CommunityCalendarFragment
  }
  ... on MyLivingError {
    ...MyLivingErrorFragment
  }
}
`;

const UPDATE_CALENDAR_DATA = `
    title
    startDate
    endDate
    reservation_type
    google_id
    microsoft_id
    notes
    project_id,
    appartment_id
`;

const GET_CALENDARS_DATA = `
  id
  title
  startDate
  endDate
  service {
    id 
    name 
    description 
    address 
    max_reservations 
    time_slot 
    allow_multiple_slot 
    private_service 
    opening_hours{
      day
      ranges{
        from{
          hours
          minutes
        }
        to{
          hours
          minutes
        }
      }
    }
    project_id
  }
  reservation_type
  google_id
  microsoft_id
  notes
  client{
    id
    firstName
    lastName
  }
  project_id
`;

const DELETE_DATA_TO_RETRIVE = `
      success
      vendor
`;

const ID_DOCUMENT_TO_RETRIEVE = `
      name
      type
      base64
`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host :String!) {
    getProjectInfoByHost (host :$host) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
    },
  };
};

const GET_ALL_CLIENTS = gql`
  query getAllClients($project_id: ID!) {
    getAllClients(project_id: $project_id) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_ALL_CLIENTS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_MY_LIVING_CLIENTS = gql`
  query getAllMyLivingClients($project_id: ID!, $page: Int, $perPage: Int) {
    getAllMyLivingClients(project_id: $project_id, page: $page, perPage: $perPage) {
      ${CLIENT_DATA_CALENDAR_TO_RETRIEVE}
    }
  }
`;
const GET_ALL_MY_LIVING_CLIENTS_OPTIONS = (pId, page, perPage) => {
  return {
    variables: {
      project_id: pId,
      page: page,
      perPage: perPage,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CLIENTS_ADDITIONAL_INFO = gql`
  query getAllAdditionalInfoByProject($project_id: ID!) {
    getAllAdditionalInfoByProject(project_id: $project_id) {
      ${CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE}
    }
  }`;

const GET_ALL_CLIENTS_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    fetchPolicy: 'cache-and-network',
  };
};

const UPDATE_ADDITIONAL_INFO = gql`
  mutation updateAdditionalInfo($project_id: ID!, $id: ID!, $input: ClientAdditionalInfoInput!){
    updateAdditionalInfo(project_id: $project_id, id: $id, input: $input){
      ${CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const GET_QUOTE_DOCUMENT = gql`
  mutation getProposalDocsByQuote($project_id: ID!, $id: ID!) {
    getProposalDocsByQuote(project_id: $project_id, id: $id) {
      proposal
    }
  }
`;

const GET_QUOTE_DOCUMENT_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const UPLOAD_DOCUMENT = gql`
  mutation uploadClientDocuments(
    $project_id: ID!
    $quoteId: ID
    $clientId: ID!
    $idDocuments: [IdDocumentInput]!
  ) {
    uploadClientDocuments(
      project_id: $project_id
      quoteId: $quoteId
      clientId: $clientId
      idDocuments: $idDocuments
    )
  }
`;

const UPLOAD_DOCUMENT_DEFAULT_OPTIONS = (pId, idQuote, idClient, input) => {
  return {
    variables: {
      project_id: pId,
      quoteId: idQuote,
      clientId: idClient,
      idDocuments: input,
    },
  };
};

const GET_CLIENT_DOCUMENT = gql`
  query getClientDocument($project_id: ID!, $id: ID!, $noQuote: Boolean) {
    getClientDocument(project_id: $project_id, id: $id, noQuote: $noQuote)
  }
`;

const GET_CLIENT_DOCUMENT_DEFAULT_OPTIONS = (pId, id, noQuote) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      noQuote: noQuote,
    },
    fetchPolicy: 'network-only',
  };
};

const CREATE_ADDITIONAL_INFO = gql`
  mutation createCustomAdditionalInfo($project_id: ID!, $input: ClientAdditionalInfoInput!){
    createCustomAdditionalInfo(project_id: $project_id, input: $input){
      ${CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE}
    }
  }
`;

const CREATE_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input: input,
    },
  };
};

const DELETE_ADDITIONAL_INFO = gql`
  mutation deleteCustomAdditionalInfo($project_id: ID!, $id: ID!) {
    deleteCustomAdditionalInfo(project_id: $project_id, id: $id)
  }
`;

const DELETE_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId, inputId) => {
  return {
    variables: {
      project_id: pId,
      id: inputId,
    },
  };
};

const GET_QUOTES_BYAPARTMENT = gql`
  query getQuotesByApartment($project_id: ID!, $apartment: ID!) {
    getQuotesByApartment(project_id: $project_id, appartment: $apartment) {
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }`;

const GET_QUOTES_BYAPARTMENT_DEFAULT_OPTIONS = (pId, apartment) => {
  return {
    variables: {
      project_id: pId,
      apartment: apartment,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_QUOTES_BYCLIENT = gql`
  query getQuotesByClient($project_id: ID!, $client: ID!) {
    getQuotesByClient(project_id: $project_id, client: $client) {
      ${GET_QUOTES_BY_CLIENT_DATA}
    }
  }`;

const GET_QUOTES_BYCLIENT_DEFAULT_OPTIONS = (pId, client) => {
  return {
    variables: {
      project_id: pId,
      client: client,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const UPDATE_EXPIRED_QUOTE = gql`
  mutation updateExpiredQuotes($project_id: ID!, $id: ID!, $input: ExpiredQuoteInput!){
    updateExpiredQuotes(project_id: $project_id, id: $id, input: $input){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_RESERVED_QUOTE = gql`
  mutation updateReservedTimeQuotes($project_id: ID!, $id: ID!, $input: ExpiredQuoteInput!){
    updateReservedTimeQuotes(project_id: $project_id, id: $id, input: $input){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_EXPIRED_QUOTE_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const UPDATE_RESERVED_QUOTE_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const UPDATE_QUOTE_PROPOSAL = gql`
  mutation updateQuotesProposalStatus($project_id: ID!, $quoteId: ID!, $proposalId: ID!, $input: QuoteProposalStatusInput!){
    updateQuotesProposalStatus(project_id: $project_id, quoteId: $quoteId, proposalId: $proposalId, input: $input){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_QUOTE_PROPOSAL_DEFAULT_OPTIONS = (pId, quoteId, proposalId, input) => {
  return {
    variables: {
      project_id: pId,
      quoteId: quoteId,
      proposalId: proposalId,
      input: input,
    },
  };
};

const DELETE_QUOTE = gql`
  mutation deleteQuotes($project_id: ID!, $id: ID!){
    deleteQuotes(project_id: $project_id, id: $id){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const DELETE_QUOTE_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const GENERATE_QUOTE_PDF = gql`
  mutation generateQuotePdf($project_id: ID!, $quoteId: ID!) {
    generateQuotePdf(project_id: $project_id, quoteId: $quoteId)
  }
`;

const GENERATE_QUOTE_PDF_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      quoteId: id,
    },
  };
};

const GET_CLIENT = gql`
  query getClient($project_id: ID!, $id: ID!) {
    getClient(project_id: $project_id, id: $id) {
      ${CLIENT_DATA_TO_RETRIEVE},confirmed
    }
  }`;

const GET_CLIENT_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_CLIENTS_DATA = gql`
  query getDataClients($project_id: ID!) {
    getDataClients(project_id: $project_id) {
      ${CLIENT_DATA_CALENDAR_TO_RETRIEVE}
    }
  }`;

const GET_CLIENTS_DATA_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_TYPOLOGIES = gql`
  query getAllTypologies($project_id: ID!) {
    getAllTypologies(project_id: $project_id) {
      ${TYPOLOGY_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_TYPOLOGIES_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CALENDARS_FO = gql`
query getCalendars($project_id: ID!, $filter: CalendarFilter!) {
  getCalendars(project_id: $project_id, filter: $filter){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_ALL_CALENDARS_BY_VENDOR = gql`
query getCalendarsByVendor($project_id: ID!, $vendor: ID!, $filter: CalendarFilter!) {
  getCalendarsByVendor(project_id: $project_id, vendor: $vendor, filter: $filter){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_ALL_CALENDARS_BY_VENDORS = gql`
query getCalendarsByVendorList($project_id: ID!, $vendor: [ID]!) {
  getCalendarsByVendorList(project_id: $project_id, vendor: $vendor){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_CALENDAR = gql`
query getCalendar($project_id: ID!, $id: ID!) {
  getCalendar(project_id: $project_id, id: $id){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const IS_FIRST_CALENDAR = gql`
  query isFirstCalendar($project_id: ID!, $id: ID!) {
    isFirstCalendar(project_id: $project_id, id: $id)
  }
`;

const GET_ALL_CALENDARS_FO_DEFAULT_OPTIONS = (pId, filter) => {
  return {
    variables: {
      project_id: pId,
      filter,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CALENDARS_BY_VENDORS_DEFAULT_OPTIONS = (pId, vId) => {
  return {
    variables: {
      project_id: pId,
      vendor: vId.map((v) => v.value),
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CALENDARS_BY_VENDOR_DEFAULT_OPTIONS = (pId, vId, filter) => {
  return {
    variables: {
      project_id: pId,
      vendor: vId,
      filter,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_CALENDAR_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const IS_FIRST_CALENDAR_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const DELETE_COMMUNITY_CALENDAR = gql`
  mutation deleteCommunityCalendarMutation($id: ID!, $project_id: ID!, $appartment_id: ID!) {
    deleteCommunityCalendar(id: $id, project_id: $project_id, appartment_id: $appartment_id){
      ${DELETE_CALENDAR_DATA}
    }
    ${BOOLEAN_RESULT_FRAGMENT}
    ${ERRORS_FRAGMENT}
`;

const DELETE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (id, project_id, appartment_id) => {
  return {
    variables: {
      id,
      project_id,
      appartment_id,
    },
  };
};

const GET_COMMUNITY_CALENDAR = gql`
query getCommunityCalendar($id: ID!, $project_id: ID!) {
  getCommunityCalendar(id: $id, project_id: $project_id){
    ${COMMUNITY_CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (project_id, id) => {
  return {
    variables: {
      id,
      project_id,
    },
    fetchPolicy: 'no-cache',
  };
};

const GET_COMMUNITY_CALENDAR_BY_PROJECT = gql`
query getCommunityCalendarsByProject($project_id: ID!) {
  getCommunityCalendarsByProject(project_id: $project_id){
    ${COMMUNITY_CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_COMMUNITY_CALENDAR_BY_PROJECT_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_SERVICES_BY_PROJECT = gql`
query getServicesByProject($project_id: ID!) {
  getServicesByProject(project_id: $project_id){
    ${COMMUNITY_SERVICE_DATA_TO_RETRIEVE}
  }
}`;

const GET_SERVICES_BY_PROJECT_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const CREATE_COMMUNITY_CALENDAR = gql`
  mutation createCommunityCalendarMutation($input: CreateCommunityCalendarInput!) {
    createCommunityCalendar(input: $input) {
      ${CALENDAR_DATA}
    }
    ${COMMUNITY_SERVICE_FRAGMENT}
    ${CLIENT_FRAGMENT}
    ${COMMUNITY_CALENDAR_FRAGMENT}
    ${ERRORS_FRAGMENT}
`;

const CREATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (pId, event) => {
  return {
    variables: {
      input: {
        title: event.data.title,
        startDate: event.data.start,
        endDate: event.data.end,
        service: event.data.service,
        client: event.data.clientId,
        appartment_id: event.data.appartmentId,
        project_id: pId,
        reservation_type: event.data.reservationType,
        google_id: event.data.googleId,
        microsoft_id: event.data.microsoftId,
        notes: event.data.notes,
      },
    },
  };
};

const UPDATE_COMMUNITY_CALENDAR = gql`
mutation updateCommunityCalendarMutation($id: ID!, $input: UpdateCommunityCalendarInput!) {
  updateCommunityCalendar(id: $id, input: $input) { 
    ${CALENDAR_DATA}
  }
  ${COMMUNITY_SERVICE_FRAGMENT}
  ${CLIENT_FRAGMENT}
  ${COMMUNITY_CALENDAR_FRAGMENT}
  ${ERRORS_FRAGMENT}
`;

const UPDATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id,
      input,
    },
  };
};

const GET_PROJECT_FEATURE = gql`
query getFeature ($project_id :ID!, $id: ID!) {
  getFeature (project_id :$project_id, id: $id) {
    ${FEATURE_DATA_TO_RETRIEVE}
  }
}`;

const GET_PROJECT_FEATURE_DEFAULT_OPTIONS = (projectId, id) => {
  return {
    variables: {
      project_id: projectId,
      id: id,
    },
    fetchPolicy: 'cache-and-network',
  };
};

const GET_PROJECT_FEATURES = gql`
query getFeatures ($project_id :ID!) {
  getFeatures (project_id :$project_id) {
    ${FEATURE_DATA_TO_RETRIEVE}
  }
}`;
const GET_PROJECT_FEATURES_DEFAULT_OPTIONS = (projectId) => {
  return {
    variables: {
      project_id: projectId,
    },
    fetchPolicy: 'cache-and-network',
  };
};

const UPDATE_PERTINENZA_INFO = gql`
  mutation updateFeatures($project_id: ID!, $id: ID!, $input: FeatureInput) {
    updateFeatures(project_id: $project_id, id: $id, input: $input){
      ${FEATURE_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_PERTINENZA_INFO_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const GET_COMMUNITY_CALENDARS = gql`
query getCommunityCalendarsQuery($project_id: ID!, $filter: CommunityCalendarFilterInput ) {
  getCommunityCalendars(
    project_id: $project_id
    filter: $filter
  ) {
    ${GET_CALENDARS_DATA}
  }
}
`;

const GET_COMMUNITY_CALENDARS_DEFAULT_OPTIONS = (project_id, filter) => {
  return {
    variables: {
      project_id,
      filter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  };
};

const INSERT_CLIENT_INTEREST = gql`
  mutation setClientInterest($project_id: ID!, $clientId: ID!, $appartmentId: ID!, $vendorId: ID!) {
    setClientInterest(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId, vendorId: $vendorId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const INSERT_CLIENT_INTEREST_DEFAULT_OPTIONS = (pId, id, app, vendor) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      appartmentId: app,
      vendorId: vendor,
    },
  };
};

const INSERT_CLIENT_APPARTMENT = gql`
  mutation setClientAppartment($project_id: ID!, $clientId: ID!, $appartmentId: ID!, $status: String!, $vendorId: ID!,$quadrant: String, $checkIn: String, $checkOut: String, $movementId: ID) {
    setClientAppartment(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId, status: $status, vendorId: $vendorId, quadrant:$quadrant, checkIn: $checkIn, checkOut: $checkOut, movementId: $movementId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const INSERT_CLIENT_APPARTMENT_DEFAULT_OPTIONS = (
  pId,
  id,
  app,
  status,
  vendor,
  quadrant,
  checkIn,
  checkOut,
  movementId,
) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      appartmentId: app,
      status: status,
      vendorId: vendor,
      quadrant: quadrant,
      checkIn: checkIn,
      checkOut: checkOut,
      movementId: movementId,
    },
  };
};

const CREATE_CALENDAR = gql`
  mutation createCalendars($project_id: ID!, $input: CalendarInput!){
    createCalendars(project_id: $project_id, input: $input){
      ${CALENDAR_DATA_TO_RETRIEVE}
    }
  }
`;

const CREATE_CALENDAR_DEFAULT_OPTIONS = (pId, event, vId) => {
  return {
    variables: {
      project_id: pId,
      input: {
        vendor: event.data.vendor && event.data.vendor.value ? event.data.vendor.value : vId,
        startDate: event.data.start.toISOString(),
        endDate: event.data.end.toISOString(),
        client: event.data.clientId,
        typology: event.data.type,
        address: event.data.place,
        full: event.data.full,
        title: event.data.title,
        proposta: event.data.proposta,
        opzione: event.data.opzione,
        interesse: event.data.interesse,
        detAcquisto: event.data.detAcquisto,
        note: event.data.note,
        comment: event.data.comment,
        motivo: event.data.motivo,
        esito: event.data.esito,
        infoText: event.data.infoText,
        createdBy: vId,
      },
    },
  };
};

const CREATE_CALENDAR_LIST = gql`
mutation createCalendarList($project_id: ID!, $input: [CalendarInput]!){
  createCalendarList(project_id: $project_id, input: $input){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}
`;

const CREATE_CALENDAR_LIST_DEFAULT_OPTIONS = (pId, event) => {
  return {
    variables: {
      project_id: pId,
      input: event,
    },
  };
};

const DELETE_CALENDAR_LIST = gql`
  mutation deleteCalendarRecurrent($project_id: ID!, $id: String!) {
    deleteCalendarRecurrent(project_id: $project_id, id: $id){
      ${CALENDAR_DATA_TO_RETRIEVE}
    }
  }
`;

const DELETE_CALENDAR_LIST_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const UPDATE_CALENDAR = gql`
  mutation updateCalendars($project_id: ID!, $id: ID!, $mailUpdate: Boolean, $input: CalendarInput!){
    updateCalendars(project_id: $project_id, id: $id, mailUpdate: $mailUpdate, input: $input){
      ${CALENDAR_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_CALENDAR_DEFAULT_OPTIONS = (pId, event, vId, mailUpdate = false) => {
  return {
    variables: {
      project_id: pId,
      id: event.data.id,
      mailUpdate: mailUpdate ? mailUpdate : false,
      input: {
        vendor: event.data.vendor && event.data.vendor.value ? event.data.vendor.value : vId,
        startDate: event.data.start.toISOString(),
        endDate: event.data.end.toISOString(),
        client: event.data.clientId,
        typology: event.data.type,
        full: event.data.full,
        title: event.data.title,
        address: event.data.place,
        proposta: event.data.proposta,
        opzione: event.data.opzione,
        interesse: event.data.interesse,
        detAcquisto: event.data.detAcquisto,
        note: event.data.note,
        comment: event.data.comment,
        motivo: event.data.motivo,
        esito: event.data.esito,
        infoText: event.data.infoText,
      },
    },
  };
};

const DELETE_CALENDAR = gql`
  mutation deleteCalendars($project_id: ID!, $id: ID!) {
    deleteCalendars(project_id: $project_id, id: $id)
  }
`;

const DELETE_AND_REASSIGN_CALENDAR = gql`
  mutation deleteAndReassignCalendars($project_id: ID!, $id: ID!) {
    deleteAndReassignCalendars(project_id: $project_id, id: $id){
      ${DELETE_DATA_TO_RETRIVE}
    }
  }
`;

const DELETE_CALENDAR_DEFAULT_OPTIONS = (pId, event) => {
  return {
    variables: {
      project_id: pId,
      id: event.data.id,
    },
  };
};

const GET_PROJECT_INFORMATIONS = gql`
  query getProjectInformations($project_id: ID!) {
    getProjectInformations(project_id: $project_id) {
      name
      tipologia
      numero
    }
  }
`;

const GET_PROJECT_INFORMATIONS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const GET_PROJECT_CONFIG = gql`
  query getProject($id: ID!) {
    getProject(id: $id) {
      ${PROJECT_DATA_CONFIG}
    }
  }
`;

const GET_PROJECT_CONFIG_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      id: pId,
    },
  };
};

const UPDATE_PROJECT_CONFIG = gql`
  mutation updateProjects($id: ID!, $input: ProjectInput!){
    updateProjects(id: $id, input: $input){
      ${PROJECT_DATA_CONFIG}
    }
  }
`;

const UPDATE_PROJECT_CONFIG_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};

const REMOVE_CLIENT_INTEREST = gql`
  mutation removeClientInterest($project_id: ID!, $clientId: ID!, $appartmentId: ID!, $movementId: ID!) {
    removeClientInterest(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId, movementId: $movementId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const REMOVE_CLIENT_INTEREST_DEFAULT_OPTIONS = (pId, id, app, mov) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      appartmentId: app,
      movementId: mov,
    },
  };
};

const REMOVE_CLIENT_APPARTMENT = gql`
  mutation removeClientAppartment($project_id: ID!, $clientId:ID!, $appartmentId: ID!) {
    removeClientAppartment(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const REMOVE_CLIENT_APPARTMENT_DEFAULT_OPTIONS = (pId, id, app) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      appartmentId: app,
    },
  };
};

const IMPORT_APARTMENT_EXCEL = gql`
  mutation importExcel($file: String!, $project_id: ID!) {
    importExcel(file: $file, project_id: $project_id)
  }
`;

const IMPORT_APARTMENT_EXCEL_DEFAULT_OPTIONS = (file, projectId) => {
  return {
    variables: {
      file: file,
      project_id: projectId,
    },
  };
};

const GET_IMPORT_APPARTMENT = gql`
query getLastImportExcel($project_id: ID! ){
  getLastImportExcel(project_id: $project_id){
    ${IMPORT_DATA_TO_RETRIEVE}
  }
}
`;

const GET_IMPORT_APPARTMENT_DEAFULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'network-only',
  };
};

const GET_EXPORT_APPARTMENT = gql`
query exportExcel($project_id: ID! ){
  exportExcel(project_id: $project_id){
    ${EXPORT_DATA_TO_RETRIEVE}
  }
}
`;

const GET_EXPORT_APPARTMENT_DEAFULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'network-only',
  };
};

const UPDATE_CLIENT = gql`
  mutation updateClients($project_id: ID!, $id: ID!, $input: ClientInput!) {
    updateClients(project_id: $project_id, id: $id, input: $input) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_CLIENT_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const ACTIVATE_MYLIVING_MUTATION = gql`
  mutation createClientAndUser($project_id: ID!, $input: ClientUserInput!, $platform: Platforms) {
    createClientAndUser(project_id: $project_id, input: $input, platform: $platform)
  }
`;

const ACTIVATE_MYLIVING_MUTATION_DEFAULT_OPTIONS = (pId, input, platform) => {
  return {
    variables: {
      project_id: pId,
      input: input,
      platform: platform,
    },
  };
};

const DELETE_CLIENT = gql`
  mutation deleteClients($project_id: ID!, $id: ID!) {
    deleteClients(project_id: $project_id, id: $id)
  }
`;

const DELETE_CLIENT_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const DELETE_MY_HOME = gql`
  mutation deleteUserByClientIdProjectId($clientId: ID!, $project_id: ID!) {
    deleteUserByClientIdProjectId(clientId: $clientId, project_id: $project_id)
  }
`;

const DELETE_MY_HOME_DEFAULT_OPTIONS = (id, pId) => {
  return {
    variables: {
      clientId: id,
      project_id: pId,
    },
  };
};

const CREATE_CLIENT = gql`
  mutation createClients($project_id: ID!, $input: ClientInput!) {
    createClients(project_id: $project_id, input: $input) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const CREATE_CLIENT_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input: input,
    },
  };
};

const LOGIN = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProject(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
      }
      user {
        id
        firstName
        lastName
        createdOn
        email
        language
        role
      }
    }
  }
`;

const LOGIN_DEFAULT_OPTIONS = (email, password, project_id) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
    },
  };
};
const GET_VENDOR = gql`
  query getVendorByEmail($email :String!) {
    getVendorByEmail(email: $email) {
      ${VENDOR_DATA_TO_RETRIEVE}
    }
  }`;
const GET_ALL_USERS = gql`
  query getAllUsers($project_id: ID){
    getAllUsers(project_id: $project_id){
      ${USER_DATA_TO_RETRIEVE}
    }
  }
 `;

const GET_ALL_USERS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    fetchPolicy: 'cache-and-network',
  };
};

const UPDATE_USER = gql`
  mutation updateUsers( $id: ID!, $input: UserInput!) {
    updateUsers( id: $id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_USER_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};

const UPDATE_USER_MANAGER_DEFAULT_OPTIONS = (id, input, project_id) => {
  return {
    variables: {
      id: id,
      input: input,
      project_id,
    },
  };
};

const UPDATE_USER_MANAGER = gql`
  mutation updateUsersByAccountManager( $id: ID!, $input: UserInput!, $project_id: ID) {
    updateUsersByAccountManager( id: $id, input: $input, project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUsersByAccountManager($id: ID!, $project_id: ID!) {
    deleteUsersByAccountManager(id: $id, project_id: $project_id)
  }
`;

const DELETE_USER_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const CREATE_USER_MANAGER = gql`
  mutation createUsersByAccountManager($project_id: ID!, $input: UserInput!) {
    createUsersByAccountManager(project_id: $project_id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const CREATE_USER_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input: input,
    },
  };
};

const CONFIRM_PASSWORD_USER = gql`
  mutation confirmUserAndChangePassword(
    $project_id: ID!
    $token: String!
    $password: String!
    $newPassword: String!
  ) {
    confirmUserAndChangePassword(
      project_id: $project_id
      token: $token
      password: $password
      newPassword: $newPassword
    )
  }
`;

const CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS = (project_id, token, password, newPassword) => {
  return {
    variables: {
      token,
      password,
      newPassword,
      project_id,
    },
  };
};

const GET_ALL_VENDORS = gql`
query getAllVendorsProfile($project_id: ID!) {
  getAllVendorsProfile(project_id: $project_id) {
    ${VENDOR_DATA_TO_RETRIEVE_ALL}
  }
}`;

const GET_ALL_VENDORS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      tokenType
      accessToken
      refreshToken
      expiresIn
    }
  }
`;

const REFRESH_TOKEN_DEFAULT_OPTIONS = (refreshToken, email) => {
  return {
    variables: {
      input: {
        refreshToken,
        email,
      },
    },
  };
};

const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
  };
};

const ACTIVATE_MYHOME_MUTATION = gql`
  mutation createClientAndUser($project_id: ID!, $input: ClientUserInput!, $platform: Platforms) {
    createClientAndUser(project_id: $project_id, input: $input, platform: $platform)
  }
`;

const ACTIVATE_MYHOME_OPTIONS = (project_id, input, platform) => {
  return {
    variables: {
      project_id,
      input,
      platform,
    },
    fetchPolicy: 'no-cache',
  };
};

const GET_NOTIFICATIONS_LIST = gql`
  query getClientNotifications ($project_id: ID!, $client: ID, $page: Int, $perPage: Int) {
    getClientNotifications (project_id: $project_id, client: $client, page: $page, perPage: $perPage) {
    ${NOTIFICATIONS_LIST_DATA_TO_RETRIEVE}
  }
}`;

const GET_NOTIFICATIONS_LIST_DEFAULT_OPTIONS = (notificationsInput) => {
  const options = {
    variables: notificationsInput,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };
  return options;
};

const SEND_COMMUNICATION_TO_COMMUNITY = gql`
  mutation sendNotificationToCommunityMutation(
    $project_id: ID!
    $input: NotifyCommunityInput!
    $client_id: ID
    $sendEmail: Boolean
  ) {
    sendNotificationToCommunity(
      project_id: $project_id
      input: $input
      client_id: $client_id
      sendEmail: $sendEmail
    ) {
      __typename
      ... on Notification {
        ...NotificationFragment
      }
      ... on MyLivingError {
        ...MyLivingErrorFragment
      }
    }
  }

  ${NOTIFICATION_FRAGMENT}
  ${MYLIVING_ERROR_FRAGMENT}
`;

const SEND_COMMUNICATION_TO_COMMUNITY_OPTIONS = (notificationsInput) => {
  const options = {
    variables: notificationsInput,
    fetchPolicy: 'no-cache',
  };

  return options;
};

const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications(
    $project_id: ID!
    $filters: NotificationFilter!
    $page: Int
    $perPage: Int
  ) {
    getAllNotifications(
      project_id: $project_id
      filters: $filters
      page: $page
      perPage: $perPage
    ) {
      __typename
      ... on FullResultList {
        count
        data {
          ...FullNotificationFragment
        }
      }
      ... on MyLivingError {
        ...MyLivingErrorFragment
      }
    }
  }
  ${FULL_NOTIFICATION_FRAGMENT}
  ${MYLIVING_ERROR_FRAGMENT}
`;

const GET_ALL_NOTIFICATIONS_OPTIONS = (notificationsOptions) => {
  const options = {
    variables: notificationsOptions,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

const GET_NOTIFICATION = gql`
  query getNotification($project_id: ID!, $notification_id: ID!) {
    getNotification(project_id: $project_id, notification_id: $notification_id) {
      __typename
      ... on Notification {
        ...NotificationFragment
      }
      ... on MyLivingError {
        ...MyLivingErrorFragment
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
  ${MYLIVING_ERROR_FRAGMENT}
`;

const GET_NOTIFICATION_OPTIONS = (notificationsOptions) => {
  const options = {
    variables: notificationsOptions,
    fetchPolicy: 'no-cache',
  };

  return options;
};

const GET_ALERTS = gql`
  query getAlerts($project_id: ID!, $client: ID) {
    getAlerts(project_id: $project_id, client: $client) {
      __typename
      ...AlertFragment
    }
  }
  ${ALERT_FRAGMENT}
`;

const GET_ALERTS_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };
};

const SET_NOTIFICATION_STATE = gql`
  mutation setNotificationState($id: ID!, $client_id: ID, $project_id: ID, $state: NotificationState!, $archive: Boolean, $from: NotificationCreationUser!) {
    setNotificationState(id: $id, client_id: $client_id, project_id: $project_id, state: $state, archive: $archive, from: $from) {
      ${SET_NOTIFICATION_DATA}
    }
  }
  ${ERRORS_FRAGMENT}
`;
const SET_NOTIFICATION_STATE_OPTIONS = (notificationDataInput) => {
  const options = {
    variables: notificationDataInput,
    fetchPolicy: 'no-cache',
  };

  return options;
};

const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($project_id: ID!, $id: ID!) {
    deleteNotification(project_id: $project_id, id: $id) {
      ${SET_NOTIFICATION_DATA}
    }
  }
  ${ERRORS_FRAGMENT}
`;

const DELETE_NOTIFICATION_OPTIONS = (notificationDataInput) => {
  const options = {
    variables: notificationDataInput,
    fetchPolicy: 'no-cache',
  };

  return options;
};

const GET_BUILDING = gql`
  query getBuilding($project_id: ID!, $id: ID!) {
    getBuilding(project_id: $project_id, id: $id) {
      ${BUILDING}
    }
  }
`;

const GET_BUILDING_OPTIONS = (project_id, id) => {
  const options = {
    variables: {
      project_id: project_id,
      id: id,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

const CREATE_PDF = gql`
  mutation createPDF($project_id: ID!, $quoteId: ID!, $clientId: ID!, $pdf_type: PdfTemplate!, $isRemake: Boolean) {
    createPDF(project_id: $project_id, quoteId: $quoteId, clientId: $clientId, pdf_type: $pdf_type, isRemake: $isRemake) {
      ${ID_DOCUMENT_TO_RETRIEVE}
    }
  }
`;

const CREATE_PDF_OPTIONS = (project_id, quoteId, clientId) => {
  const options = {
    variables: {
      project_id: project_id,
      quoteId: quoteId,
      clientId: clientId,
      pdf_type: 'RENT_PROPOSAL',
      isRemake: true,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

const SEND_PROPOSAL_RENT = gql`
  mutation sendProposalRent(
    $project_id: ID!
    $quoteId: ID!
    $stripeId: String
    $documents: [DuplicateDocumentInput]
    $force: Boolean
  ) {
    sendProposalRent(
      project_id: $project_id
      quoteId: $quoteId
      stripeId: $stripeId
      documents: $documents
      force: $force
    )
  }
`;

const SEND_PROPOSAL_RENT_OPTIONS = (project_id, quoteId) => {
  const options = {
    variables: {
      project_id: project_id,
      quoteId: quoteId,
      stripeId: null,
      documents: null,
      force: true,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

const MUTATION_LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS_V2 = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
    fetchPolicy: 'network-only',
  };
};

const Client = {
  ...aptModules,
  ...quoteModules,
  ...productCategoryModules,
  ...proposalModules,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_DEFAULT_OPTIONS,
  GET_CLIENTS_DATA,
  GET_CLIENTS_DATA_DEFAULT_OPTIONS,
  GET_CLIENT,
  GET_CLIENT_DEFAULT_OPTIONS,
  GET_TYPOLOGIES,
  GET_TYPOLOGIES_DEFAULT_OPTIONS,
  INSERT_CLIENT_INTEREST,
  INSERT_CLIENT_INTEREST_DEFAULT_OPTIONS,
  INSERT_CLIENT_APPARTMENT,
  INSERT_CLIENT_APPARTMENT_DEFAULT_OPTIONS,
  REMOVE_CLIENT_INTEREST,
  REMOVE_CLIENT_INTEREST_DEFAULT_OPTIONS,
  REMOVE_CLIENT_APPARTMENT,
  REMOVE_CLIENT_APPARTMENT_DEFAULT_OPTIONS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_DEFAULT_OPTIONS,
  CREATE_CLIENT,
  CREATE_CLIENT_DEFAULT_OPTIONS,
  GET_VENDOR,
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  DELETE_CLIENT,
  DELETE_CLIENT_DEFAULT_OPTIONS,
  LOGIN,
  LOGIN_DEFAULT_OPTIONS,
  GET_PROJECT_INFORMATIONS,
  GET_PROJECT_INFORMATIONS_DEFAULT_OPTIONS,
  GET_PROJECT_CONFIG,
  GET_PROJECT_CONFIG_DEFAULT_OPTIONS,
  UPDATE_PROJECT_CONFIG,
  UPDATE_PROJECT_CONFIG_DEFAULT_OPTIONS,
  GET_ALL_CALENDARS_BY_VENDOR,
  GET_ALL_CALENDARS_BY_VENDOR_DEFAULT_OPTIONS,
  GET_ALL_CALENDARS_FO,
  GET_ALL_CALENDARS_FO_DEFAULT_OPTIONS,
  GET_ALL_CALENDARS_BY_VENDORS,
  GET_ALL_CALENDARS_BY_VENDORS_DEFAULT_OPTIONS,
  GET_CALENDAR,
  GET_CALENDAR_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDAR,
  GET_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDAR_BY_PROJECT,
  GET_COMMUNITY_CALENDAR_BY_PROJECT_DEFAULT_OPTIONS,
  GET_SERVICES_BY_PROJECT,
  GET_SERVICES_BY_PROJECT_DEFAULT_OPTIONS,
  CREATE_COMMUNITY_CALENDAR,
  CREATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  CREATE_CALENDAR,
  UPDATE_COMMUNITY_CALENDAR,
  UPDATE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  DELETE_COMMUNITY_CALENDAR,
  DELETE_COMMUNITY_CALENDAR_DEFAULT_OPTIONS,
  GET_COMMUNITY_CALENDARS,
  GET_COMMUNITY_CALENDARS_DEFAULT_OPTIONS,
  CREATE_CALENDAR_DEFAULT_OPTIONS,
  CREATE_CALENDAR_LIST,
  CREATE_CALENDAR_LIST_DEFAULT_OPTIONS,
  UPDATE_CALENDAR,
  UPDATE_CALENDAR_DEFAULT_OPTIONS,
  DELETE_CALENDAR,
  DELETE_AND_REASSIGN_CALENDAR,
  DELETE_CALENDAR_DEFAULT_OPTIONS,
  IS_FIRST_CALENDAR,
  IS_FIRST_CALENDAR_DEFAULT_OPTIONS,
  IMPORT_APARTMENT_EXCEL,
  IMPORT_APARTMENT_EXCEL_DEFAULT_OPTIONS,
  GET_QUOTE_DOCUMENT,
  GET_QUOTE_DOCUMENT_DEFAULT_OPTIONS,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_DEFAULT_OPTIONS,
  ACTIVATE_MYLIVING_MUTATION,
  ACTIVATE_MYLIVING_MUTATION_DEFAULT_OPTIONS,
  GET_CLIENT_DOCUMENT,
  GET_CLIENT_DOCUMENT_DEFAULT_OPTIONS,
  GET_QUOTES_BYAPARTMENT,
  GET_QUOTES_BYAPARTMENT_DEFAULT_OPTIONS,
  GET_QUOTES_BYCLIENT,
  GET_QUOTES_BYCLIENT_DEFAULT_OPTIONS,
  UPDATE_EXPIRED_QUOTE,
  UPDATE_EXPIRED_QUOTE_DEFAULT_OPTIONS,
  UPDATE_RESERVED_QUOTE,
  UPDATE_RESERVED_QUOTE_DEFAULT_OPTIONS,
  UPDATE_QUOTE_PROPOSAL,
  UPDATE_QUOTE_PROPOSAL_DEFAULT_OPTIONS,
  GET_IMPORT_APPARTMENT,
  GET_IMPORT_APPARTMENT_DEAFULT_OPTIONS,
  GET_EXPORT_APPARTMENT,
  GET_EXPORT_APPARTMENT_DEAFULT_OPTIONS,
  GET_ALL_CLIENTS_ADDITIONAL_INFO,
  GET_ALL_CLIENTS_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  UPDATE_ADDITIONAL_INFO,
  UPDATE_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  DELETE_QUOTE,
  DELETE_QUOTE_DEFAULT_OPTIONS,
  GET_ALL_VENDORS,
  GET_ALL_VENDORS_DEFAULT_OPTIONS,
  GET_ALL_USERS,
  GET_ALL_USERS_DEFAULT_OPTIONS,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_OPTIONS,
  UPDATE_USER_MANAGER_DEFAULT_OPTIONS,
  UPDATE_USER_MANAGER,
  DELETE_USER,
  DELETE_USER_DEFAULT_OPTIONS,
  CREATE_USER_MANAGER,
  CREATE_USER_DEFAULT_OPTIONS,
  CONFIRM_PASSWORD_USER,
  CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS,
  CREATE_ADDITIONAL_INFO,
  CREATE_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  DELETE_ADDITIONAL_INFO,
  DELETE_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  ACTIVATE_MYHOME_MUTATION,
  ACTIVATE_MYHOME_OPTIONS,
  DELETE_MY_HOME,
  DELETE_MY_HOME_DEFAULT_OPTIONS,
  DELETE_CALENDAR_LIST,
  DELETE_CALENDAR_LIST_DEFAULT_OPTIONS,
  GET_PROJECT_FEATURES,
  GET_PROJECT_FEATURES_DEFAULT_OPTIONS,
  GET_PROJECT_FEATURE,
  GET_PROJECT_FEATURE_DEFAULT_OPTIONS,
  UPDATE_PERTINENZA_INFO,
  UPDATE_PERTINENZA_INFO_DEFAULT_OPTIONS,
  GET_NOTIFICATIONS_LIST,
  GET_NOTIFICATIONS_LIST_DEFAULT_OPTIONS,
  SEND_COMMUNICATION_TO_COMMUNITY,
  SEND_COMMUNICATION_TO_COMMUNITY_OPTIONS,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_OPTIONS,
  SET_NOTIFICATION_STATE,
  SET_NOTIFICATION_STATE_OPTIONS,
  GET_ALERTS,
  GET_ALERTS_OPTIONS,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_OPTIONS,
  GET_NOTIFICATION,
  GET_NOTIFICATION_OPTIONS,
  GET_BUILDING,
  GET_BUILDING_OPTIONS,
  GENERATE_QUOTE_PDF,
  GENERATE_QUOTE_PDF_OPTIONS,
  GET_ALL_MY_LIVING_CLIENTS,
  GET_ALL_MY_LIVING_CLIENTS_OPTIONS,
  CREATE_PDF,
  CREATE_PDF_OPTIONS,
  SEND_PROPOSAL_RENT,
  SEND_PROPOSAL_RENT_OPTIONS,
  MUTATION_LOGOUT,
  GET_USER_INFO_DEFAULT_OPTIONS_V2,
};

export default Client;
