export const SPACE_AVAILABILITY_STATUS = {
  NO: 'no',
  SI: 'si',
  TEMPORANEAMENTE_SOSPESO: 'temporaneamente_sospeso',
};

export const SPACE_AVAILABILITY_STATUS_ORDER = [
  'temporarily_suspended',
  'not_available',
  'available',
];
