import CurrencyFormatter from 'utils/CurrencyFormatter.jsx';
import colors from 'styles/colors.scss';

const defaultColors = [
  colors.LIBERO,
  colors.INTERESSE,
  colors.PROPOSTA,
  colors.AFFITTATO,
  colors.COMPROMESSO,
  colors.OPZIONATO,
];
const availableStatus = ['libero', 'interesse', 'opzionato', 'proposta', 'compromesso', 'rogitato'];
const availableSizes = ['Bilocale', 'Trilocale', 'Quadrilocale', 'Pentalocale Duplex'];
const shortNames = {
  monolocale: 'Mono',
  bilocale: 'Bilo',
  trilocale: 'Trilo',
  quadrilocale: 'Quadri',
  pentalocale: 'Penta',
  penthouse: 'Pent',
  'pentalocale duplex': 'Penta',
  duplex: 'Duplex',
};
const keyTofilter = 'status';
const borderColor = 'rgba(0,0,0,0)';
const horizontalBarChartOptions = {
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: { beginAtZero: true },
        stacked: true,
      },
    ],
    yAxes: [{ gridLines: { display: false }, stacked: true }],
  },
  tooltips: {
    enabled: false,
  },
  responsive: true,
  maintainAspectRatio: false,
};
const doughnutChartOptions = (currency, locale) => {
  return {
    title: {
      display: false,
    },
    legend: {
      display: true,
      position: 'left',
      align: 'start',
      labels: {
        padding: 15,
        generateLabels(chart) {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${label} (${CurrencyFormatter(currency, locale, data.datasets[0].data[i])})`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                index: i,
              };
            });
          }
          return [];
        },
      },
    },
    tooltips: {
      enabled: true,
    },
    responsive: true,
    maintainAspectRatio: false,
  };
};
const verticalBarChartOptions = {
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{ gridLines: { color: colors.BACKGROUNDS, display: false } }],
    yAxes: [{ gridLines: { color: colors.BACKGROUNDS, display: true } }],
  },
  tooltips: {
    enabled: true,
  },
  responsive: true,
  maintainAspectRatio: false,
};

const ChartConstants = {
  defaultColors,
  availableStatus,
  availableSizes,
  shortNames,
  keyTofilter,
  borderColor,
  horizontalBarChartOptions,
  verticalBarChartOptions,
  doughnutChartOptions,
};

export default ChartConstants;
