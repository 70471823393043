import React from 'react';
import { useWinstonLogger } from 'winston-react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import { useStore } from '../store/storeUtils';
import { useHistory } from 'react-router-dom';
import { HomeRoute } from 'routes/Routes';
import useLogout from 'customHooks/useLogout';
import { generateNewHostname } from 'utils/util';

const ClientComponent = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { logOut } = useLogout();
  const logger = useWinstonLogger();

  React.useEffect(() =>
    autorun(async () => {
      const isLoggedIn = store.projectId && store.loggedUser;
      if (isLoggedIn) {
        // l'utente è loggato, lo riporto alla pagina richiesta
        const params = new URLSearchParams(window.location.search);
        params.delete('hostname');
        history.push(`${HomeRoute.to}?${params}`);
      } else if (store.checkingJwt) {
        // controllo jwt in corso, aspetto
      } else {
        // Se non esiste il JWT l'utente non è loggato e lo rimando alla login
        // Creo l'url di reindirizzamento in base all'ambiente in cui ci troviamo
        const backToUrl = new URL(window.location.href);
        const params = new URLSearchParams(backToUrl.search);
        params.delete('hostname');
        let backTo = `${window.location.origin}${window.location.pathname}?${params}`;
        backTo = backTo.replace('/login?', '');

        const finalHostName = generateNewHostname(`backTo=${encodeURIComponent(backTo)}`, '/login');
        try {
          let hasLoggedOut = await logOut();
          if (hasLoggedOut && hasLoggedOut.called && !hasLoggedOut.loading) {
            window.location.href = `https://${finalHostName}`;
          }
        } catch (e) {
          logger.error(e);
          window.location.href = `https://${finalHostName}`;
        }
      }
    }, [store.projectId, store.loggedUser, store.checkingJwt]),
  );

  return <></>;
});

export default ClientComponent;
