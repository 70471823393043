import React from 'react';

import CrmSvgLoader from 'components/common/CrmSvgLoader';
import CrmButton from 'components/common/CrmButton';
import LogoTecma from 'images/assets/tecma_logo.svg';
import '../styles/error-page.scss';

const ErrorPage = ({ title, message, buttonText, onClick }) => (
  <div className="error-page">
    <CrmSvgLoader data={LogoTecma} alt="Tecma logo" className="error-page-logo" />
    <div className="error-page-content">
      <p className="error-page-title">{title}</p>
      <p className="error-page-message">{message}</p>
      <CrmButton className="error-page-button" variant="outlined" onClick={onClick}>
        {buttonText}
      </CrmButton>
    </div>
  </div>
);

ErrorPage.defaultProps = {
  title: 'Qualcosa è andato storto',
  message:
    'Non siamo riusciti a trovare la pagina che cercavi oppure c’è stato un errore inaspettato.',
  buttonText: 'Torna alla Homepage',
  onClick: () => {
    window.location.href = '/';
  },
};

export default ErrorPage;
