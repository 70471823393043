/**
 * a generic function to capitalize strings
 * @param {string} string what must be capitalized
 */
const Capitalize = (string) => {
  if (!string) return undefined;
  return string
    .toLowerCase()
    .replaceAll('_', ' ')
    .replaceAll('-', ' ')
    .replace(/^\w|\s\w/g, (c) => c.toUpperCase());
};

export default Capitalize;
