import colors from './colors.scss';
import variables from './variables.scss';

export const CrmMUITheme = {
  typography: {
    fontFamily: variables.DEFAULT_FONT,
    fontDisplay: 'swap',
    fontWeight: variables.LIGHT,
  },
  overrides: {
    MuiTypography: {
      caption: {
        color: '#1E201F',
        fontSize: '12px',
      },
      root: {
        fontSize: '12px!important',
      },
    },
    MuiPaper: {
      root: {
        color: colors.DEFAULT_FONT_COLOR + '!important',
        borderRadius: '0px!important',
        zIndex: '0',
        border: '1px solid #E8E9EB',
        backgroundColor: 'transparent',
      },
      elevation1: {
        boxShadow: 'none!important',
        border: '0px!important',
      },
      elevation2: {
        boxShadow: 'none!important',
        border: '0px!important',
      },
      elevation4: {
        boxShadow: 'none !important',
      },
      elevation8: {
        boxShadow: 'none !important',
      },
      elevation24: {
        boxShadow: 'none !important',
      },
    },
    MuiButton: {
      root: {
        color: colors.DEFAULT_FONT_COLOR,
        borderRadius: '0px',
      },
      startIcon: {
        marginLeft: '-2px',
        marginRight: '20px',
      },
      textPrimary: {
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      outlined: {
        border: '1px solid #EBE9EB',
      },
    },
    MuiDrawer: {
      paper: {
        borderRight: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '3',
      },
      paperAnchorDockedLeft: {
        borderRight: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '3',
      },
      paperAnchorRight: {
        borderLeft: 'none!important',
        backgroundColor: '#E8E9EB',
        zIndex: '4',
      },
    },
    MuiListItem: {
      root: {
        justifyContent: 'flex-start !important',
        height: '48px',
        width: '100%!important',
        marginTop: '-1px',
        position: 'relative',
        borderBottom: '1px solid #CACBD3',
        padding: '0px !important',
        '&:hover': {
          backgroundColor: '#CACBD3',
        },
        '&$selected': {
          borderBottom: '1px solid #f5f5fc',
          borderTop: '1px solid #f5f5fc',
          zIndex: '2',
          backgroundColor: colors.BACKGROUND_PAGE,
          '&:active': {
            backgroundColor: colors.BACKGROUND_PAGE,
          },
          '&:hover': {
            borderBottom: '1px solid #CACBD3',
            borderTop: '1px solid #CACBD3',
          },
          '& div.navbar-image-size': {
            borderLeft: '2px solid #6BC1B4 !important',
            paddingLeft: '6px!important',
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        width: '100px',
        borderRadius: '0px',
      },
      list: {
        padding: '0px!important',
        width: '100%!important',
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: '5px!important',
        width: '100%',
        height: '32px',
        backgroundColor: '#E8E9EB !important',
        fontSize: '12px',
        border: '0px',
        '&$selected': {
          backgroundColor: '#1E201F !important',
          color: 'white',
          border: '0px',
          width: '100%',
        },
        '&:hover': {
          backgroundColor: '#CACBD3!important',
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        backgroundColor: '#E8E9EB',
        border: 'none',
        borderRadius: '0px',
      },
    },
    MuiToggleButton: {
      root: {
        width: '20px!important',
        border: 'none',
        marginRight: '5px',
        color: '#1E201F',
        fontSize: '18px',
        height: '18px',
        borderRadius: '0px',
        fontWeight: '600',

        '&$selected': {
          backgroundColor: '#E8E9EB',
          textDecoration: 'underline',
          color: '#1E201F',
          '&:hover': {
            backgroundColor: '#E8E9EB',
            textDecoration: 'underline',
          },
        },
        '&:hover': {
          backgroundColor: '#E8E9EB',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottomWidth: '0px!important',
        borderTopWidth: '0px!important',
        fontSize: '14px',
      },
      body: {
        fontWeight: '600',
        padding: '5px 16px 5px 16px!important',
      },
      head: {
        textTransform: 'uppercase',
        fontWeight: '700',
        backgroundColor: '#E8E9EB!important',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        zIndex: '0!important',
        padding: '5px 16px 5px 16px!important',
        flexDirection: 'row!important',
      },
      footer: {
        width: 'fit-content!important',
        overflowX: 'hidden!important',
        '@media (max-width: 320px)': {
          position: 'relative',
          left: '-16px',
        },
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: 'white !important',
        height: '56px!important',
      },
      footer: {
        backgroundColor: 'transparent !important',
      },
    },
    MuiTablePagination: {
      toolbar: {
        display: 'flex',
        flexFlow: 'row-reverse',
        backgroundColor: 'transparent!important',
      },
    },
    MuiToolbar: {
      root: {
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        flexWrap: 'wrap',
      },
      gutters: {
        paddingLeft: '0px!important',
        paddingRight: '0px!important',
        flexWrap: 'wrap',
      },
    },
    MuiSelect: {
      root: {
        border: '1px solid #E8E9EB!important',
        padding: '4px 25px 4px 5px!important',
        color: 'black',
        fontSize: '12px',
        fontFamily: 'SagoeRegular',
      },
    },

    MuiIconButton: {
      root: {
        color: 'black',
        '&:hover': {
          backgroundColor: 'none !important',
        },
      },
      colorPrimary: {
        color: '#EEEEEE !important',
      },
      edgeEnd: {
        marginRight: '5px!important',
      },
    },
    MuiInputBase: {
      root: {
        lineHeight: '1rem',
      },
    },
    MuiInput: {
      input: {
        fontSize: '14px',
        color: 'black',
        fontWeight: '600',
        height: '2rem',
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #E8E9EB',
        },
        '&:after': {
          borderBottom: '2px solid #6BC1B4',
        },
        '&&&&:hover:before': {
          borderBottom: '1px solid #CACBD3',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      icon: {
        height: '25px',
        width: '25px',
      },
    },
    MuiFormControl: {
      root: {
        padding: '0px!important',
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: '2px',
        fontSize: '14px',
      },
    },
    MuiSnackbar: {
      anchorOriginTopCenter: {
        top: '60px !important',
      },
    },
    MuiRadio: {
      root: {
        color: '#EEEEEE',
        paddingRight: '0px !important',
        '&$checked': {
          color: '#6BC1B4!important',
        },
        '&:hover': {
          backgroundColor: 'transparent!important',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#E8E9EB',
        paddingLeft: '0px !important',
        '&$checked': {
          color: '#6BC1B4 !important',
        },
        '&:hover': {
          backgroundColor: 'transparent!important',
        },
      },
      colorSecondary: {
        color: '#E8E9EB !important',
      },
    },
    MuiAvatar: {
      root: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
      },
      img: {
        width: '24px',
        height: '24px',
      },
    },
    MuiIcon: {
      root: {
        fontSize: '18px',
      },
    },
    MuiStepIcon: {
      root: {
        width: '40px',
        height: '40px',
        color: '#E8E9EB',
      },
      active: {
        color: '#6BC1B4!important',
      },
      completed: {
        color: '#6BC1B4!important',
      },
      text: {
        fill: '#989898',
        fontSize: '0.65rem',
      },
    },
    MuiStepLabel: {
      labelContainer: {
        fontSize: '14px',
      },
      label: {
        fontWeight: '900',
        color: 'black',
      },
      active: {
        fontWeight: '900!important',
      },
      completed: {
        fontWeight: '900!important',
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderColor: '#E8E9EB',
      },
      alternativeLabel: {
        top: '20px',
      },
      active: {
        '& $line': {
          borderColor: '#6BC1B4!important',
        },
      },
      completed: {
        '& $line': {
          borderColor: '#6BC1B4!important',
        },
      },
    },
    MuiAutocomplete: {
      root: {
        width: '100%',
      },
      input: {
        paddingLeft: '0px !important',
      },
      endAdornment: {
        top: 'auto',
      },
      popper: {
        backgroundColor: 'white',
      },
      option: {
        padding: '0px !important',
        paddingLeft: '10px !important',
        color: 'black',
        fontWeight: '600',
        border: '0px !important',
        textTransform: 'capitalize',
        backgroundColor: 'white !important',
        '&:hover': {
          color: 'white',
          backgroundColor: '#6bc1b4 !important',
        },
      },
      popupIndicator: {
        '& span': {
          '& svg': {
            '& path': {
              d: "path('M 16.59 8.59 L 12 13.17 L 7.41 8.59 L 6 10 l 6 6 l 6 -6 Z')",
            },
          },
        },
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiFormLabel: {
      filled: {
        width: '250px',
      },
      root: {
        fontSize: '14px',
      },
    },
    MuiInputLabel: {
      root: {
        height: '15px',
        display: 'flex',
      },
    },
    MuiAppBar: {
      root: {
        border: '0px !important',
      },
      colorPrimary: {
        backgroundColor: 'transparent !important',
      },
    },
    MuiTab: {
      root: {
        padding: '8px 16px',
        textTransform: 'none !important',
        marginRight: '12px !important',
        borderTop: '1px solid #E8E9EB !important',
        borderLeft: '1px solid #E8E9EB !important',
        borderRight: '1px solid #E8E9EB !important',
        '@media (max-width: 750px)': {
          lineHeight: '1.1',
        },
        backgroundColor: '#ffffff',
      },
      textColorInherit: {
        fontSize: '16px',
        color: 'black',
        stroke: 'black',
        fontFamily: 'SagoeRegular',
        fontWeight: '400',
        backgroundColor: '#ffffff',
        '&$selected': {
          stroke: '#6BC1B4!important',
          fill: '#6BC1B4!important',
          color: '#6BC1B4!important',
          fontFamily: 'SagoeBold',
          backgroundColor: '#f6f7f7',
          position: 'relative',
          zIndex: '2',
          marginBottom: '-1px',
          strokeWidth: '1.2',
        },
      },
    },
    MuiTabs: {
      scrollable: {
        overflowY: 'hidden',
      },
      indicator: {
        backgroundColor: 'rgba(232, 233, 235, 0)!important',
      },
      flexContainer: {
        height: '55px',
        marginTop: '10px',
        marginRight: '10px',
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: 'row',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: '10px',
      },
    },
    MuiSwitch: {
      root: {
        width: 'fit-content !important',
        height: 'fit-content!important',
        overflow: 'visible!important',
      },
      thumb: {
        width: '25px!important',
        height: '25px!important',
      },
      track: {
        height: '15px!important',
        width: '40px!important',
      },
      switchBase: {
        top: '-3px!important',
        left: '-5px!important',
        color: '#FBFBFB !important',
        cursor: 'pointer !important',
        '& + $track': {
          backgroundColor: '#A9A9A9 !important',
          opacity: '1',
        },
        '&$disabled': {
          color: '#DFE0E2 !important',
          cursor: 'not-allowed !important',
          pointerEvents: 'unset !important',
          '& + $track': {
            backgroundColor: '#DFE0E2 !important',
            opacity: '1',
          },
          '&$checked': {
            color: '#C4E2DE !important',
            left: '5px!important',
            '& + $track': {
              backgroundColor: '#D9EFEC !important',
            },
          },
        },
        '&$checked': {
          color: '#6BC1B4 !important',
          left: '5px!important',
          '& + $track': {
            backgroundColor: 'rgba(107, 193, 180, 0.26) !important',
            opacity: '1',
          },
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      },
    },
    MuiExpansionPanel: {
      root: {
        width: '100%',
        padding: '0',
        marginTop: '10px',
        '&:before': {
          position: 'unset',
        },
        '&$expanded': {
          marginTop: '10px',
          marginBottom: '0px',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        paddingLeft: '4px',
        paddingRight: '4px',
        borderBottom: '1px solid #E8E9EB !important',
        height: '68px',
        '&$expanded': {
          height: '68px',
        },
      },
      expandIcon: {
        '&$expanded': {
          marginRight: '-8px !important',
        },
      },
      content: {
        fontSize: '14px',
        fontWeight: '600',
        margin: '16px 0',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        display: 'block',
        padding: '0',
      },
    },
    MuiAccordion: {
      root: {
        width: '100%',
        backgroundColor: 'white',
        zIndex: 'unset!important',
      },
    },
    MuiAccordionSummary: {
      root: {
        height: '80px',
        paddingLeft: '0px !important',
        '@media (max-width: 599px)': {
          height: '56px',
          paddingRight: '0px!important',
          '&$expanded': {
            minHeight: '56px',
          },
        },
      },
      content: {
        height: '100%',
        alignItems: 'center',
        margin: '0px',
      },
    },
    MuiAccordionDetails: {
      root: {
        justifyContent: 'center',
        borderTop: '1px solid #e8e9eb !important',
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        minWidth: '330px',
        backgroundColor: 'white',
        maxHeight: 'unset',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'white',
        minHeight: '1px!important',
        justifyContent: 'start',
        height: 'unset',
        paddingLeft: '16px !important',
        paddingTop: '16px!important',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'black',
        fontFamily: 'SagoeBold',
      },
      toolbarBtnSelected: {
        color: 'black',
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        alignItems: 'center',
        flexDirection: 'row-reverse',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        margin: '2px',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginBottom: '35px',
        minHeight: '200px',
        marginTop: '0px',
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        maxHeight: 'unset',
        height: '20px',
        marginBottom: '20px',
      },
      transitionContainer: {
        '& p': {
          color: 'black',
          fontSize: '14px !important',
          fontFamily: 'SagoeBold',
        },
      },
      dayLabel: {
        width: '42px',
        color: 'black',
        fontSize: '14px',
        fontFamily: 'SagoeBold',
        textTransform: 'Capitalize',
      },
      iconButton: {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      switchHeader: {
        marginTop: '10px',
      },
    },
    MuiPickersDay: {
      day: {
        width: '40px',
        height: '31px',
        margin: '3px',
      },
      current: {
        color: '#009688',
      },
      daySelected: {
        backgroundColor: '#6BC1B4',
        color: 'white',
        borderRadius: '0%',
        '&:hover': {
          backgroundColor: '#6BC1B4',
          color: 'black',
          borderRadius: '0%',
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        borderBottom: '1px solid #E8E9EB',
        minHeight: '320px',
        overflowY: 'hidden',
        maxWidth: 'unset',
        alignItems: 'center',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0px',
      },
    },
    MuiChip: {
      label: {
        textTransform: 'Capitalize',
      },
    },
    MuiBadge: {
      root: {
        right: '13px',
        top: '1px',
      },
      badge: {
        backgroundColor: '#f35959',
        color: '#fff',
        fontSize: '0.7rem',
        fontWeight: '700',
        padding: '0px',
        height: '15px',
        minWidth: '15px',
        paddingBottom: '2px',
      },
    },
  },
  palette: {
    primary: {
      main: colors.COLOR_PRIMARY,
    },
    secondary: {
      main: colors.COLOR_SECONDARY,
    },
  },
};
