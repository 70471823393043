import { observable, action, decorate, computed } from 'mobx';
import { persist } from 'mobx-persist';
import { datadogRum } from '@datadog/browser-rum';

import Initials from 'utils/Initials';
import ValidateEmail from 'utils/ValidateEmail';
import ValidatePhone from 'utils/ValidatePhone';
import moment from 'utils/MyMoment';

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.feKey = '';
    this.feVendorKey = '';
    this.breadCrumbs = null;
    this.loginProjectName = '';
    this.assetsUrl = null;
    this.searchAppartamenti = '';
    this.searchAppartamentiHome = '';
    this.searchClientiHome = '';
    this.searchClienti = '';
    this.hostKey = '';
    this.projectName = null;
    this.enabledStatus = null;
    this.alertDays = 5;
    this.isPackEditable = false;
    this.isQuoteEditable = false;
    this.projectId = null;
    this.enabledSections = [];
    this.dashboardConfig = undefined;
    this.currentNavTab = 0;
    this.apartmentId = 0;
    this.clientId = 0;
    this.vendorEmail = '';
    this.vendorPassword = '';
    this.errorModal = false;
    this.errorMessage = null;
    this.logo = [];
    this.eventId = null;
    this.assetLogin = [];
    this.externalPdf = null;
    this.menuMobile = false;
    this.avatarMenu = false;
    this.alertMenu = false;
    this.sendNotificationMenu = false;
    this.notificationMenu = false;
    this.appartmentModal = false;
    this.editApartmentAvailabilityModal = false;
    this.addingAppartments = false;
    this.removeAppartmentModal = false;
    this.removeClientModal = false;
    this.modifyingAppartmentOriginalState = null;
    this.removeAppartment = null;
    this.modifyingAppartment = {
      id: '',
      name: '',
      typology: '',
      status: '',
      mood: '',
      sides: [],
      packsList: [],
      movements: [],
    };
    this.statusProposal = null;
    this.confirmProposalModal = false;
    this.signProposalModal = false;
    this.validateProposalModal = false;
    this.refuseProposalModal = false;
    this.refuseProposal = false;
    this.resendProposalModal = false;

    this.pendingProposalModal = false;
    this.addInfoConfirmModal = false;
    this.tabConfirmModal = false;
    this.errorImportApartment = null;
    this.errorImportApartmentModal = false;
    this.expiredQuote = null;
    this.proposalQuoteName = null;
    this.renewTimeQuote = null;
    this.generatePdfQuoteModal = false;
    this.renewTimeQuoteModal = false;
    this.deleteQuote = null;
    this.regenerateProposalQuote = null;
    this.sendProposalQuote = null;
    this.generatePdfQuoteId = null;
    this.deleteQuoteModal = false;
    this.regenerateProposalModal = false;
    this.sendProposalModal = false;
    this.changeNotificationStatusModal = false;
    this.removeEvent = false;
    this.updateEvent = false;
    this.cancelEvent = false;
    this.eventList = [];
    this.communityEventList = [];
    this.activities = [];
    this.event = undefined;
    this.currentEvent = {};
    this.updEvent = false;
    this.calendarPopup = false;
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.clientToAdd = null;
    this.selectedClient = null;
    this.clientToSave = null;
    this.appartmentToSave = null;
    this.selectedAppartment = null;
    this.selectedBuilding = null;
    this.selectedNotification = null;
    this.selectedNotificationStatus = null;
    this.selectedSentNotification = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.realLocation = undefined;
    this.longLoading = false;
    this.rowLoading = false;
    this.globalEditing = false;
    this.infoEditing = false;
    this.pdfToView = undefined;
    this.project_id = undefined;
    this.vendorLogo = [];
    this.locale = null;
    this.currency = null;
    this.pageTitles = undefined;
    this.clients = undefined;
    this.appartments = undefined;
    this.users = undefined;
    this.userCalled = undefined;
    this.clientsFromFilter = null;
    this.clientsToFilter = null;
    this.appointmentConfig = null;
    this.appartamentoSort = [null, null, null, null, null];
    this.appartamentoHomeSort = [null, null, null, null, null];
    this.clienteSort = [null, null, null, null, null];
    this.clienteHomeSort = [null, null, null, null, null];
    this.systemLanguage = null;
    this.enabledTools = null;
    this.isMyLivingActive = null;
    this.myhomeConfig = undefined;
    this.featuresFlags = undefined;
    this.openDrawer = undefined;
    this.setItemModal = false;
    this.openAccountModal = false;
    this.openModifyVATDrawer = false;
    this.openChangeProposal = false;
    this.openReserveTimeModal = false;
    this.deleteAccountModal = false;
    this.stepperModal = false;
    this.activeMyHomeModal = false;
    this.activeMyLivingModal = false;
    this.acceptCountersignModal = false;
    this.rejectCountersignModal = false;
    this.deleteMyHomeModal = false;
    this.deleteMyLivingModal = false;
    this.calendarSettingsModal = false;
    this.errorCalendarSettings = false;
    this.updateAdditionalInfo = null;
    this.policyConfig = [];
    this.tabValueApartment = 0;
    this.pertinenze = undefined;
    this.extraSpaceToSave = undefined;
    this.selectedExtraSpace = undefined;
    this.extraSpaceId = undefined;
    this.selectedCro = undefined;
    this.checkingJwt = false;
    this.alertIsOpen = false;
    this.alertInvalidIsOpen = false;
    this.redirectionToSsoNeeded = false;
    this.calendarSyncRevokeSuccess = undefined;
    this.area = undefined;
    this.openNewsletterModal = false;
    this.defaultLang = '';
  }

  /* ACTIONS */

  /**
   * updates tab.
   * @param {number} newTab the new selected tab (id)
   * @param {string} location
   */
  updateCurrentNavTab(newTab, location) {
    if (newTab !== this.currentNavTab) {
      this.currentNavTab = newTab;
    }
    this.setRealLocation(location);
  }

  /**
   * sets new location
   * @param {string} val
   */
  setRealLocation(val) {
    this.realLocation = val;
  }

  setClientsFromFilter(val) {
    if (val) {
      // se ho un valore arrotondo alla mezzanotte per avere range temporale corretto (e non dipendente dall'ora di selezione del filtro)
      val = moment(new Date(val).setHours(0, 0, 0, 0));
    }

    this.clientsFromFilter = val;
  }

  setClientsToFilter(val) {
    if (val) {
      // se ho un valore arrotondo alla mezzanotte per avere range temporale corretto (e non dipendente dall'ora di selezione del filtro)
      val = moment(new Date(val).setHours(0, 0, 0, 0));
    }
    this.clientsToFilter = val;
  }

  /**
   * sets avatar's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setAvatarMenu(val) {
    this.avatarMenu = val;
  }

  /**
   * sets alert's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setAlertMenu(val) {
    this.alertMenu = val;
  }

  /**
   * sets alert's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setSendNotificationMenu(val) {
    this.sendNotificationMenu = val;
  }

  /**
   * sets notification's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setNotificationMenu(val) {
    this.notificationMenu = val;
  }

  /**
   * toggles mobile menu state (open/closed).
   */
  toggleMenuMobile() {
    this.menuMobile = !this.menuMobile;
  }

  /**
   * closes Menu Mobile drawer
   */
  closeMenuMobile() {
    this.menuMobile = false;
  }

  /**
   * sets the selected client
   * @param {Object} userData the selected client object
   */
  setSelectedClient(userData) {
    this.clientToSave = null;
    delete userData['idDocuments'];
    this.selectedClient = userData;
  }

  /**
   * sets the selected appartment
   * @param {Object} appartmentData the selected appartment object
   */
  setSelectedAppartment(appartmentData) {
    this.selectedAppartment = appartmentData;
  }

  /**
   * sets the selected building
   * @param {Object} buildingData the selected building object
   */
  setSelectedBuilding(buildingData) {
    this.selectedBuilding = buildingData;
  }

  /**
   * sets the selected notification
   * @param {Object} notificationData the selected notification object
   */
  setSelectedNotification(notificationData) {
    this.selectedNotification = notificationData;
  }

  /**
   * sets the selected notification status
   * @param {Object} notificationData the selected notification object
   */
  setSelectedNotificationStatus(notificationStatus) {
    this.selectedNotificationStatus = notificationStatus;
  }

  /**
   * sets the selected appartment
   * @param {Object} notificationData the selected notification object
   */
  setSelectedSentNotification(notificationData) {
    this.selectedSentNotification = notificationData;
  }

  /**
   * sets the logged user
   * @param {Object} userData the logged user object
   */
  setLoggedUser(userData) {
    if (!this.loggedUser) {
      this.loggedUser = userData;
    }
  }

  /**
   * sets the logged consents
   * @param {Object} consents the logged user object
   */
  setLoggedConsentsData(consents) {
    if (consents) {
      this.loggedUser.consents = consents;
    }
  }

  /**
   * update logged user data
   * @param {Object} updatedData object containing updated data
   */
  updateLoggedUser(updatedData) {
    this.loggedUser = { ...this.loggedUser, ...updatedData };
  }

  /**
   * sets the logged user language
   * @param {Object} userData the logged user object
   */
  setLoggedUserLang(language) {
    if (language) {
      this.loggedUser.language = language;
    }
  }

  /**
   * sets if logged user has a google token
   * @param {Boolean} hasGoogleToken
   */
  setLoggedUserHasGoogleToken(hasGoogleToken) {
    this.loggedUser.hasGoogleToken = hasGoogleToken;
  }

  /**
   * sets the logged token
   * @param {Object} tokenData the logged token object
   */
  setLoggedToken(tokenData) {
    if (!this.loggedToken) {
      this.loggedToken = tokenData;
    }
  }

  /**
   * sets the loging in vendor email
   * @param {string} val the email
   */
  updateVendorEmail(val) {
    if (this.vendorEmail !== val) {
      this.vendorEmail = val;
    }
  }

  /**
   * sets the loging in vendor password
   * @param {string} val the password
   */
  updateVendorPassword(val) {
    if (this.vendorPassword !== val) {
      this.vendorPassword = val;
    }
  }

  /**
   * sets the current project name
   * @param {string} pName project name
   */
  updateProjectName(pName) {
    if (this.projectName !== pName) {
      this.projectName = pName;
    }
  }

  /**
   * sets the current project id
   * @param {string} pId project id
   */
  updateProjectId(pId) {
    if (this.projectId !== pId) {
      this.projectId = pId;
    }
  }

  /**
   * sets the selected client id
   * @param {number} id the selected client id
   */
  setClientId(id) {
    this.clientId = id;
  }

  /**
   * sets the selected appartment id
   * @param {number} id the selected appartment id
   * @DEPRECATED use query params from react-router instead
   */
  setApartmentId(id) {
    this.apartmentId = id;
  }

  /**
   * sets the remove pop up
   */
  setRemoveEvent(value) {
    this.removeEvent = value;
  }
  /**
   * sets the remove pop up
   */
  setUpdateEvent(value) {
    this.updateEvent = value;
  }
  /**
   * sets the remove pop up
   */
  setCancelEvent(value) {
    this.cancelEvent = value;
  }

  /**
   * resets all persisted data
   */
  logOut() {
    this.feKey = '';
    this.feVendorKey = '';
    this.loginProjectName = '';
    this.projectName = null;
    this.searchAppartamenti = '';
    this.searchAppartamentiHome = '';
    this.searchClientiHome = '';
    this.searchClienti = '';
    this.hostKey = '';
    this.projectId = null;
    this.enabledStatus = null;
    this.alertDays = 5;
    this.isPackEditable = false;
    this.isQuoteEditable = false;
    this.logo = [];
    this.eventId = null;
    this.assetLogin = [];
    this.assetsUrl = null;
    this.enabledSections = [];
    this.dashboardConfig = undefined;
    this.currentNavTab = 0;
    this.apartmentId = 0;
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.clientId = 0;
    this.realLocation = undefined;
    this.vendorEmail = '';
    this.vendorPassword = '';
    this.modifyingAppartment = {
      id: '',
      name: '',
      typology: '',
      status: '',
      mood: '',
      sides: [],
      packsList: [],
      movements: [],
    };
    this.removeEvent = false;
    this.updateEvent = false;
    this.cancelEvent = false;
    this.eventList = [];
    this.communityEventList = [];
    this.activities = [];
    this.event = undefined;
    this.currentEvent = {};
    this.updEvent = false;
    this.calendarPopup = false;
    this.menuMobile = false;
    this.avatarMenu = false;
    this.alertMenu = false;
    this.sendNotificationMenu = false;
    this.notificationMenu = false;
    this.errorModal = false;
    this.errorMessage = null;
    this.appartmentModal = false;
    this.editApartmentAvailabilityModal = false;
    this.modifyingAppartmentOriginalState = null;
    this.removeAppartmentModal = false;
    this.removeClientModal = false;
    this.removeAppartment = null;
    this.statusProposal = null;
    this.confirmProposalModal = false;
    this.signProposalModal = false;
    this.validateProposalModal = false;
    this.refuseProposalModal = false;
    this.refuseProposal = false;
    this.resendProposalModal = false;
    this.pendingProposalModal = false;
    this.addInfoConfirmModal = false;
    this.tabConfirmModal = false;
    this.errorImportApartment = null;
    this.errorImportApartmentModal = false;
    this.expiredQuote = null;
    this.proposalQuoteName = null;
    this.renewTimeQuote = null;
    this.generatePdfQuoteModal = false;

    this.renewTimeQuoteModal = false;
    this.deleteQuote = null;
    this.regenerateProposalQuote = null;
    this.sendProposalQuote = null;
    this.generatePdfQuoteId = null;

    this.deleteQuoteModal = false;
    this.regenerateProposalModal = false;
    this.sendProposalModal = false;
    this.changeNotificationStatusModal = false;
    this.externalPdf = null;
    this.clientToAdd = null;
    this.selectedClient = null;
    this.clientToSave = null;
    this.appartmentToSave = null;
    this.selectedAppartment = null;
    this.selectedBuilding = null;
    this.selectedNotification = null;
    this.selectedNotificationStatus = null;
    this.selectedSentNotification = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.longLoading = false;
    this.rowLoading = false;
    this.globalEditing = false;
    this.infoEditing = false;
    this.pdfToView = undefined;
    this.project_id = undefined;
    this.vendorLogo = [];
    this.locale = null;
    this.currency = null;
    this.pageTitles = undefined;
    this.clients = undefined;
    this.appartments = undefined;
    this.users = undefined;
    this.userCalled = undefined;
    this.clientsFromFilter = null;
    this.appointmentConfig = null;
    this.clientsToFilter = null;
    this.appartamentoSort = [null, null, null, null, null];
    this.appartamentoHomeSort = [null, null, null, null, null];
    this.clienteSort = [null, null, null, null, null];
    this.clienteHomeSort = [null, null, null, null, null];
    this.myhomeConfig = undefined;
    this.featuresFlags = undefined;
    this.setItemModal = false;
    this.policyConfig = [];
    this.tabValueApartment = 0;
    this.checkingJwt = false;
    this.area = undefined;
    this.openNewsletterModal = false;
    this.defaultLang = '';
    if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.clearUser();
    }
  }

  /**
   *
   */
  setRowLoading(val) {
    if (this.rowLoading !== val) {
      this.rowLoading = val;
    }
  }

  /**
   *
   */
  setLongLoading(val) {
    if (this.longLoading !== val) {
      this.longLoading = val;
    }
  }

  /**
   *
   * @param {search terms} value
   */
  setSearchAppartamenti(value) {
    this.searchAppartamenti = value;
  }
  setSearchClienti(value) {
    this.searchClienti = value;
  }
  setSearchAppartamentiHome(value) {
    this.searchAppartamentiHome = value;
  }
  setSearchClientiHome(value) {
    this.searchClientiHome = value;
  }
  /**
   *
   */
  setEventId(id) {
    this.eventId = id;
  }
  /**
   *
   * @param {sort order} list
   */
  setAppartamentoSort(list) {
    this.appartamentoSort = list;
  }
  setAppartamentoHomeSort(list) {
    this.appartamentoHomeSort = list;
  }
  setClienteSort(list) {
    this.clienteSort = list;
  }
  setClienteHomeSort(list) {
    this.clienteHomeSort = list;
  }

  setCalendarPopup(val) {
    if (this.calendarPopup !== val) {
      this.calendarPopup = val;
    }
  }
  /**
   * sets global editing on a form
   * @param {bool} val
   */
  setGlobalEditing(val) {
    this.globalEditing = val;
  }

  /**
   * sets global editing on a form
   * @param {bool} val
   */
  setInfoEditing(val) {
    this.infoEditing = val;
  }

  /**
   * @param {object} obj an object containing all assets
   */
  setAssetsByObject(obj) {
    if (obj) {
      this.feKey = obj.assetKey;
      this.assetLogin = obj.assetLogin;
      this.logo = obj.logo;
      this.externalPdf = obj.pdf;
      this.projectName = obj.name;
      this.projectId = obj.id;
      this.loginProjectName = obj.displayName;
      this.hostKey = obj.hostKey;
      this.assetsUrl = obj.assetsUrl;
      this.feVendorKey = obj.feVendorKey;
      this.project_id = obj.id;
      this.vendorLogo = obj.vendorLogo;
      this.pageTitles = obj.pageTitles;
      this.enabledSections = obj?.followupRentConfig?.enabledSections ?? [];
      this.dashboardConfig = obj.followupRentConfig
        ? obj.followupRentConfig.dashboardConfig
        : undefined;
      this.enabledTools = obj.enabledTools;
      this.isMyLivingActive = obj.enabledTools.filter(
        (tool) => tool.name === 'MyLiving',
      )[0]?.enabled;
      this.locale = obj.locale;
      this.currency = obj.currency;
      this.enabledStatus = obj?.followupRentConfig?.enabledStatus ?? null;
      this.alertDays = obj?.followupRentConfig?.alertDays ?? null;
      this.isPackEditable = obj?.followupRentConfig?.isPackEditable ?? false;
      this.isQuoteEditable = obj?.followupRentConfig?.isQuoteEditable ?? false;
      this.myhomeConfig = obj.myhomeConfig;
      this.featuresFlags = obj.followupRentConfig?.featuresFlags;
      // Cerco le policy in italiano
      // TODO: da valutare se esiste il caso in cui non saranno presenti le policy in italiano
      // oppure se quando viene cambiata lingua vogliamo cambiare anche le policy
      this.policyConfig = obj.policyFlags.ita ?? [];
      this.area = obj.area;
      this.defaultLang = obj.defaultLang;
    }
  }

  /**
   * set clients
   * @param {array} data an array of clients
   */
  setClients(data) {
    this.clients = data;
  }

  setAppointmentConfig(data) {
    this.appointmentConfig = data;
  }

  /**
   * set appartments
   * @param {array} data an array of appartments
   */
  setAppartments(data) {
    this.appartments = data;
  }
  /**
   * set users
   * @param {array} data an array of users
   */
  setUsers(data) {
    this.users = data;
  }

  /**
   * set userCalled
   * @param data user called to modify
   */
  setUserCalled(data) {
    this.userCalled = data;
  }

  /**
   * set calendar event
   * @param {array} array of events
   *  */
  setEvent(data) {
    this.event = data;
  }

  setEventList(data) {
    this.eventList = data;
  }

  setCommunityEventList(data) {
    this.communityEventList = data;
  }

  setActivities(data) {
    this.activities = data;
  }

  /**
   * set calendar creation event
   * @param {dictionary} single event
   *  */
  setCurrentEvent(data) {
    this.currentEvent['vendorId'] = this.loggedUser.id;
    this.currentEvent['projectId'] = this.feKey;
    this.currentEvent = data;
  }
  /**
   * set calendar creation event
   * @param {dictionary} single event
   *  */
  setUpdEvent(data) {
    this.updEvent = data;
  }

  /**
   * open/close lateral navbar
   * @param {boolean} val
   */
  setOpenDrawer(val) {
    this.openDrawer = val;
  }

  /**
   * open/close calendar settings modal
   * @param {boolean} val
   */
  setOpenCalendarSettings(val) {
    this.calendarSettingsModal = val;
  }

  setErrorCalendarSettings(val) {
    this.errorCalendarSettings = val;
  }
  setUpdateAdditionalInfo(val) {
    this.updateAdditionalInfo = val;
  }

  /**
   * open/close stepper modal
   * @param {boolean} val
   */
  setStepperModal(val) {
    this.stepperModal = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setSetItemModal(val) {
    this.setItemModal = val;
  }

  /**
   * tab open in apartment
   * @param {number} val
   */

  setTabValueApartment(val) {
    this.tabValueApartment = val;
  }

  /**
   * set pertinenze
   * @param {array} data an array of appartments
   */
  setPertinenze(data) {
    this.pertinenze = data;
  }

  /**
   * set selectedExtraSpace
   * @param {object} data an object of pertinenza
   */
  setSelectedExtraSpace(data) {
    this.selectedExtraSpace = data;
  }

  /**
   * set extraSpaceId
   * @param {string} data an id of pertinenza
   */
  setExtraSpaceId(data) {
    this.extraSpaceId = data;
  }

  /**
   * set cro
   * @param {string} data an id of cro
   */
  setSelectedCro(data) {
    this.selectedCro = data;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setAggiungiAccountModal(val) {
    this.openAccountModal = val;
  }

  /**
   * open/close setVAT modal
   * @param {boolean} val
   */
  setOpenModifyVATDrawer(val) {
    this.openModifyVATDrawer = val;
  }

  setOpenChangeProposal(val) {
    this.openChangeProposal = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setReservationTimeConfigModal(val) {
    this.openReserveTimeModal = val;
  }

  /**
   * open/close active my home modal
   * @param {boolean} val
   */
  setActiveMyHomeModal(val) {
    this.activeMyHomeModal = val;
  }

  /**
   * open/close active my living modal
   * @param {boolean} val
   */
  setActiveMyLivingModal(val) {
    this.activeMyLivingModal = val;
  }

  /**
   * open/close accept countersign modal
   * @param {boolean} val
   */
  setAcceptCountersignModal(val) {
    this.acceptCountersignModal = val;
  }

  /**
   * open/close reject countersign modal
   * @param {boolean} val
   */
  setRejectCountersignModal(val) {
    this.rejectCountersignModal = val;
  }

  /**
   * open/close delete my home modal
   * @param {boolean} val
   */
  setDeleteMyHomeModal(val) {
    this.deleteMyHomeModal = val;
  }

  /**
   * open/close delete my living modal
   * @param {boolean} val
   */
  setDeleteMyLivingModal(val) {
    this.deleteMyLivingModal = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setDeleteAccountModal(val) {
    this.deleteAccountModal = val;
  }

  /**
   * open/close error modal
   * @param {boolean} val
   */
  setErrorModal(val) {
    this.errorModal = val;
  }

  /**
   * open/close newsletter modal
   * @param {boolean} val
   */
  setOpenNewsletterModal(val) {
    this.openNewsletterModal = val;
  }

  setErrorMessage(val) {
    this.errorMessage = val;
  }

  /**
   * updates client to save
   * @param {object} data updated client
   */
  updateClientToSave(data) {
    this.clientToSave = data;
  }

  setIsOpenAlert(isOpen) {
    this.alertIsOpen = isOpen;
  }

  setAlertInvalidIsOpen(isOpen) {
    this.alertInvalidIsOpen = isOpen;
  }

  /**
   * updates quote alert data
   * @param {object} data updated alert data
   */
  updateQuoteAlert(data) {
    this.quoteAlert = data;
  }

  /**
   * updates client to add
   * @param {object} data client to add
   */
  updateClientToAdd(data) {
    this.clientToAdd = data;
  }

  /**
   * updates appartment to save
   * @param {object} data updated client
   */
  updateAppartmentToSave(data) {
    this.appartmentToSave = data;
  }

  /**
   * updates pertinenza to save
   * @param {object} data updated client
   */
  updateExtraSpaceToSave(data) {
    this.extraSpaceToSave = data;
  }

  /**
   * sets breadcrumbs
   * @param {string} bread parent bread crumb
   */
  setBreadCrumbs(bread) {
    this.breadCrumbs = bread;
  }

  /**
   * set remove client modal open/close
   * @param {boolean} val  clientmodal
   */
  setRemoveClientModal(val) {
    this.removeClientModal = val;
  }

  /**
   * set appartment modal open/close
   * @param {boolean} val  appartmentmodal
   */
  setAppartmentModal(val) {
    this.appartmentModal = val;
  }

  /**
   * set edit availability appartment modal open/close
   * @param {boolean} val  editApartmentAvailabilityModal
   */
  setEditApartmentAvailabilityModal(val) {
    this.editApartmentAvailabilityModal = val;
  }

  /**
   * set remove appartment modal open/close
   * @param {boolean} val  appartmentmodal
   */
  setRemoveAppartmentModal(val) {
    this.removeAppartmentModal = val;
  }

  /**
   * set confirm proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setConfirmProposalModal(val) {
    this.confirmProposalModal = val;
  }

  /**
   * set validate proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setValidateProposalModal(val) {
    this.validateProposalModal = val;
  }

  /**
   * set sign proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setSignProposalModal(val) {
    this.signProposalModal = val;
  }

  /**
   * set refuse proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setRefuseProposalModal(val) {
    this.refuseProposalModal = val;
  }
  setRefuseProposal(val) {
    this.refuseProposal = val;
  }
  setResendProposalModal(val) {
    this.resendProposalModal = val;
  }

  /**
   * set pending proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setPendingProposalModal(val) {
    this.pendingProposalModal = val;
  }

  /**
   * set client info modal open/close
   * @param {boolean} val  clientInfoModal
   */
  setAddInfoConfirmModal(val) {
    this.addInfoConfirmModal = val;
  }
  /**
   * set client info modal change tab
   * @param {boolean} val  clientInfoModal
   */
  setTabConfirmModal(val) {
    this.tabConfirmModal = val;
  }

  /**
   * set refuse proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setErrorImportApartmentModal(val) {
    this.errorImportApartmentModal = val;
  }

  setGeneratePdfQuoteModal(val) {
    this.generatePdfQuoteModal = val;
  }
  /**
   * set modal to renew expired reservation time open/close
   * @param {boolean} val
   */
  setRenewTimeQuoteModal(val) {
    this.renewTimeQuoteModal = val;
  }

  /**
   * set modal to delete quote open/close
   * @param {boolean} val
   */
  setDeleteQuoteModal(val) {
    this.deleteQuoteModal = val;
  }

  /**
   * set modal to regenerate proposal open/close
   * @param {boolean} val
   */
  setRegenerateProposalModal(val) {
    this.regenerateProposalModal = val;
  }

  /**
   * set modal to send proposal open/close
   * @param {boolean} val
   */
  setSendProposalModal(val) {
    this.sendProposalModal = val;
  }

  /**
   * set modal to delete quote open/close
   * @param {boolean} val
   */
  setChangeNotificationStatusModal(val) {
    this.changeNotificationStatusModal = val;
  }

  /**
   * sets currently modifying appartment
   * @param {object} app
   */
  setUpdateSelectedAppartment(app) {
    if (!app) {
      this.modifyingAppartment = {
        id: '',
        name: '',
        typology: '',
        status: '',
        mood: '',
        sides: [],
        plan: { quadrants: [] },
        availableQuadrants: [],
        packsList: [],
        movements: [],
      };
    } else {
      this.modifyingAppartment = app;
    }
  }

  /**
   * sets currently modifying appartment original status
   * @param {string} state
   */
  setOrignalStateUpdateSelectedAppartment(state) {
    this.modifyingAppartmentOriginalState = state;
  }

  /**
   * set appartment to remove
   * @param {object} val
   */
  setRemoveAppartment(val) {
    this.removeAppartment = val;
  }

  forceLogout(logout) {
    this.redirectionToSsoNeeded = logout;
  }

  /**
   *
   * @param {object} v1 clientToSave
   * @param {object} v2 selecteClient
   * @param {func} callback
   */
  updateGloballyClient(v1, v2, callback) {
    this.clientToSave = v1;
    this.selectedClient = v2;
    if (callback) {
      callback();
    }
  }

  /**
   * sets snackbar open/closed
   * @param {boolean} val
   * @param {string} mes message to display
   */
  setSnackbar(val, mes) {
    if (val !== this.snackbarOpen) {
      this.snackbarMessage = mes;
      this.snackbarOpen = val;
    }
  }

  /**
   * sets snackbar error open/closed
   * @param {boolean} err
   * @param {string} mes message to display
   */
  setSnackbarError(err, mes) {
    if (err !== this.snackbarError) {
      this.snackbarMessage = mes;
      this.snackbarError = err;
    }
  }

  /**
   * sets pdf to view in pdf viewer
   * @param {string} val
   */
  setPdfToView(val) {
    this.pdfToView = val;
  }

  /**
   * set proposal to validate
   * @param {object} val
   */
  setStatusProposal(val) {
    this.statusProposal = val;
  }

  /**
   * set proposal to refuse
   * @param {object} val
   */
  setErrorImportApartment(val) {
    this.errorImportApartment = val;
  }

  /**
   * set expired quote
   * @param {object} val
   */
  setExpiredQuote(val) {
    this.expiredQuote = val;
  }

  /**
   * set proposal quote name
   * @param {object} val
   */
  setProposalQuoteName(val) {
    this.proposalQuoteName = val;
  }

  /**
   * set expiration reservation time
   * @param {object} val
   */
  setRenewTimeQuote(val) {
    this.renewTimeQuote = val;
  }

  /**
   * set delete quote
   * @param {object} val
   */
  setDeleteQuote(val) {
    this.deleteQuote = val;
  }

  /**
   * set regenerate quote proposal
   * @param {object} val
   */
  setRegenerateProposalQuote(val) {
    this.regenerateProposalQuote = val;
  }

  /**
   * set send quote proposal
   * @param {object} val
   */
  setSendProposalQuote(val) {
    this.sendProposalQuote = val;
  }

  setGeneratePdfQuoteId(val) {
    this.generatePdfQuoteId = val;
  }

  /**
   * set the language of the system
   * @param {string} val
   */
  setSystemLanguage(val) {
    this.systemLanguage = val;
  }

  setCheckingJwt(val) {
    this.checkingJwt = val;
  }
  setLoggedUserJwtData(obj) {
    const { userData, checkingJwt } = obj;
    this.setLoggedUser(userData);
    this.setCheckingJwt(checkingJwt);
    if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.setUser({
        id: userData.id,
        name: userData.firstName,
        surname: userData.lastName,
        email: userData.email,
        role: userData.role,
        TwoFA: userData.TwoFA,
      });
    }
  }

  /**
   * set calendar sync revoke success
   * @param {string} status
   */
  setCalendarSyncRevokeSuccess(status) {
    this.calendarSyncRevokeSuccess = status;
  }

  /* COMPUTED */

  /**
   * computes the initials of the currently logged user
   */
  get initials() {
    if (this.loggedUser && this.loggedUser.firstName && this.loggedUser.lastName) {
      return Initials(this.loggedUser.firstName, this.loggedUser.lastName);
    } else {
      return '-';
    }
  }

  /**
   * computes the logged user full name
   */
  get loggedUserFullname() {
    if (this.loggedUser && this.loggedUser.firstName && this.loggedUser.lastName) {
      return `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
    } else if (this.loggedUser && this.loggedUser.id) {
      return this.loggedUser.id;
    } else {
      return '-';
    }
  }

  getLoggedUserRole() {
    return this.loggedUser.role;
  }

  /**
   * computes the selected client initials
   */
  get clientInitials() {
    if (this.selectedClient && this.selectedClient.firstName && this.selectedClient.lastName) {
      return Initials(this.selectedClient.firstName, this.selectedClient.lastName);
    } else {
      return '-';
    }
  }

  /**
   * computes the selected client full name
   */
  get selectedClientFullname() {
    if (this.selectedClient && this.selectedClient.firstName && this.selectedClient.lastName) {
      return `${this.selectedClient.firstName} ${this.selectedClient.lastName}`;
    } else if (this.selectedClient && this.selectedClient.id) {
      return this.selectedClient.id;
    } else {
      return '-';
    }
  }

  /**
   * computes the selected client status
   */
  get selectedClientStatus() {
    return this.selectedClient && this.selectedClient.status ? this.selectedClient.status : null;
  }

  /**
   * extra validation for the client
   */
  get clientToSaveIsValid() {
    return (
      this.clientToSave &&
      ValidateEmail(this.clientToSave.email) &&
      ValidatePhone(this.clientToSave.tel)
    );
  }

  /**
   * extra validation for the added client
   */
  get clientToAddIsValid() {
    return (
      this.clientToAdd &&
      !!this.clientToAdd.firstName &&
      !!this.clientToAdd.lastName &&
      /*this.clientToAdd.trattamento &&*/ ValidateEmail(this.clientToAdd.email) &&
      ValidatePhone(this.clientToAdd.tel)
    );
  }

  get retrieveClientsList() {
    if (this.clients !== undefined) {
      // gestione date
      let clientsCopy = [...this.clients];

      clientsCopy = clientsCopy.filter((client) => {
        const createdOnDate = new Date(client.createdOn).setHours(0, 0, 0, 0);
        let greaterThan = true;
        let lessThan = true;
        if (this.clientsFromFilter) {
          greaterThan = moment(this.clientsFromFilter).diff(moment(createdOnDate)) <= 0;
        }
        if (this.clientsToFilter) {
          lessThan = moment(this.clientsToFilter).diff(moment(createdOnDate)) >= 0;
        }
        return greaterThan && lessThan;
      });
      return clientsCopy;
    } else return [];
  }
}

decorate(Store, {
  feVendorKey: [persist, observable],
  feKey: [persist, observable],
  breadCrumbs: [persist, observable],
  projectName: [persist, observable],
  loginProjectName: [persist, observable],
  projectId: [persist, observable],
  assetsUrl: [persist, observable],
  currentNavTab: [persist, observable],
  hostKey: [persist, observable],
  enabledStatus: [persist('object'), observable],
  alertDays: [persist('object'), observable],
  isPackEditable: [persist('object'), observable],
  isQuoteEditable: [persist('object'), observable],
  enabledSections: [persist('list'), observable],
  enabledTools: [persist('object'), observable],
  dashboardConfig: [persist('object'), observable],
  loggedUser: [persist('object'), observable],
  loggedToken: [persist('object'), observable],
  selectedClient: [observable],
  selectedAppartment: [persist('object'), observable],
  selectedBuilding: [persist('object'), observable],
  selectedNotification: [persist('object'), observable],
  selectedNotificationStatus: [persist('object'), observable],
  selectedSentNotification: [persist('object'), observable],
  apartmentId: [persist, observable],
  clientId: [persist, observable],
  logo: [persist('list'), observable],
  externalPdf: [persist, observable],
  modifyingAppartment: [persist('object'), observable],
  removeAppartment: [persist('object'), observable],
  statusProposal: [persist('object'), observable],
  errorImportApartment: [persist('object'), observable],
  expiredQuote: [persist('object'), observable],
  proposalQuoteName: [persist('object'), observable],
  renewTimeQuote: observable,
  deleteQuote: [persist('object'), observable],
  regenerateProposalQuote: [persist('object'), observable],
  sendProposalQuote: [persist('object'), observable],

  modifyingAppartmentOriginalState: [persist, observable],
  pdfToView: [persist, observable],
  project_id: [persist, observable],
  vendorLogo: [persist('list'), observable],
  currency: [persist, observable],
  locale: [persist, observable],
  assetLogin: [persist('list'), observable],
  pageTitles: [persist('object'), observable],
  appartamentoSort: [persist('list'), observable],
  appartamentoHomeSort: [persist('list'), observable],
  clienteSort: [persist('list'), observable],
  clienteHomeSort: [persist('list'), observable],
  eventId: [persist, observable],
  policyConfig: [persist('object'), observable],
  calendarSyncRevokeSuccess: [persist, observable],
  area: [persist, observable],
  defaultLang: [persist, observable],

  openDrawer: observable,
  setItemModal: observable,
  deleteAccountModal: observable,
  stepperModal: observable,
  openAccountModal: observable,
  openModifyVATDrawer: observable,
  openChangeProposal: observable,
  openReserveTimeModal: observable,
  activeMyHomeModal: observable,
  activeMyLivingModal: observable,
  acceptCountersignModal: observable,
  rejectCountersignModal: observable,
  deleteMyHomeModal: observable,
  deleteMyLivingModal: observable,
  calendarSettingsModal: observable,
  errorCalendarSettings: observable,
  updateAdditionalInfo: observable,
  clients: observable.shallow,
  appartments: observable,
  users: observable,
  userCalled: observable,
  clientsFromFilter: observable,
  clientsToFilter: observable,
  snackbarOpen: observable,
  snackbarMessage: observable,
  snackbarError: observable,
  longLoading: observable,
  rowLoading: observable,
  clientToAdd: observable,
  removeClientModal: observable,
  appartmentModal: observable,
  editApartmentAvailabilityModal: observable,
  removeAppartmentModal: observable,
  confirmProposalModal: observable,
  signProposalModal: observable,
  validateProposalModal: observable,
  refuseProposalModal: observable,
  refuseProposal: observable,
  resendProposalModal: observable,
  pendingProposalModal: observable,
  addInfoConfirmModal: observable,
  tabConfirmModal: observable,
  errorImportApartmentModal: observable,
  generatePdfQuoteModal: observable,
  generatePdfQuoteId: observable,

  renewTimeQuoteModal: observable,
  deleteQuoteModal: observable,
  regenerateProposalModal: observable,
  sendProposalModal: observable,
  changeNotificationStatusModal: observable,
  vendorEmail: observable,
  vendorPassword: observable,
  clientToSave: observable,
  appartmentToSave: observable,
  avatarMenu: observable,
  alertMenu: observable,
  sendNotificationMenu: observable,
  notificationMenu: observable,
  menuMobile: observable,
  errorModal: observable,
  errorMessage: observable,
  realLocation: observable,
  globalEditing: observable,
  infoEditing: observable,
  calendarPopup: observable,
  eventList: observable,
  communityEventList: observable,
  activities: observable,
  event: observable,
  currentEvent: observable,
  updEvent: observable,
  removeEvent: observable,
  updateEvent: observable,
  cancelEvent: observable,
  searchAppartamenti: observable,
  searchAppartamentiHome: observable,
  searchClientiHome: observable,
  systemLanguage: [persist, observable],
  appointmentConfig: observable,
  myhomeConfig: observable,
  featuresFlags: observable,
  tabValueApartment: observable,
  pertinenze: observable,
  selectedExtraSpace: observable,
  extraSpaceId: observable,
  selectedCro: observable,
  checkingJwt: observable,
  alertIsOpen: observable,
  alertInvalidIsOpen: observable,
  redirectionToSsoNeeded: observable,
  isMyLivingActive: observable,
  openNewsletterModal: observable,

  setPdfToView: action.bound,
  setGlobalEditing: action.bound,
  setInfoEditing: action.bound,
  setLongLoading: action.bound,
  setRowLoading: action.bound,
  setOrignalStateUpdateSelectedAppartment: action.bound,
  setRemoveAppartment: action.bound,
  setUpdateSelectedAppartment: action.bound,
  updateCurrentNavTab: action.bound,
  setRealLocation: action.bound,
  toggleMenuMobile: action.bound,
  closeMenuMobile: action.bound,
  setSelectedClient: action.bound,
  setAvatarMenu: action.bound,
  setAlertMenu: action.bound,
  setSendNotificationMenu: action.bound,
  setNotificationMenu: action.bound,
  setLoggedUser: action.bound,
  setLoggedUserLang: action.bound,
  setLoggedConsentsData: action.bound,
  setLoggedUserHasGoogleToken: action.bound,
  setLoggedToken: action.bound,
  updateVendorEmail: action.bound,
  updateVendorPassword: action.bound,
  updateProjectName: action.bound,
  updateProjectId: action.bound,
  setApartmentId: action.bound,
  setSelectedAppartment: action.bound,
  setSelectedBuilding: action.bound,
  setSelectedNotification: action.bound,
  setSelectedNotificationStatus: action.bound,
  setSelectedSentNotification: action.bound,
  setClientId: action.bound,
  logOut: action.bound,
  setAssetsByObject: action.bound,
  updateClientToSave: action.bound,
  updateClientToAdd: action.bound,
  updateAppartmentToSave: action.bound,
  updateExtraSpaceToSave: action.bound,
  setErrorModal: action.bound,
  setOpenNewsletterModal: action.bound,
  setErrorMessage: action.bound,
  setBreadCrumbs: action.bound,
  setRemoveClientModal: action.bound,
  setAppartmentModal: action.bound,
  setEditApartmentAvailabilityModal: action.bound,
  setRemoveAppartmentModal: action.bound,
  setConfirmProposalModal: action.bound,
  setRefuseProposalModal: action.bound,
  setRefuseProposal: action.bound,
  setResendProposalModal: action.bound,
  setAddInfoConfirmModal: action.bound,
  setTabConfirmModal: action.bound,
  setGeneratePdfQuoteModal: action.bound,
  setRenewTimeQuoteModal: action.bound,
  setDeleteQuoteModal: action.bound,
  setRegenerateProposalModal: action.bound,
  setSendProposalModal: action.bound,
  setChangeNotificationStatusModal: action.bound,
  updateGloballyClient: action.bound,
  setCurrentEvent: action.bound,
  setSystemLanguage: action.bound,
  setOpenDrawer: action.bound,
  setSetItemModal: action.bound,
  setAggiungiAccountModal: action.bound,
  setOpenModifyVATDrawer: action.bound,
  setOpenChangeProposal: action.bound,
  setActiveMyHomeModal: action.bound,
  setActiveMyLivingModal: action.bound,
  setAcceptCountersignModal: action.bound,
  setRejectCountersignModal: action.bound,
  setDeleteMyHomeModal: action.bound,
  setDeleteMyLivingModal: action.bound,
  setDeleteAccountModal: action.bound,
  setStepperModal: action.bound,
  setOpenCalendarSettings: action.bound,
  setErrorCalendarSettings: action.bound,
  setUpdateAdditionalInfo: action.bound,
  setTabValueApartment: action.bound,
  setSelectedExtraSpace: action.bound,
  setExtraSpaceId: action.bound,
  setSelectedCro: action.bound,
  setCheckingJwt: action.bound,
  setIsOpenAlert: action.bound,
  setAlertInvalidIsOpen: action.bound,
  setLoggedUserJwtData: action.bound,
  forceLogout: action.bound,
  setCalendarSyncRevokeSuccess: action.bound,
  updateLoggedUser: action.bound,

  initials: computed,
  clientInitials: computed,
  loggedUserFullname: computed,
  selectedClientFullname: computed,
  selectedClientStatus: computed,
  clientToSaveIsValid: computed,
  clientToAddIsValid: computed,
});

export default Store;
