import React from 'react';
import ChartConstants from 'constants/ChartConstants';
import { PieChart, Pie, ResponsiveContainer, Cell, Legend, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Capitalize from 'utils/Capitalize';
import 'styles/charts.scss';
import { useIntl } from 'react-intl';
import { useStore } from 'store/storeUtils';

/**
 * DoughnutChart
 * based on recharts
 *
 * @component
 * @see {@link http://recharts.org/en-US/api/PieChart PieChart API}
 */
const DoughnutChart = (props) => {
  const { availableStatuses, availableColors, data, height, width, innerRadius, outerRadius } =
    props;
  const intl = useIntl();
  const store = useStore();

  let pieData = [];
  let total = 0;
  if (data) {
    data.forEach((el) => {
      if (el) {
        total += el[0].data;
        pieData.push(el[0]);
      }
    });
  }

  const percFormat = (value) => {
    return total && total > 0 ? Math.round((value * 1000.0) / total) / 10 + '%' : 'NaN';
  };

  const formatter = (value, name) => {
    return [percFormat(value), Capitalize(availableStatuses[name])];
  };

  const CustomTooltip = (dataTooltip) => {
    if (dataTooltip.active) {
      let formatArray = formatter(dataTooltip.payload[0].payload.data, dataTooltip.payload[0].name);
      let label = dataTooltip.payload[0].payload.label;
      return (
        <div className="recharts-tooltip-wrapper">
          <div className="recharts-default-tooltip custom-tooltip">
            <p className="recharts-tooltip-label">{`${intl.formatMessage({
              id: `label.${label}`,
              defaultMessage: label,
            })} : ${dataTooltip.payload[0].payload.data} - ${formatArray[0]}`}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={height} className="doughnut-chart-container">
      <PieChart width={width} height={height}>
        <Legend
          align="left"
          content={(p) => {
            return (
              <>
                <Grid container>
                  {p.payload.map((pay, i) => (
                    <Grid key={'i' + i} item xs={12} sm={6}>
                      <div className="legend-content">
                        <div className="flex legend-right-side">
                          <div
                            className="donut-legend-icon"
                            style={{ backgroundColor: pay.color }}
                          ></div>
                          <div className="donut-legend-text small-font-14">
                            {Capitalize(
                              intl.formatMessage({
                                id: `label.${pay.payload.label}`,
                                defaultMessage: pay.payload.label,
                              }),
                            )}
                          </div>
                        </div>
                        <div className="small-font-14 donut-legend-stats">
                          <div style={{ width: '60px' }} className="flex-container-end">
                            {'(' + formatter(pay.payload.data, pay.payload.label)[0] + ')'}
                          </div>
                          <div className="donut-legend-money-stats">{pay.payload.data}</div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </>
            );
          }}
        />
        <Tooltip
          content={<CustomTooltip />}
          allowEscapeViewBox={{ x: true, y: true }}
          wrapperStyle={{ top: -30, left: 5 }}
        />
        <Pie
          cx={'50%'}
          cy={height / 3}
          data={pieData}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          dataKey="data"
        >
          {data.map((entry, index) => {
            const color = availableColors[availableStatuses.indexOf(entry[0]?.label)];
            return <Cell dataKey="data" key={`cell-${index}`} fill={color} />;
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

DoughnutChart.propTypes = {
  /**
   * availableStatuses: all statuses to be displayed on chart and legend
   */
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  /**
   * availableColors: colors to use for each status
   */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /**
   * data
   */
  data: PropTypes.arrayOf(PropTypes.array),
  /**
   * width: of the chart
   */
  width: PropTypes.number,
  /**
   * height: of all the elements
   */
  height: PropTypes.number,
  /**
   * innerRadius: 0 is a pie chart. The higher it is, the bigger the "hole" at the center of the chart will be
   */
  innerRadius: PropTypes.number,
  /**
   * outerRadius: how "big" the chart is
   */
  outerRadius: PropTypes.number,
};

DoughnutChart.defaultProps = {
  availableStatuses: ChartConstants.availableStatus,
  availableColors: ChartConstants.defaultColors,
  data: [],
  width: 500,
  height: 200,
  innerRadius: 40,
  outerRadius: 80,
};

export default DoughnutChart;
