import React from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Headset from 'images/assets/headset-support.svg';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import { generateNewHostname } from 'utils/util';
import 'styles/support.scss';

const CrmSupport = observer(() => {
  const intl = useIntl();
  const store = useStore();

  const handleOnClick = () => {
    const nowTimestamp = moment().valueOf();
    const newHostName = generateNewHostname(`openSupport=${nowTimestamp}`);
    window.open(`https://${newHostName}`, '_blank');
  };

  if (store?.area === 'rent') {
    return (
      <div className="support-wrapper">
        <Button className="link-button" onClick={handleOnClick} disableRipple={true}>
          <CrmSvgLoader data={Headset} alt="Headset support" className="support-logo" />
          <div className="link-button-text">
            {intl.formatMessage({
              id: 'header.support',
              defaultMessage: 'Support',
            })}
          </div>
        </Button>
      </div>
    );
  }
  return null;
});

export default CrmSupport;
