import { gql } from '@apollo/client';

export const GET_PRODUCT_CATEGORY_DATA = ` 
  id
  name
  projectId
  iva
  default
  duplicated
`;

export const GET_COST_ITEMS_PRODUCT_CATEGORY_DATA = `
  name
  key
  label
  frequency
  default 
  projectId
  productCategory {
    ${GET_PRODUCT_CATEGORY_DATA}
  }
`;
export const PRODUCT_CATEGORY_MUTATION_RESPONSE = `
  done
  errors
`;

export const GET_COST_ITEMS_PRODUCT_CATEGORY = gql`
  query getCostItemsProductCategory($project_id: ID!) {
    getCostItemsProductCategory(project_id: $project_id) {
      ${GET_COST_ITEMS_PRODUCT_CATEGORY_DATA}
    }
  }
`;

export const GET_COST_ITEMS_PRODUCT_CATEGORY_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

export const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories($project_id: ID!) {
    getProductCategories(project_id: $project_id) {
      ${GET_PRODUCT_CATEGORY_DATA}
    }
  }
`;

export const GET_PRODUCT_CATEGORIES_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

export const GET_COST_ITEMS_BY_PROJECT_DATA = `
  key
  name
  label
`;

export const GET_COST_ITEMS_BY_PROJECT = gql`
  query getCostItemsByProject($project_id: ID!) {
    getCostItemsByProject(project_id: $project_id) {
      ${GET_COST_ITEMS_BY_PROJECT_DATA}
    }
  }
`;

export const GET_COST_ITEMS_BY_PROJECT_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

export const CREATE_PRODUCT_CATEGORIES = gql`
  mutation createProductCategory(
    $project_id: ID!
    $costItemKey: ID!
    $category: ProductCategoryInput!
  ) {
    createProductCategory(project_id: $project_id, costItemKey: $costItemKey, category: $category) {
      done
      errors
    }
  }
`;

export const CREATE_PRODUCT_CATEGORIES_OPTIONS = (project_id, costItemKey, category) => {
  const options = {
    variables: {
      project_id,
      costItemKey,
      category,
    },
  };

  return options;
};

export const UPDATE_PRODUCT_CATEGORIES = gql`
  mutation updateProductCategory(
    $project_id: ID!
    $costItemKey: ID!
    $category: ProductCategoryInput!
  ) {
    updateProductCategory(project_id: $project_id, costItemKey: $costItemKey, category: $category) {
      done
      errors
    }
  }
`;

export const UPDATE_PRODUCT_CATEGORIES_OPTIONS = (project_id, costItemKey, category) => {
  const options = {
    variables: {
      project_id,
      costItemKey,
      category,
    },
  };

  return options;
};

export const DELETE_PRODUCT_CATEGORY_BY_ID = gql`
  mutation deleteProductCategoryById($project_id: ID!, $productCategoryId: ID!) {
    deleteProductCategoryById(project_id: $project_id, productCategoryId: $productCategoryId) {
      done
      errors
    }
  }
`;

export const DELETE_PRODUCT_CATEGORY_BY_ID_OPTIONS = (project_id, productCategoryId) => {
  const options = {
    variables: {
      project_id,
      productCategoryId,
    },
  };

  return options;
};

export const LINK_PRODUCT_CATEGORY = gql`
  mutation linkProductCategory(
    $project_id: ID!
    $costItemKey: ID!
    $category: ProductCategoryInput!
  ) {
    linkProductCategory(project_id: $project_id, costItemKey: $costItemKey, category: $category) {
      ${PRODUCT_CATEGORY_MUTATION_RESPONSE}
    }
  }
`;

export const LINK_PRODUCT_CATEGORY_OPTIONS = (project_id, costItemKey, category) => {
  const options = {
    variables: {
      project_id,
      costItemKey,
      category,
    },
  };

  return options;
};
