import moment from 'moment';
import { getApartmentAvailableStatus } from 'utils/apartment';

export const defaultSort = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const sortDates = (a, b) => {
  if ((!a && b) || (a && b && moment(a).isBefore(moment(b)))) {
    return -1;
  }
  if ((!b && a) || (a && b && moment(a).isAfter(moment(b)))) {
    return 1;
  }
  return 0;
};

export const sortApartmentAvailability = (apartmentA, apartmentB) => {
  const mapAvailableStatusA = getApartmentAvailableStatus(apartmentA);
  const mapAvailableStatusB = getApartmentAvailableStatus(apartmentB);
  return defaultSort(mapAvailableStatusA, mapAvailableStatusB);
};

export const sortClient = (a, b) => {
  if (!a && b) {
    return 1;
  }
  if (a && !b) {
    return -1;
  }
  if (
    a &&
    b &&
    (a.firstName + ' ' + a.lastName).toLowerCase() < (b.firstName + ' ' + b.lastName).toLowerCase()
  ) {
    return -1;
  }
  if (
    a &&
    b &&
    (a.firstName + ' ' + a.lastName).toLowerCase() > (b.firstName + ' ' + b.lastName).toLowerCase()
  ) {
    return 1;
  }
  return 0;
};
