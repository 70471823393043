import { gql } from '@apollo/client';

export const CORE_FIELDS_MOVEMENT = `
  quote
  status
  originalStatus
  checkIn
  checkOut
`;

export const MOVEMENT = `
  id
  quote
  status
  originalStatus
  createdOn
  checkIn
  checkOut
  expiryOn
`;

export const CUSTOM_QUOTE_DATA_TO_RETRIEVE = `
boxes{
  box
  price
  meters
  floor
}
basements{
  basement
  price
  meters
  floor
}
parkingSpace_cars{
  parkingSpace_car
  price
  meters
  floor
}
parkingSpace_bykes{
  parkingSpace_byke
  price
  meters
  floor
}
features{
  id
  name
  type
  price
  floor
  notes
  code
  meters
  width
  length
  visible
  available
  status
  extraInfo
  sub
  selectedBy{
    client{
      id
    }
    status
    expiryOn
  }
}
expenses{
  name
  price
  percentage
  }
  iva{
  percentage
  name
  }
  promoPrice
  totalPrice
  fixedRate{
  percentage
  value
  }
  variableRate{
  percentage
  value
  }
  rate
  freeNotes
`;

export const APARTMENT_DATA_TO_RETRIEVE = `
      id
      name
      price
      status
      floor
      available
      enabled_web
      enabled_desk
      forRent
      extraInfo
      selectedBy {
        client {
          id
          firstName
          lastName
          avatar
        }
        status
        expiryOn
      }
      interestedBy {
        client {
          id
          firstName
          lastName
          email
        }
        status
      }
      reservedBy {
        client {
          id
          firstName
          lastName
        }
        status
        expiryOn
        quote
      }
      updatedOn
      plan {
        id
        name
        SuperficieAppartamento
        SuperficieTotale
        SuperficieGiardino
        SuperficieTerrazzo
        SuperficieBalcone
        quadrants
        typology {
          id
          name
          formulas {
            label
          }
        }
        model {
          id
          name
        }
        extraInfo
      }

      sides {     
      building {
        id
        name
      }
        id
        name
        pass
        staircase
        building {
          name
          id
        }
      }
      availableQuadrants
      packsList
      mood
      movements {
        ${MOVEMENT}
      }

`;

export const QUOTE_FRAGMENT = gql`
  fragment QuoteFragment on Quote {
    id
    status
    quoteNumber
    reserved
    pdf
    client{
      id
      firstName
      lastName
    }
    customQuote {
      ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
    }
    proposals
    appartment{
      ${APARTMENT_DATA_TO_RETRIEVE}
    }
    savedDocuments {
      name
      path
      lastModified
    }
    idDocuments {
      name
      type
      base64
    }
    vendor
    createdOn
    expiryOn
  }
`;

export const UPDATE_QUOTE_ERROR_FRAGMENT = gql`
  fragment UpdateQuoteErrorFragment on UpdateQuoteError {
    errors {
      mood {
        code
        details
      }
      packsList {
        code
        details
      }
      apt {
        code
        details
      }
      feat {
        code
        details
      }
      date {
        code
        details
      }
      typology {
        code
        details
      }
      generic {
        code
        details
      }
    }
  }
`;

export const GENERIC_RETURN_FRAGMENT = gql`
  fragment GenericReturnFragment on GenericReturn {
    done
  }
`;
