import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import defaultAvatar from 'images/assets/user.svg';
import 'styles/avatar.scss';

/**
 * Default simple avatar
 * based on material-ui Avatar
 * @component
 * @see {@link https://material-ui.com/api/avatar/ Avatar API}
 */
const CrmSimpleAvatar = (props) => {
  const { className, src, style, children } = props;

  return (
    <Avatar className={className} style={style} src={src ? src : defaultAvatar}>
      {children}
    </Avatar>
  );
};

CrmSimpleAvatar.defaultProps = {
  style: {},
  className: '',
  children: undefined,
  src: defaultAvatar,
};

/** prop types */
CrmSimpleAvatar.propTypes = {
  /** className: avatar className */
  className: PropTypes.string,
  /** style: additional style */
  style: PropTypes.object,
  /** children: fallback content */
  children: PropTypes.string,
  /** src: image to display */
  src: PropTypes.any,
};
export default CrmSimpleAvatar;
