import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { getBuilding } from './GetBuilding';
import DetectUrl from './DetectUrl';
import { QUOTE_STATUS, MOVEMENT_STATUS } from 'constants/quoteStatus';
import { STATUS } from 'constants/quote';
import {
  BUSINESS_PLATFORM_APPLICATION_NAME,
  FOLLOWUP_RENT_APPLICATION_NAME,
} from 'constants/applicationsConstants';

export const getLabel = (name) => {
  if (!name) return '';
  return name.toLowerCase().replace(/\s/g, '-');
};

export const getFormattedName = (name) => {
  if (!name) return '';
  return name.replace(/-/g, ' ');
};

export const getBucketUrl = (projectName) => {
  return `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${encodeURI(projectName)}`;
};

export const getPlanimetry = (projectName, complexName, planimetry) => {
  return `${getBucketUrl(projectName)}/planimetry/${getLabel(complexName)}/${planimetry}.png`;
};

export const getEnergyCertificateUrl = (projectName, apartment) => {
  const aptData = apartment?.extraInfo;
  return `${getBucketUrl(projectName)}/global/energy-certificate/${getLabel(
    getBuilding(apartment),
  )}/${aptData?.foglio}-${aptData?.mappale}-${aptData?.sub}.pdf`;
};

export const getRegistryUrl = (projectName, apartment) => {
  const aptData = apartment?.extraInfo;
  return `${getBucketUrl(projectName)}/global/land-registry/${getLabel(getBuilding(apartment))}/${
    aptData?.foglio
  }-${aptData?.mappale}-${aptData?.sub}.pdf`;
};

export const getRegistryFeatureUrl = (projectName, feature, apartment) => {
  return `${getBucketUrl(projectName)}/global/land-registry/${getLabel(getBuilding(apartment))}/${
    feature?.extraInfo?.foglio
  }-${feature?.extraInfo?.mappale}-${feature?.sub}.pdf`;
};

export const getReactivationAlertType = (proposalStatus) => {
  if ([STATUS.CONFERMATO, STATUS.VALIDATO].includes(proposalStatus)) {
    return 'invalidation';
  }
  if ([STATUS.IN_CORSO, STATUS.NON_CONFERMATO].includes(proposalStatus)) {
    return 'suspended';
  }
  return 'reactivation';
};

export const capitalize = (string) =>
  string?.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());

export const capitalizeAndReplace = (string, { from, to }) => capitalize(string.replace(from, to));

export const pushingToArrays = (array1, array2, app) => {
  if (!array1) array1 = [];
  array1.push(app);
  if (!array2) array2 = [];
  array2.push(app);
};

export const spliceFromArrays = (array1, array2, val) => {
  const sp1 = array1.findIndex((el) => el.appartment === val);
  if (sp1 > -1) {
    array1.splice(sp1, 1);
  }
  const sp2 = array2.findIndex((el) => el.appartment === val);
  if (sp2 > -1) {
    array2.splice(sp2, 1);
  }
};

const getFeatureName = (featureObject) => {
  return `${featureObject.label}`;
};

export const getInitialQuoteInfo = (quoteToUpdate, translationFn) => {
  const selectedQuote = {
    apartmentID: quoteToUpdate.id,
    quoteID: quoteToUpdate.quoteID,
    quoteNumber: quoteToUpdate.quoteNumber,
    isGreenField: quoteToUpdate.isGreenField,
    typology: quoteToUpdate.plan?.typology?.name,
    building: quoteToUpdate.building,
    features: quoteToUpdate?.features || {},
    plan: quoteToUpdate.plan,
    checkIn: quoteToUpdate?.checkIn,
    checkOut: quoteToUpdate?.checkOut,
    price: quoteToUpdate.price,
    proposalStatus: quoteToUpdate?.proposalStatus,
    packsList: quoteToUpdate?.packsList,
  };
  const apartmentStatus = {
    label: translationFn.formatMessage({
      id: `label.${quoteToUpdate.status || ' '}`,
      defaultMessage: quoteToUpdate.status || ' ',
    }),
    value: quoteToUpdate.status,
  };
  const apartmentName = {
    value: quoteToUpdate?.name,
    label: quoteToUpdate?.name,
  };
  const apartmentMood = {
    value: quoteToUpdate?.mood || '',
    label: translationFn.formatMessage({
      id: `label.${quoteToUpdate?.mood || 'notSetUp'}`,
      defaultMessage: capitalizeAndReplace(quoteToUpdate?.mood || 'Not Set Up', {
        from: '-',
        to: ' ',
      }),
    }),
  };
  return {
    ...selectedQuote,
    label: apartmentName,
    status: apartmentStatus,
    mood: apartmentMood,
  };
};

export const getUpdateQuoteResume = (newQuote, oldQuote) => {
  const newValues = { ...newQuote };
  const newValuesKeys = Object.keys(newValues);
  if (newValuesKeys.includes('checkIn') && newValuesKeys.includes('checkOut')) {
    newValues['checkIn-checkOut'] = {
      checkIn: newValues['checkIn'],
      checkOut: newValues['checkOut'],
    };
    delete newValues['checkIn'];
    delete newValues['checkOut'];
  }
  return Object.entries(newValues).map((value) => {
    const fieldName = (
      <FormattedMessage
        id={`editQuote.${value[0]}`}
        defaultMessage={capitalizeAndReplace(value[0], { from: '-', to: ' & ' })}
      />
    );
    const newValue = value[1];
    const oldValue = oldQuote[value[0]];
    let result;
    if (value[0] === 'packsList') {
      const newPacks = newValue.map((item) => item.name);
      const oldPacks = oldValue.map((item) => item.name);
      const diffArray = [...newPacks, ...oldPacks].reduce(
        (acc, value) => {
          if (newPacks.includes(value) && oldPacks.includes(value)) {
            return acc;
          }
          if (newPacks.includes(value) && !oldPacks.includes(value)) {
            acc['enabled'] = [...acc['enabled'], value];
            return acc;
          }
          acc['disabled'] = [...acc['disabled'], value];
          return acc;
        },
        { enabled: [], disabled: [] },
      );
      result = (
        <>
          {!!diffArray?.enabled?.length && (
            <p>
              <FormattedMessage
                id="editQuote.items-enabled"
                values={{
                  1: (text) => <span>{text}:</span>,
                  items: capitalizeAndReplace(diffArray.enabled.join(', '), { from: '-', to: ' ' }),
                }}
                defaultMessage="<1>Enabled</1> {items}"
              />
            </p>
          )}
          {!!diffArray?.disabled?.length && (
            <p>
              <FormattedMessage
                id="editQuote.items-disabled"
                values={{
                  1: (text) => <span>{text}:</span>,
                  items: capitalizeAndReplace(diffArray.disabled.join(', '), {
                    from: '-',
                    to: ' ',
                  }),
                }}
                defaultMessage="<1>Disabled</1> {items}"
              />
            </p>
          )}
        </>
      );
    } else if (['checkIn', 'checkOut'].includes(value[0])) {
      result = (
        <>
          <p>{newValue.format('DD/MM/YYYY')}</p>
          <p className="old-dates">
            <FormattedMessage
              id="editQuote.oldDates"
              values={{ dates: oldValue.format('DD/MM/YYYY') }}
              defaultMessage="(instead of {dates})"
            />
          </p>
        </>
      );
    } else if (value[0] === 'checkIn-checkOut') {
      result = (
        <>
          <p>
            {newValue['checkIn'].format('DD/MM/YYYY')} - {newValue['checkOut'].format('DD/MM/YYYY')}
          </p>
          <p className="old-dates">
            <FormattedMessage
              id="editQuote.oldDates"
              values={{
                dates: `${oldQuote['checkIn'].format('DD/MM/YYYY')} - ${oldQuote['checkOut'].format(
                  'DD/MM/YYYY',
                )}`,
              }}
              defaultMessage="(instead of {dates})"
            />
          </p>
        </>
      );
    } else if (value[0] === 'features') {
      const oldValuesArray = Object.values(oldValue).reduce((acc, value) => {
        return [...acc, ...value];
      }, []);
      const newValuesArray = Object.values(newValue).reduce((acc, value) => {
        return [...acc, ...value];
      }, []);
      const diffArray = [...newValuesArray, ...oldValuesArray].reduce(
        (acc, value) => {
          if (
            value?.isFixed ||
            (newValuesArray.some((val) => val.id === value.id) &&
              oldValuesArray.some((val) => val.id === value.id))
          ) {
            return acc;
          }
          if (
            newValuesArray.some((val) => val.id === value.id) &&
            !oldValuesArray.some((val) => val.id === value.id)
          ) {
            acc['enabled'] = [...acc['enabled'], getFeatureName(value)];
            return acc;
          }
          acc['disabled'] = [...acc['disabled'], getFeatureName(value)];
          return acc;
        },
        { enabled: [], disabled: [] },
      );
      result = (
        <>
          {!!diffArray.enabled.length && (
            <p>
              <FormattedMessage
                id="editQuote.items-enabled"
                values={{
                  1: (text) => <span>{text}:</span>,
                  items: capitalize(diffArray.enabled.join(', ')),
                }}
                defaultMessage="<1>Enabled</1> {items}"
              />
            </p>
          )}
          {!!diffArray.disabled.length && (
            <p>
              <FormattedMessage
                id="editQuote.items-disabled"
                values={{
                  1: (text) => <span>{text}:</span>,
                  items: capitalize(diffArray.disabled.join(', ')),
                }}
                defaultMessage="<1>Disabled</1> {items}"
              />
            </p>
          )}
        </>
      );
    } else if (value[0] === 'mood') {
      const defaultMood = <FormattedMessage id="label.notSetUp" />;
      result = (
        <p>
          <FormattedMessage
            id="editQuote.change-mood-resume"
            values={{
              1: (text) => <span>{text}</span>,
              oldMood: capitalizeAndReplace(oldValue, { from: '-', to: ' ' }) || defaultMood,
              newMood: capitalizeAndReplace(newValue, { from: '-', to: ' ' }) || defaultMood,
            }}
            defaultMessage={`from <1>${
              capitalizeAndReplace(oldValue, { from: '-', to: ' ' }) || defaultMood
            }</1> to <1>${
              capitalizeAndReplace(newValue, { from: '-', to: ' ' }) || defaultMood
            }</1>`}
          />
        </p>
      );
    } else {
      result = `da ${oldValue} a ${newValue}`;
    }

    return (
      <div className="quote-field" key={value[0]}>
        <p className="quote-field-title">{fieldName}</p>
        <div className="quote-field-value">{result}</div>
      </div>
    );
  });
};

export const isFieldEditable = (selectedQuote) => {
  if (
    [
      MOVEMENT_STATUS.ROGITATO,
      QUOTE_STATUS.DELETED,
      QUOTE_STATUS.SCADUTO,
      QUOTE_STATUS.INVALID,
      QUOTE_STATUS.PENDING,
      QUOTE_STATUS.EX_CLIENTE,
    ].includes(selectedQuote?.status.value) ||
    (selectedQuote?.status?.value === 'proposta' &&
      [STATUS.CONFERMATO, STATUS.VALIDATO, STATUS.CONTROFIRMATO].includes(
        selectedQuote?.proposalStatus,
      ))
  ) {
    return false;
  }
  return true;
};

export const isQuoteEditable = (selectedQuote) => {
  return ![
    QUOTE_STATUS.DELETED,
    QUOTE_STATUS.INVALID,
    QUOTE_STATUS.EX_CLIENTE,
    QUOTE_STATUS.SCADUTO,
    QUOTE_STATUS.PENDING,
  ].includes(selectedQuote?.status.value);
};

export const getFeaturesIDs = (featuresObject, filterOwnFeatures = false) => {
  if (!featuresObject) return [];
  const featuresIDs = Object.values(featuresObject).reduce((acc, features) => {
    acc = [...acc, ...features];
    return acc;
  }, []);
  return filterOwnFeatures
    ? featuresIDs.filter((feature) => !feature.isFixed).map((feature) => feature.id)
    : featuresIDs.map((feature) => feature.id);
};

export const sanitizeOptionals = (optionals) =>
  optionals?.map((optional) => {
    if (typeof optional === 'object') {
      return optional.name;
    }
    return optional;
  });

export const calculateStatus = (quote) => {
  let status = quote.status;
  const movementStatus = quote.appartment.movements
    .filter((mov) => mov.originalStatus !== MOVEMENT_STATUS.TEMPORANEAMENTE_SOSPESO)
    .find((e) => e.quote === quote.id)?.status;
  const movementExpiryOn = moment(
    quote.appartment.movements.find((e) => e.quote === quote.id)?.expiryOn,
  );

  if (movementStatus === MOVEMENT_STATUS.SCADUTO) return QUOTE_STATUS.EX_CLIENTE;
  if (
    (movementStatus === MOVEMENT_STATUS.PROPOSTA && movementExpiryOn?.isBefore(moment())) ||
    (movementStatus === MOVEMENT_STATUS.INTERESSE && quote.proposals?.[0]?.expired)
  ) {
    return QUOTE_STATUS.SCADUTO;
  }
  if (status === QUOTE_STATUS.SCADUTO) {
    if (
      movementStatus === MOVEMENT_STATUS.INTERESSE ||
      (movementStatus === MOVEMENT_STATUS.OPZIONATO && movementExpiryOn?.isBefore(moment()))
    ) {
      return status;
    } else if (movementStatus) {
      return movementStatus;
    }
  }
  if (status === QUOTE_STATUS.OK && movementStatus) {
    return movementStatus;
  }
  return status;
};

export const isProposalAdvancedStatus = (quoteStatus, proposalStatus) =>
  quoteStatus === MOVEMENT_STATUS.ROGITATO ||
  (quoteStatus === MOVEMENT_STATUS.PROPOSTA &&
    (proposalStatus === STATUS.CONFERMATO || proposalStatus === STATUS.VALIDATO));

export const getTranslatedTabList = (tabs, intl) => {
  return tabs.map((tab) => ({
    ...tab,
    name: intl.formatMessage({
      id: tab.name.id,
      defaultMessage: tab.name.defaultMessage,
    }),
  }));
};

export const getCorrectsProposalReason = (reason) => {
  if (reason) {
    return (
      <FormattedMessage
        id={`reason.${reason.toUpperCase().replace(' ', '_')}`}
        defaultMessage={capitalize(reason)}
      />
    );
  }
};

export const getCurrentMovementFromQuote = (quote) => {
  const quoteID = quote.id;
  const quoteMovement = quote.appartment.movements.find((movement) => movement.quote === quoteID);
  return quoteMovement;
};

export const updateReservedTimeQuote = (oldDate, newDate) => {
  const oldDateReservation = moment(oldDate);
  const newDateReservation = moment(newDate).set({
    hour: oldDateReservation.hour(),
    minute: oldDateReservation.minutes(),
    second: oldDateReservation.seconds(),
    millisecond: oldDateReservation.milliseconds(),
  });
  return newDateReservation;
};

export const generateNewHostname = (queryParams, extraPathUrl) => {
  let finalHostName = '';
  const hostnamePage = extraPathUrl ? extraPathUrl : '/';
  const hostKey = localStorage.getItem('followup_dev_hostname') || DetectUrl();
  const hostname = window.location.hostname.replace('www.', '');
  if (hostname === hostKey.replace('www.', '')) {
    finalHostName = `${hostname.replace(
      FOLLOWUP_RENT_APPLICATION_NAME,
      BUSINESS_PLATFORM_APPLICATION_NAME,
    )}${hostnamePage}?${queryParams}`;
  } else {
    if (hostname.indexOf('localhost') >= 0) {
      finalHostName = `${BUSINESS_PLATFORM_APPLICATION_NAME}-biz-tecma-dev1.tecmasolutions.com${hostnamePage}?hostname=${hostKey}&${queryParams}`;
    } else if (
      hostname.indexOf('biz-tecma') >= 0 ||
      hostname.indexOf('-demo.tecmasolutions.com') >= 0
    ) {
      const newHostName = hostname.replace(
        FOLLOWUP_RENT_APPLICATION_NAME,
        BUSINESS_PLATFORM_APPLICATION_NAME,
      );
      finalHostName = `${newHostName}${hostnamePage}?hostname=${hostKey}&${queryParams}`;
    } else {
      finalHostName = `${hostKey.replace(
        'www',
        BUSINESS_PLATFORM_APPLICATION_NAME,
      )}${hostnamePage}?${queryParams}`;
    }
  }
  return finalHostName;
};

export default {
  getLabel,
  getFormattedName,
  getPlanimetry,
  getEnergyCertificateUrl,
  getRegistryUrl,
  getRegistryFeatureUrl,
  capitalize,
  capitalizeAndReplace,
  getUpdateQuoteResume,
  pushingToArrays,
  spliceFromArrays,
  getInitialQuoteInfo,
  isQuoteEditable,
  isFieldEditable,
  getFeaturesIDs,
  calculateStatus,
  sanitizeOptionals,
  isProposalAdvancedStatus,
  getTranslatedTabList,
  getCorrectsProposalReason,
  getCurrentMovementFromQuote,
  updateReservedTimeQuote,
  generateNewHostname,
};
