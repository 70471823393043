import { Tooltip } from '@material-ui/core';
import React from 'react';

export const getFormattedClientName = (firstName, lastName) => {
  return (
    <>
      <Tooltip title={firstName + ' ' + lastName}>
        <div>
          {(firstName?.length > 9 ? firstName.substring(0, 6).trim() + '...' : firstName || '') +
            ' ' +
            (lastName?.length > 9 ? lastName.substring(0, 6).trim() + '...' : lastName || '')}
        </div>
      </Tooltip>
    </>
  );
};

export default {
  getFormattedClientName,
};
