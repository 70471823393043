/**
 * filters an object array looking for a specific key and value
 * @param {array} array the given array
 * @param {string} key the key for filtering
 * @param {string} value the valeu to filter
 */
const getFilteredArray = (array, key, value) => {
  return array.filter(function (e) {
    return e[key] === value;
  });
};

export default getFilteredArray;
