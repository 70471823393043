import React from 'react';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import { Menu, Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { useIntl, FormattedMessage } from 'react-intl';
import 'styles/avatar.scss';
import UserLogo from 'images/assets/user_white.svg';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import { ENABLE_NEWSLETTER_POLICY } from 'constants/PagePermission';
import NewsletterModal from 'components/specific/NewsletterModal';

/**
 * Default avatar
 * based on material-ui Avatar
 * @component
 * @see {@link https://material-ui.com/api/avatar/ Avatar API}
 */
const CrmAvatar = observer(() => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const store = useStore();
  const isEnabledNewsletter = ENABLE_NEWSLETTER_POLICY.includes(store.loggedUser.role);

  const openMenu = (e) => {
    if (!anchorEl) {
      setAnchorEl(e.target);
    }
    store.setAvatarMenu(true);
  };

  const close = () => {
    store.setAvatarMenu(false);
  };

  const handleLogOut = () => {
    store.forceLogout(true);
  };

  const openNewsletterModal = () => {
    store.setOpenNewsletterModal(true);
  };

  return (
    <>
      <div className="avatar-wrapper">
        <Button
          aria-controls="simple-menu"
          className="user-button"
          onClick={openMenu}
          disableRipple={true}
        >
          <CrmSvgLoader data={UserLogo} alt="User" className="user-logo" />
          <div className="logged-user-name">{store.loggedUserFullname.toLowerCase()}</div>
        </Button>
        {store.avatarMenu && (
          <Menu
            id="menu2"
            anchorEl={anchorEl}
            keepMounted
            open={store.avatarMenu}
            onClose={close}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{
              paper: 'no-border user-option-selection',
            }}
          >
            {isEnabledNewsletter && (
              <MenuItem
                classes={{
                  root: 'inline-block',
                }}
                onClick={openNewsletterModal}
              >
                {intl.formatMessage({
                  id: 'newsletter.header-button',
                  defaultMessage: 'Newsletter',
                })}
              </MenuItem>
            )}
            <Divider variant="middle" />
            <MenuItem
              classes={{
                root: 'inline-block',
              }}
              onClick={handleLogOut}
            >
              <FormattedMessage id="menu.logout" defaultMessage="Logout" />
            </MenuItem>
          </Menu>
        )}
      </div>
      <NewsletterModal />
    </>
  );
});

export default CrmAvatar;
