export const LOGGED_USER = 'user';
export const VENDOR_MANAGER = 'vendor_manager';
export const ADMIN = 'admin';
export const LOGGED_CLIENT = 'client';
export const SYSTEM = 'system';
export const ACCOUNT_MANAGER = 'account_manager';
export const VENDOR = 'vendor';
export const FRONT_OFFICE = 'front_office';
export const PROPRIETA = 'proprieta';
export const BUILDING_MANAGER = 'building_manager';

export const ENABLE_NEWSLETTER_POLICY = [VENDOR_MANAGER, VENDOR];

export const PERMISSION = {
  home: [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, FRONT_OFFICE, ADMIN, PROPRIETA, BUILDING_MANAGER],
  apps: [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, FRONT_OFFICE, ADMIN, PROPRIETA, BUILDING_MANAGER],
  clients: [
    ACCOUNT_MANAGER,
    VENDOR,
    VENDOR_MANAGER,
    FRONT_OFFICE,
    ADMIN,
    PROPRIETA,
    BUILDING_MANAGER,
  ],
  userInfoManager: [ACCOUNT_MANAGER, VENDOR_MANAGER, ADMIN],
  accountManager: [ACCOUNT_MANAGER, ADMIN],
  calendar: [
    ACCOUNT_MANAGER,
    VENDOR,
    VENDOR_MANAGER,
    FRONT_OFFICE,
    ADMIN,
    PROPRIETA,
    BUILDING_MANAGER,
  ],
  quoteList: [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, ADMIN, PROPRIETA],
  notificationManager: [ADMIN, BUILDING_MANAGER, VENDOR_MANAGER],
  addNotification: [ADMIN, BUILDING_MANAGER, VENDOR_MANAGER],
  notificationSent: [ADMIN, BUILDING_MANAGER, VENDOR_MANAGER],
  contractsManager: [ADMIN, ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER],
  'gestione-prop': [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, ADMIN],
};
