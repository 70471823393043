import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

import 'styles/input.scss';

/**
 * Default switch
 * based on material-ui Switch
 * Unknown props are passed down to the underlying MUI component
 * @component
 * @see {@link https://material-ui.com/api/switch/ Switch API}
 */
const CrmInputCheckbox = (props) => {
  const { extraClass, label, required, disabled, value, error, helperText, ...rest } = props;

  return (
    <div className="input-checkbox-container">
      <div className="input-checkbox-main-container">
        <div>
          <Checkbox
            required={required}
            disabled={disabled}
            className={`filled ${extraClass} ${error ? 'input-checkbox-error' : ''}`}
            checked={value}
            style={{ padding: '5' }}
            {...rest}
          />
        </div>
        <label className="input-checkbox-label">
          {value ? (
            <span style={{ fontWeight: '700', color: 'black' }}>{label}</span>
          ) : (
            <span>{label}</span>
          )}
        </label>
      </div>
      {error && <div className="input-checkbox-error-helper">{helperText}</div>}
    </div>
  );
};

CrmInputCheckbox.defaultProps = {
  variant: 'standard',
  extraClass: '',
  label: '',
  value: false,
  required: false,
  disabled: false,
  error: false,
  helperText: '',
};

/** prop types */
CrmInputCheckbox.propTypes = {
  /** extraClass: additional className */
  extraClass: PropTypes.string,
  /** label: for the input */
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** value: actual value */
  value: PropTypes.bool,
  /** variant: string, based on material-ui input variants. Must be one of ["standard", "filled", "outlined"] */
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  /** required: boolean, required input */
  required: PropTypes.bool,
  /** disabled: boolean, disabled input */
  disabled: PropTypes.bool,
  /** error: boolean, unchecked required input */
  error: PropTypes.bool,
  /** helperText: string, error helper text */
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CrmInputCheckbox;
