import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import SendToALl from 'images/assets/send-to-all.svg';

const CrmContainer = (props) => {
  const { containerClasses, title, subtitle, buttonMessage, buttonClick, svgImage } = props;

  return (
    <div className={containerClasses.containerStyle}>
      <div className={containerClasses.imageStyle}>
        <div className="container-svg-style">
          <CrmSvgLoader
            data={svgImage}
            alt="SendToAll"
            beforeInjection={(svg) => {
              svg.setAttribute('display', 'block');
              svg.setAttribute('margin', 'auto');
              svg.setAttribute('preserveAspectRatio', 'xMidYMin');
            }}
          />
        </div>
      </div>
      <div className={containerClasses.titleStyle}>
        <div className={containerClasses.containerMargin}>
          <div className="add-notification-title">
            <FormattedMessage id={title.label} defaultMessage={title.defaultMessage} />
          </div>
          <div className={containerClasses.subtitleStyle}>
            <FormattedMessage id={subtitle.label} defaultMessage={subtitle.defaultMessage} />
          </div>
        </div>
        <div className={containerClasses.buttonContainer}>
          <Button
            className={containerClasses.buttonStyle}
            disableRipple={true}
            onClick={() => buttonClick()}
          >
            <FormattedMessage
              id={buttonMessage.label}
              defaultMessage={buttonMessage.defaultMessage}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

CrmContainer.propTypes = {
  /** containerClasses: customize default classes */
  containerClasses: PropTypes.objectOf(PropTypes.string),
  /** title: label and default message */
  title: PropTypes.objectOf(PropTypes.string),
  /** subtitle: label and default message */
  subtitle: PropTypes.objectOf(PropTypes.string),
  /** buttonMessage: label and default message */
  buttonMessage: PropTypes.objectOf(PropTypes.string),
  /** buttonClick: call a function when you click the button */
  buttonClick: PropTypes.func,
};

CrmContainer.defaultProps = {
  containerClasses: {
    containerStyle: 'add-notification',
    imageStyle: 'add-notification-image-container',
    titleStyle: 'add-notification-text',
    subtitleStyle: 'add-notification-subtitle',
    buttonStyle: 'notification-table-button',
    topContainer: 'add-notification-sub-container-top',
    bottomContainer: 'add-notification-sub-container-bottom',
    containerMargin: 'add-notification-text-position',
    buttonContainer: 'notification-table-button-position',
  },
  title: {
    label: 'notification.sendToAll',
    defaultMessage: 'Send to All',
  },
  subtitle: {
    label: 'notification.sendToAllMessage',
    defaultMessage: 'Notify all your customers',
  },
  buttonMessage: {
    label: 'notification.sendButtonMessage',
    defaultMessage: 'Click here',
  },
  svgImage: SendToALl,
};

export default CrmContainer;
