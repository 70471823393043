export const QUOTE_STATUS = Object.freeze({
  OK: 'ok',
  DELETED: 'cancellato',
  EXPIRED: 'scaduto',
  PENDING: 'sospeso',
  INVALID: 'invalido',
  EX_CLIENTE: 'ex_cliente',
  SCADUTO: 'scaduto',
  // sales
  WAITING: 'attesa',
  FINALIZED: 'acquistato',
});

export const MOVEMENT_STATUS = Object.freeze({
  /** accesso      : lato sale, indica un accesso su floorplanning da parte di un client insieme ad un vendor */
  ACCESSO: 'accesso',
  /** interesse    : lato sale e rent viene creato contestualmente alla creazione del preventivo. */
  INTERESSE: 'interesse',
  /** opzionato    : lato rent viene utilizzato per bloccare un appartamento ( tramite caparra ). Lato sale, l'appartamento è bloccato tramite deposito cauzionale */
  OPZIONATO: 'opzionato',
  /** proposta     : utilizzato quando un client effettua una proposta, che poi deve essere validata */
  PROPOSTA: 'proposta',
  /** compromesso  : utilizzato per indicare che l'appartamento è alla fase di compromesso (di fatto venduto, manca atto notarile) */
  COMPROMESSO: 'compromesso',
  /** rogitato     : indica che l'appartamento è stato venduto (atto notarile fatto) o affittato */
  ROGITATO: 'rogitato',
  /** scaduto      : utilizzato per indicare che un appartamento era affittato ( aka rogitato ) ma non è stato rinnovato. */
  SCADUTO: 'scaduto',
  /** disabilitato : utilizzato per indicare che un appartamento è stato disabilitato per un periodo di tempo e per il restante periodo sarà disponibile. */
  TEMPORANEAMENTE_SOSPESO: 'temporaneamente_sospeso',
  NULL: null,
});
