/**
 * a function that returns the name of the building,
 * with the apartment for input
 */
import React from 'react';
import { getFormattedName } from './util';

export const getBuilding = (apartment) => {
  return apartment?.sides?.[0]?.building?.name || apartment?.building?.name || '';
};

export const getComplex = (apartment) => {
  return apartment?.sides?.[0]?.building?.complex || '';
};

/**
 * a function that returns the building entity of apartment,
 * if exist or null
 */
export const getBuildingInfo = (apartment) => {
  return apartment?.sides?.[0]?.building || null;
};

export const getRenderBuildingName = (apartment) => {
  return <span className="capitalize">{getFormattedName(getBuilding(apartment))}</span>;
};

export default {
  getBuildingInfo,
  getBuilding,
  getComplex,
  getRenderBuildingName,
};
