import React from 'react';
import CrmSnackbar from 'components/common/CrmSnackbar';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import ClearIcon from '@material-ui/icons/Clear';

const CrmSnackbarError = observer(() => {
  const store = useStore();

  return (
    <CrmSnackbar open={store.snackbarError} onClose={() => store.setSnackbarError(false)}>
      <div className="snackbar-body-error">
        <ClearIcon style={{ marginRight: '5px' }} />
        {store.snackbarMessage}
      </div>
    </CrmSnackbar>
  );
});

export default CrmSnackbarError;
