import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';

import { MOVEMENT_STATUS } from 'constants/quoteStatus';
import { SPACE_AVAILABILITY_STATUS_ORDER } from 'constants/apartment';
import Capitalize from './Capitalize';

export const isTemporarilyDisabled = (movement) =>
  movement.status === MOVEMENT_STATUS.TEMPORANEAMENTE_SOSPESO &&
  moment(movement.checkOut).isSameOrAfter(moment(), 'day');

export const wasTemporarilyDisabled = (movement) =>
  movement.originalStatus === MOVEMENT_STATUS.TEMPORANEAMENTE_SOSPESO;

export const wasTemporarilyDisabledStatus = (movement) =>
  movement.status === MOVEMENT_STATUS.SCADUTO && !movement.quote;
export const isOrWasTemporarilyDisabled = (movement) => {
  return isTemporarilyDisabled(movement) || wasTemporarilyDisabled(movement);
};

export const searchApartmentAvailability = (apartment) => {
  const availabilityStatus = getApartmentAvailableStatus(apartment);
  return SPACE_AVAILABILITY_STATUS_ORDER[availabilityStatus];
};

export const getApartmentAvailableStatus = (apartment) => {
  if (!apartment.available) {
    return 1;
  }
  const hasTemporarilyDisabled = apartment?.movements?.find(isTemporarilyDisabled);
  return hasTemporarilyDisabled ? 0 : 2;
};

export const getTemporarilyDisabledDates = (apartment) => {
  if (apartment?.available) {
    const hasTemporarilyDisabled = apartment?.movements?.find(isTemporarilyDisabled);
    if (hasTemporarilyDisabled) {
      return {
        startDate: moment(hasTemporarilyDisabled.checkIn),
        endDate: moment(hasTemporarilyDisabled.checkOut),
      };
    }
  }
  return null;
};

export const extrapolatePacksFromGetApartmentAllowedMoodsPacksQuery = (
  apartmentAllowedMoodsPacks,
  apartmentMood,
) => {
  return apartmentAllowedMoodsPacks.reduce((acc, mood) => {
    if (apartmentMood && apartmentMood !== mood.name) {
      return acc;
    }
    const packsName = mood.packs.map((pack) => pack.name);
    return [...new Set([...acc, ...packsName])];
  }, []);
};

export const extrapolateMoodsFromGetApartmentAllowedMoodsPacksQuery = (
  apartmentAllowedMoodsPacks,
  intl,
) => {
  const moodOptions = apartmentAllowedMoodsPacks?.map((el) => {
    return { label: Capitalize(el.name), value: el.name };
  });

  if (moodOptions?.length) {
    moodOptions.push({
      value: '',
      label: intl.formatMessage({ id: 'label.notSetUp', defaultMessage: 'Not Set Up' }),
    });
  }
  return moodOptions;
};

export const getTemporarilyDisabledTooltipContent = ({ startDate, endDate }) => {
  return (
    <div className="availability-input-tooltip">
      <FormattedMessage
        id="editAvailability.tooltip-info"
        defaultMessage="<1>Not available</1> from {startDate}{breakPoint} to {endDate}"
        values={{
          1: (content) => <strong>{content}</strong>,
          breakPoint: <br />,
          startDate: startDate.format('DD-MM-YYYY'),
          endDate: endDate.format('DD-MM-YYYY'),
        }}
      />
    </div>
  );
};

getTemporarilyDisabledTooltipContent.propTypes = {
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
};
