import { gql } from '@apollo/client';
import { pollInterval } from '../ClientConstants';
import { CORE_FIELDS_MOVEMENT, MOVEMENT, APARTMENT_DATA_TO_RETRIEVE } from '../fragments';

export const APARTMENT_LIST_DATA_TO_RETRIEVE = `
      id      
      name
      price
      status
      available
      updatedOn
      movements{
        ${CORE_FIELDS_MOVEMENT}
      }
      plan {
        typology {
          name
        }
      }
      sides {
        building {
          name
          complex
        }
      }
`;

const FEATURE = `
  id
  name
  type
  price
  floor
  notes
  code
  meters
  width
  length
  visible
  available
  createdOn
  status
  sub
  expenses
  extraInfo
  building{
    id
    name
  }
`;

export const SPACES_IN_RANGE_DATA = `
  name
  type
  building
  mood
  space
  packs
  price
`;

export const ALL_FEATURES_DATA = `
  type
`;

export const GET_APARTMENT_DATA = `
id
name
price
status
floor
available
enabled_web
enabled_desk
forRent
extraInfo
associatedExtraSpaces{
  ${FEATURE}
}
selectedBy {
  client {
    id
    firstName
    lastName
    avatar
  }
  status
  expiryOn
}
interestedBy {
  client {
    id
    firstName
    lastName
    email
  }
  status
}
reservedBy {
  client {
    id
    firstName
    lastName
  }
  status
  expiryOn
  quote
}
updatedOn
plan {
  id
  name
  SuperficieAppartamento
  SuperficieTotale
  SuperficieGiardino
  SuperficieTerrazzo
  SuperficieBalcone
  quadrants
  typology {
    id
    name
    formulas {
      label
    }
  }
  model {
    id
    name
  }
  extraInfo
}
building {
  id
  name
}
sides {     
building {
  id
  name
  complex
}
  id
  name
  pass
  staircase
  building {
    name
    id
    complex
  }
}
availableQuadrants
packsList
mood
movements {
  ${MOVEMENT}
}

`;

export const ALL_APPARTMENT_DATA = `
      id
      name
      packsList
      mood
      movements {
        ${MOVEMENT}
      }
      plan {
        id
        name
        SuperficieAppartamento
        SuperficieTotale
        SuperficieGiardino
        SuperficieTerrazzo
        SuperficieBalcone
        quadrants
        typology {
          id
          name
          formulas {
            label
          }
        }
        model {
          id
          name
        }
        extraInfo
      }
      floor
      price
`;

export const CLIENT_APPARTMENT_DATA_TO_RETRIEVE = `
      id
      name
      price
      status
      floor
      available
      enabled_web
      enabled_desk
      forRent
      extraInfo
      selectedBy {
        client {
          id
          firstName
          lastName
          avatar
        }
        status
        expiryOn
      }
      interestedBy {
        client {
          id
          firstName
          lastName
        }
        status
      }
      updatedOn
      plan {
        id
        name
        SuperficieAppartamento
        SuperficieTotale
        SuperficieGiardino
        SuperficieTerrazzo
        SuperficieBalcone
        quadrants
        typology {
          id
          name
          formulas {
            label
          }
        }
        model {
          id
          name
        }
        extraInfo
      }
      building {
        id
        name
      }
      sides {
        id
        name
        pass
        staircase
        building {
          name
          id
        }
      }
      availableQuadrants
      packsList
      mood
      movements{
        ${MOVEMENT}
      }

`;

export const UPDATE_SPACE_AVAILABILITY_DATA = `
  done
  errors
`;

export const GET_APARTMENT = gql`
  query getAppartment($project_id: ID!, $id: ID!) {
    getAppartment(project_id: $project_id, id: $id) {
      ${GET_APARTMENT_DATA}
    }
  }
`;

export const GET_APARTMENT_OPTIONS = (project_id, id) => {
  const options = {
    variables: {
      project_id: project_id,
      id: id,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

export const GET_APARTMENT_BY_NAME = gql`
  query getAppartmentByName($project_id: ID!, $appartmentName: String!) {
    getAppartmentByName(project_id: $project_id, appartmentName: $appartmentName) {
      ${APARTMENT_DATA_TO_RETRIEVE}
    }
  }
`;

export const GET_APARTMENT_BY_NAME_OPTIONS = (project_id, appartmentName) => {
  const options = {
    variables: {
      project_id,
      appartmentName,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

export const GET_FEATURES_BY_APARTMENT = gql`
  query getFeaturesByApartment($project_id: ID!, $appartment: ID!) {
    getQuotesByApartment(project_id: $project_id, appartment: $appartment) {
      customQuote {
        features {
          name
          type
        }
      }
    }
  }
`;

export const GET_FEATURES_BY_APARTMENT_OPTIONS = (project_id, appartment) => {
  const options = {
    variables: {
      project_id,
      appartment,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

export const GET_ALL_APPARTMENTS = gql`
  query getAllAppartments ($project_id: ID!) {
    getAllAppartments (project_id: $project_id) {
      ${APARTMENT_DATA_TO_RETRIEVE}
    }
  }`;

export const GET_ALL_APPARTMENTS_DEFAULT_OPTIONS = (pId, nextFetchPolicy) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: nextFetchPolicy ? 'cache-first' : null,
  };
};

export const GET_ALL_APPARTMENTS_NETWORK_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    fetchPolicy: 'network-only',
  };
};

export const GET_ALL_APPARTMENTS_LIST = gql`
  query getAllAppartments ($project_id: ID!) {
    getAllAppartments (project_id: $project_id) {
      ${APARTMENT_LIST_DATA_TO_RETRIEVE}
    }
  }`;

export const GET_CLIENT_APPARTMENTS = gql`
  query getClientAppartments ($project_id: ID!, $client_id: ID!, $status: [String!]) {
    getClientAppartments (project_id :$project_id, client_id: $client_id, status: $status) {
      ${CLIENT_APPARTMENT_DATA_TO_RETRIEVE}
    }
}`;
export const GET_CLIENT_APPARTMENTS_DEFAULT_OPTIONS = (project_id, id, status) => {
  return {
    variables: {
      project_id: project_id,
      client_id: id,
      status: status,
    },
    fetchPolicy: 'no-cache',
  };
};

export const UPDATE_APARTMENT = gql`
  mutation updateAppartments($project_id: ID!, $id: ID!, $input: UpdateAppartmentInput!) {
    updateAppartments(project_id: $project_id, id: $id, input: $input) {
      ${APARTMENT_DATA_TO_RETRIEVE}
    }
  }
`;

export const UPDATE_APARTMENT_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

export const UPDATE_SPACE_AVAILABILITY = gql`
  mutation updateSpaceAvailability($projectId: ID!, $spaceId: ID!, $suspension: SpaceSuspension!) {
    updateSpaceAvailability(projectId: $projectId, spaceId: $spaceId, suspension: $suspension) {
      ${UPDATE_SPACE_AVAILABILITY_DATA}
    }
  }
`;

export const UPDATE_SPACE_AVAILABILITY_OPTIONS = (projectId, spaceId, suspension) => {
  return {
    variables: {
      projectId,
      spaceId,
      suspension,
    },
  };
};

export const GET_APPARTMENTS_FILTERED = gql`
  query getAppartmentsFiltered($project_id: ID!, $appartmentInput: AppartmentFilterInput!) {
    getAppartmentsFiltered(project_id: $project_id, appartmentInput: $appartmentInput) {
      ${ALL_APPARTMENT_DATA}
    }
  }
`;

export const GET_APPARTMENTS_FILTERED_OPTIONS = (project_id, appartmentInput) => {
  const options = {
    variables: {
      project_id: project_id,
      appartmentInput: appartmentInput,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

export const GET_SPACES_IN_RANGE = gql`
  query getSpacesInRange($project_id: ID!, $input: SpaceInRangeInput!, $filter: SpaceFilter) {
    getSpacesInRange(project_id: $project_id, input: $input, filter: $filter)
  }
`;

export const GET_SPACES_IN_RANGE_OPTIONS = (project_id, input, filter) => {
  const options = {
    variables: {
      project_id,
      input,
      filter,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

export const GET_ALL_FEATURES = gql`
  query getAllFeatures($project_id: ID!, $page: Int, $perPage: Int) {
    getAllFeatures(project_id: $project_id, page: $page, perPage: $perPage) {
      ${ALL_FEATURES_DATA}
    }
  }
`;

export const GET_ALL_FEATURES_OPTIONS = (project_id, page, perPage) => {
  const options = {
    variables: {
      project_id,
      page,
      perPage,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

export const GET_SPACE_UNAVAILABLE_DATES = gql`
  query getSpaceUnavailableDates($projectId: ID!, $filter: SpaceAvailabilityFilter!) {
    getSpaceUnavailableDates(projectId: $projectId, filter: $filter) {
      firstDay
      lastDay
      spaceId
      spaceType
    }
  }
`;

export const GET_SPACE_UNAVAILABLE_DATES_OPTIONS = (projectId, filter) => {
  const options = {
    variables: {
      projectId,
      filter,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

export const GET_APARTMENT_ALLOWED_MOODS_PACKS = gql`
  query getApartmentAllowedMoodsPacks($projectId: ID!, $apartmentId: ID!) {
    getApartmentAllowedMoodsPacks(projectId: $projectId, apartmentId: $apartmentId) {
      allowedMoods {
        name
        packs {
          name
          noMood
          singleUse
        }
      }
    }
  }
`;

export const GET_APARTMENT_ALLOWED_MOODS_PACKS_OPTIONS = (projectId, apartmentId) => {
  const options = {
    variables: {
      projectId,
      apartmentId,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};
