/**
 * a number formatter for currency
 */

const CurrencyFormatter = (currency, locale, value, extraValue = '') => {
  try {
    return new Intl.NumberFormat(locale ? locale : 'it-IT', {
      style: 'currency',
      currency: currency ? currency : 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
      .format(value)
      .concat(extraValue);
  } catch (error) {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
      .format(value)
      .concat(extraValue);
  }
};

export default CurrencyFormatter;
