import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import variables from 'styles/colors.scss';
import { CsvBuilder } from 'filefy';
import 'styles/table.scss';
import { cloneDeep } from 'lodash';
import { useIntl } from 'react-intl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import searchLens from 'images/assets/search-table.svg';
/**
 * customized table based on material-table
 * @component
 * @see {@link https://material-table.com/ material-table}
 */
const CrmTable = (props) => {
  const handleExportCsv = (columns, renderData, exportFileName) => {
    const csvColumns = columns.filter((columnDef) => {
      return columnDef.field && columnDef.export !== false;
    });

    const data = renderData.map((rowData) =>
      csvColumns.map((columnDef) => rowData[columnDef.field]),
    );

    const builder = new CsvBuilder(exportFileName ? exportFileName + '.csv' : 'export.csv')
      .setDelimeter(',')
      .setColumns(csvColumns.map((columnDef) => columnDef.title))
      .addRows(data)
      .exportFile();
  };

  const {
    title,
    data,
    columns,
    paging,
    searchText,
    extraComponents,
    pageSize,
    pageSizeOptions,
    containerClass,
    search,
    exportButton = false,
    exportFileName,
    saveSearch,
    filtering,
    saveSort,
    hideHeader,
    ...rest
  } = props;

  const theme = useTheme();
  const matchPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const matchSm = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  let customStyle = {};
  let alignment = 'right';

  if (matchSm) {
    customStyle = { ...customStyle, position: 'relative' };
    alignment = 'left';
  }

  if (matchPhone) {
    customStyle = { ...customStyle, width: '100%', position: 'absolute', bottom: '-24px' };
  }

  const finalOptions = {
    sorting: true,
    search: search,
    searchText: searchText,
    emptyRowsWhenPaging: false,
    paging: paging && data && data.length > 0,
    headerStyle: {
      backgroundColor: variables.CARD_BACKGROUND_LIGHT,
      fontFamily: variables.DEFAULT_FONT + '!important',
      fontWeight: variables.LIGHT + '!important',
      display: hideHeader ? 'none' : '',
    },
    rowStyle: {
      height: '80px',
    },
    pageSize: pageSize,
    pageSizeOptions: pageSizeOptions,
    exportFileName: exportFileName,
    exportButton: exportButton,
    exportAllData: exportButton,
    exportCsv: (columns, renderData) => handleExportCsv(columns, renderData, exportFileName),
    filtering: filtering,
    searchFieldStyle: customStyle,
    searchFieldAlignment: alignment,
  };

  const defaultComponents = search
    ? {}
    : {
        Toolbar: () => '',
      };

  const mergedComponents = Object.assign({}, defaultComponents, extraComponents);

  const intl = useIntl();

  const editable = cloneDeep(data);

  return (
    <div className={containerClass}>
      <MaterialTable
        title={title}
        components={mergedComponents}
        columns={columns}
        data={editable}
        style={{ backgroundColor: 'inherit' }}
        icons={{
          SortArrow: forwardRef((props, ref) => <KeyboardArrowDownIcon {...props} ref={ref} />),
          Search: forwardRef((props, ref) => (
            <CrmSvgLoader data={searchLens} alt="lens" id={'lens-in-table'} {...props} ref={ref} />
          )),
        }}
        options={finalOptions}
        onSearchChange={saveSearch}
        onOrderChange={saveSort}
        localization={{
          body: {
            emptyDataSourceMessage: intl.formatMessage({
              id: 'general.noDataToShow',
              defaultMessage: 'No data to show',
            }),
          },
          toolbar: {
            searchTooltip: intl.formatMessage({
              id: 'general.search',
              defaultMessage: 'Search',
            }),
            searchPlaceholder: intl.formatMessage({
              id: 'general.search',
              defaultMessage: 'Search',
            }),
            exportName: intl.formatMessage({
              id: 'general.exportCSV',
              defaultMessage: 'Export as CSV',
            }),
            exportAriaLabel: intl.formatMessage({
              id: 'general.export',
              defaultMessage: 'Export',
            }),
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage({
              id: 'general.displayedRows',
              defaultMessage: '{from}-{to} of {count}',
            }),
            labelRowsSelect: intl.formatMessage({
              id: 'general.rows',
              defaultMessage: '/rows',
            }),
            labelRowsPerPage: intl.formatMessage({
              id: 'general.rowsPerPage',
              defaultMessage: 'Rows per page:',
            }),
            firstAriaLabel: intl.formatMessage({
              id: 'general.firstPageAriaLabel',
              defaultMessage: 'First Page',
            }),
            firstTooltip: intl.formatMessage({
              id: 'general.firstPageTooltip',
              defaultMessage: 'Firse page',
            }),
            previousAriaLabel: intl.formatMessage({
              id: 'general.previousPageAriaLabel',
              defaultMessage: 'Previous page',
            }),
            previousTooltip: intl.formatMessage({
              id: 'general.previousPageTooltip',
              defaultMessage: 'Previous page',
            }),
            nextAriaLabel: intl.formatMessage({
              id: 'general.nextPageAriaLabel',
              defaultMessage: 'Next page',
            }),
            nextTooltip: intl.formatMessage({
              id: 'general.nextPageTooltip',
              defaultMessage: 'Next page',
            }),
            lastAriaLabel: intl.formatMessage({
              id: 'general.lastPageAriaLabel',
              defaultMessage: 'Last page',
            }),
            lastTooltip: intl.formatMessage({
              id: 'general.lastPageTooltip',
              defaultMessage: 'Last page',
            }),
          },
        }}
        {...rest}
      />
    </div>
  );
};
/** prop types */
CrmTable.propTypes = {
  /** extraComponents: additional custom components */
  extraComponents: PropTypes.object,
  /** containerClass: SCSS class for table container */
  containerClass: PropTypes.string,
  /** title: string or html node for table title */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** data: an array of data to be displayed */
  data: PropTypes.arrayOf(PropTypes.object),
  /** columns: column definitions */
  columns: PropTypes.arrayOf(PropTypes.object),
  /** paging: boolean for pagination ability */
  paging: PropTypes.bool,
  /** search: boolean for searching ability */
  search: PropTypes.bool,
  /** search: boolean for searching ability */
  hideHeader: PropTypes.bool,
  /** pageSize: default page size (if pagination = true) */
  pageSize: PropTypes.number,
  /** pageSizeOptions: array of available page sizes */
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  /** filtering: boolean for filtering ability */
  filtering: PropTypes.bool,
  /** exportButton: export sorting button on title table */
  exportButton: PropTypes.bool,
  /** saveSearch: save search in material-table */
  saveSearch: PropTypes.func,
  /** saveSort: save sort in material-table  */
  saveSort: PropTypes.func,
};

CrmTable.defaultProps = {
  containerClass: '',
  searchText: '',
  title: '',
  data: [],
  search: false,
  loading: false,
  columns: [],
  paging: false,
  pageSize: 5,
  pageSizeOptions: [5, 10, 20],
  filtering: false,
};

export default CrmTable;
