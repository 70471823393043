import { useMutation } from '@apollo/client';
import Client from 'client/Client';
import { useWinstonLogger } from 'winston-react';

const useLogout = () => {
  const [logoutMutation, logoutMutationInfo] = useMutation(Client.MUTATION_LOGOUT);
  const logger = useWinstonLogger();

  const logOut = async () => {
    if (logoutMutationInfo && logoutMutationInfo.called) {
      logger.warn('...trying to log out ...');
      return logoutMutationInfo;
    } else {
      try {
        logger.warn('starting log out...');
        await logoutMutation();
        return logoutMutationInfo;
      } catch (e) {
        logger.error('logout on server error');
        throw e;
      }
    }
  };

  return { logOut };
};

export default useLogout;
