import React from 'react';
import RouteConsts from 'routes/Routes.jsx';
import { useHistory } from 'react-router-dom';
import { useStore } from 'store/storeUtils.js';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import { PERMISSION } from 'constants/PagePermission.jsx';
import LogoTecma from 'images/assets/tecma_logo.svg';
import { FormattedMessage } from 'react-intl';

import 'styles/navbar.scss';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

/**
 * Sidebar
 * based on material-ui BreadCrumbs
 * It contains a fixed drawer for mdUp screens and a mobile drawer for smaller screens
 * @component
 * @see {@link https://material-ui.com/api/drawer/  Drawer API}
 */
const CrmNavbar = observer((props) => {
  const { setLanguage } = props;
  const history = useHistory();
  const store = useStore();
  const navbarRoutes =
    RouteConsts &&
    RouteConsts.filter((e) => {
      const page = PERMISSION[e.id];
      const pagePermission =
        !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
      return store.enabledSections && store.enabledSections.length > 0
        ? store.enabledSections.includes(e.id) && e.navbar && pagePermission
        : e.navbar && pagePermission;
    }).filter((el) => {
      // const myLiving = store.enabledTools.filter((tool) => tool.name === 'MyLiving')[0];
      const notificationSection = [
        'notificationDetail',
        'notificationSentDetail',
        'notificationSent',
        'addNotificationForEveryone',
        'addNotificationForClient',
        'addNotification',
        'notificationManager',
      ].includes(el.id);
      if (notificationSection) {
        return store.isMyLivingActive; 
      }
      return el;
    });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const handleClick = () => {
    store.toggleMenuMobile();
    store.setOpenDrawer(!store.openDrawer);
  };

  const handleClose = (val) => {
    store.closeMenuMobile();
    if (typeof val !== 'undefined' && typeof val === 'number') {
      goTo(val);
    }
    if (matchesPhone) {
      store.setOpenDrawer(!store.openDrawer);
    }
  };

  const goHome = () => {
    store.closeMenuMobile();
    goTo(0);
  };

  const goTo = (val) => {
    store.updateCurrentNavTab(val, navbarRoutes[val].to);
    history.push(navbarRoutes[val].to);
    if (navbarRoutes[val].to !== '/appartamenti' && store.tabValueApartment === 1) {
      store.setTabValueApartment(0);
    }
  };

  const handleChangeLanguage = (e) => {
    setLanguage(e);
    store.setOpenDrawer(!store.openDrawer);
  };

  if (typeof store.openDrawer === 'undefined') {
    if (matches) {
      store.setOpenDrawer(false);
    } else {
      store.setOpenDrawer(true);
    }
  }

  React.useEffect(() =>
    autorun(() => {
      if (
        typeof store.realLocation === 'undefined' &&
        (store.currentNavTab || store.currentNavTab === 0) &&
        navbarRoutes[store.currentNavTab] &&
        history.location.pathname !== navbarRoutes[store.currentNavTab].to
      ) {
        history.push(navbarRoutes[store.currentNavTab].to);
      }
    }),
  );

  const addDefaultSrc = (ev, url) => {
    if (ev.target.src.includes(process.env.REACT_APP_BUCKET_BASEURL)) {
      ev.target.src = url && url.length > 0 && url[0];
    } else {
      ev.target.hidden = true;
    }
  };

  const logo = `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/global/img/primary-logo.svg`;
  const logoSmall = `${process.env.REACT_APP_BUCKET_BASEURL}initiatives/${store.loginProjectName}/global/img/primary-logo--XS.svg`;

  const drawer = (
    <>
      {store.openDrawer || matchesPhone ? (
        <img
          className={matchesPhone ? 'image-drawer-mobile' : 'image-drawer'}
          alt="logo"
          src={logo}
          onClick={goHome}
          onError={(ev) => addDefaultSrc(ev, store.logo)}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <img
          className="image-drawer-small"
          alt="logo-small"
          src={logoSmall}
          onClick={goHome}
          style={{ cursor: 'pointer' }}
          onError={(e) => {
            e.target.src = logo;
            e.target.onerror = '';
          }}
        />
      )}
      <List className={store.openDrawer ? 'navbar-list-open' : 'navbar-list-close'}>
        {navbarRoutes &&
          navbarRoutes.map((tabEl, i) => (
            <ListItem
              className={i === 0 ? 'first-drawer-of-list' : ''}
              key={`tab${tabEl.order}`}
              onClick={() => handleClose(i)}
              selected={store.currentNavTab === i}
            >
              <Button
                className="drawer-button semi-bold"
                disableRipple={true}
                startIcon={<CrmSvgLoader alt="" data={tabEl.icon} className="navbar-image-size" />}
                classes={{
                  label: 'justify-left',
                }}
              >
                {store.openDrawer && (
                  <div className="text-align-left line-height-initial">
                    <FormattedMessage id={tabEl.navbarTitle} defaultMessage={tabEl.navbarTitle} />
                  </div>
                )}
              </Button>
            </ListItem>
          ))}
      </List>
      {store.openDrawer && !matchesPhone && (
        <div className="image-tecm-container">
          <div style={{ fontSize: '11px', paddingBottom: '8px' }}>Powered by</div>
          <CrmSvgLoader data={LogoTecma} alt="Tecma" className="image-tecma" />
          <div style={{ fontSize: '11px' }}>version 1.0.1</div>
        </div>
      )}
      {matchesPhone && (
        <ToggleButtonGroup className="language-buttons">
          <ToggleButton
            value="it"
            onClick={() => {
              handleChangeLanguage('it');
            }}
            selected={store.loggedUser.language === 'it'}
            style={{ borderRight: '2px solid #CACBD3' }}
          >
            IT
          </ToggleButton>
          <ToggleButton
            value="en"
            onClick={() => {
              handleChangeLanguage('en');
            }}
            selected={store.loggedUser.language === 'en'}
          >
            EN
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );

  return (
    <div className="toolbar-container">
      <Drawer
        variant={matchesPhone ? 'persistent' : 'permanent'}
        anchor={matchesPhone ? 'right' : 'left'}
        open={store.openDrawer}
        classes={
          store.openDrawer
            ? {
                paperAnchorLeft: 'permanent-drawer',
                paperAnchorRight: 'permanent-drawer-mobile',
              }
            : {
                paper: 'permanent-drawer-close',
              }
        }
      >
        {!matchesPhone && (
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className="hamburger-button"
          >
            <MenuIcon />
          </IconButton>
        )}
        {drawer}
      </Drawer>
    </div>
  );
});

CrmNavbar.propTypes = {
  setLanguage: PropTypes.func,
};

export default CrmNavbar;
