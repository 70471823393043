import React from 'react';
import moment from 'utils/MyMoment';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import { useStore } from 'store/storeUtils.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { CalculateAptStatus } from 'utils/CalculateAptStatus';

import CrmSvgLoader from 'components/common/CrmSvgLoader';
import appartamenti from 'images/assets/appartamenti.svg';

/**
 * configuration for the appartments list with the following columns:
 * - Appartamento (appartment name)
 * - Ultimo aggiornamento (last update, rendered as time from now)
 * - Taglio (capitalized appartment size)
 * - Valore appartamento (formatted appartment price)
 * - Status (appartment status in uppercase)
 */
const ListaPreventiviDashboardTableConfig = (currency, locale) => {
  const store = useStore();
  const intl = useIntl();

  return [
    {
      title: <FormattedMessage id="apartments.apartment" defaultMessage="Apartment" />,
      field: 'name',
      sorting: true,
      customFilterAndSearch: (value, rowData) => {
        return (
          rowData.name.toLowerCase().includes(value.toLowerCase()) ||
          rowData.name
            .replace(/\_/g, '-')
            .replace(/\./g, '-')
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      render: (rowData) => (
        <div style={{ display: 'flex' }}>
          <CrmSvgLoader data={appartamenti} alt="appartamenti" />
          <div
            className="black small-font-14 capitalize font-bold"
            style={{ paddingLeft: '8px', paddingTop: '4px' }}
          >
            {rowData.name.replace(/\_/g, '-').replace(/\./g, '-')}
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="apartments.lastUpdate" defaultMessage="last Update" />,
      field: 'updatedOn',
      sorting: true,
      customFilterAndSearch: (value, rowData) => {
        return moment(new Date(rowData.updatedOn))
          .format('DD MMM YYYY')
          .includes(value.toLowerCase());
      },
      render: (rowData) => {
        const date = new Date(rowData.updatedOn);
        return <>{moment(date).format('DD MMM YYYY')}</>;
      },
    },
    {
      title: <FormattedMessage id="apartments.typology" defaultMessage="typology" />,
      field: 'plan.typology.name',
      sorting: true,
      customFilterAndSearch: (value, rowData) => {
        return intl
          .formatMessage({
            id: `label.${rowData.plan.typology.name}`,
            defaultMessage: rowData.plan.typology.name,
          })
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (rowData) => (
        <span className="capitalize">
          {' '}
          {rowData.plan.typology
            ? intl.formatMessage({
                id: `label.${rowData.plan.typology.name}`,
                defaultMessage: rowData.plan.typology.name,
              })
            : ''}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="apartments.apartmentValue" defaultMessage="Apartment Value" />,
      field: 'price',
      sorting: true,
      customFilterAndSearch: (value, rowData) => {
        return (
          CurrencyFormatter(currency, locale, rowData.price).includes(value.toLowerCase()) ||
          ('' + rowData.price).includes(value.toLowerCase())
        );
      },
      render: (rowData) => (
        <span>
          {CurrencyFormatter(currency, locale, rowData.price)}
          {<FormattedMessage id="per-month" defaultMessage="/month" />}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="apartments.status" defaultMessage="Status" />,
      field: 'status',
      sorting: true,
      customFilterAndSearch: (value, rowData) => {
        var newStatus = CalculateAptStatus(rowData.status, store.enabledStatus);
        return intl
          .formatMessage({
            id: `label.${newStatus}`,
            defaultMessage: newStatus,
          })
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (rowData) => {
        var newStatus = CalculateAptStatus(rowData.status, store.enabledStatus);
        return rowData.status && newStatus ? (
          <div style={{ display: 'flex' }}>
            <span className={'circle-in-table ' + `${rowData.status}`}></span>
            <span style={{ paddingLeft: '8px', paddingTop: '3px' }}>
              <FormattedMessage id={`label.${newStatus}`} defaultMessage={newStatus} />
            </span>
          </div>
        ) : (
          ''
        );
      },
    },
  ];
};

export default ListaPreventiviDashboardTableConfig;
