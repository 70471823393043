import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { useStore } from 'store/storeUtils.js';
import { Button } from '@material-ui/core';
import { appDetailRoute, communityCalendarDetailRoute } from 'routes/Routes';
import { useHistory } from 'react-router-dom';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import Appartamenti from 'images/assets/appartamenti.svg';
import CrmSimpleAvatar from 'components/common/CrmSimpleAvatar';
import { defaultSort } from 'utils/customSort';
import { getFormattedClientName } from 'utils/getFormattedClientName';
/**
 * configuration for the appartments list with the following columns:
 * - Appartamento (appartment name)
 * - Data creazione preventivo (last update, rendered as time from now)
 * - Valore appartamento (formatted appartment price)
 * - Valore Varianti(???)
 * - Scelta finiture(???)
 * - Stato preventivo (appartment status in uppercase)
 */

const ListaServicesTableConfig = (goToClient) => {
  const store = useStore();
  const intl = useIntl();
  const history = useHistory();

  const apartment = {
    title: <FormattedMessage id="apartments.apartment" defaultMessage="Apartment" />,
    field: 'apartment',
    customFilterAndSearch: (value, rowData) => {
      return (
        rowData.apartment?.name.toLowerCase().includes(value.toLowerCase()) ||
        rowData.apartment.name
          .replace(/\_/g, '-')
          .replace(/\./g, '-')
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    },
    render: (rowData) => (
      <div style={{ display: 'flex' }}>
        <CrmSvgLoader data={Appartamenti} alt="icon" />
        <a
          className={'black small-font-14 capitalize font-bold'}
          style={{
            paddingLeft: '8px',
            paddingTop: '4px',
            color: '#6BC1B4',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            const handleClick = (rowData) => {
              if (rowData.apartment.id) {
                store.setSelectedAppartment(rowData.apartment);
                const destination = appDetailRoute.to.replace(':id', rowData.apartment.id);
                store.setBreadCrumbs('title.apartments');
                store.setRealLocation(destination);
                history.push(destination);
              }
            };
            handleClick(rowData);
          }}
        >
          {rowData.apartment?.name
            ? rowData.apartment.name.replace(/\_/g, '-').replace(/\./g, '-')
            : rowData.apartment}
        </a>
      </div>
    ),
  };

  const client = {
    title: intl.formatMessage({
      id: 'client.user',
      defaultMessage: 'User',
    }),
    field: 'lastName',
    sorting: true,
    customFilterAndSearch: (value, rowData) => {
      return (rowData.firstName + ' ' + rowData.lastName)
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    customSort: (a, b) => {
      return defaultSort(a.firstName + ' ' + a.lastName, b.firstName + ' ' + b.lastName);
    },
    cellStyle: {
      padding: '12px',
    },
    filtering: false,
    render: (rowData) =>
      rowData.client.firstName && rowData.client.lastName ? (
        <div className="flex-centered">
          <CrmSimpleAvatar src={rowData.client.avatar}></CrmSimpleAvatar>

          <a
            className="black small-font-14 capitalize font-bold"
            style={{
              paddingLeft: '8px',
              paddingTop: '4px',
              color: '#6BC1B4',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => goToClient(rowData.client.id)}
          >
            {getFormattedClientName(rowData.client.firstName, rowData.client.lastName)}
          </a>
        </div>
      ) : (
        <div className="flex-centered">
          <CrmSimpleAvatar src={rowData.client.avatar}></CrmSimpleAvatar>
          <FormattedMessage id="notification.userDeleted" defaultMessage="User deleted" />
        </div>
      ),
  };

  const startDate = {
    title: <FormattedMessage id="calendar.date" defaultMessage="Date" />,
    field: 'startDate',
    width: '10%',
    customFilterAndSearch: (value, rowData) => {
      return moment(new Date(rowData.startDate)).fromNow().includes(value.toLowerCase());
    },
    render: (rowData) => {
      const date = new Date(rowData.startDate);
      const diff = moment().diff(moment(date), 'days');
      return (
        <>
          <div>
            <span>{moment(date).format('DD/MM/YYYY')}</span>
          </div>
        </>
      );
    },
  };

  const hours = {
    title: <FormattedMessage id="calendar.date" defaultMessage="Hours" />,
    field: 'hours',
    width: '10%',
    customFilterAndSearch: (value, rowData) => {
      return moment(new Date(rowData.startDate)).fromNow().includes(value.toLowerCase());
    },
    render: (rowData) => {
      const startDate = new Date(rowData.startDate);
      const endDate = new Date(rowData.endDate);
      return (
        <>
          <div>
            <span>{moment(startDate).format('HH:mm') + ' ' + moment(endDate).format('HH:mm')}</span>
          </div>
        </>
      );
    },
  };

  const service = {
    title: <FormattedMessage id="subject" defaultMessage="Service" />,
    field: 'service',
    customFilterAndSearch: (value, rowData) => {
      return value && rowData.service.name.toLowerCase().includes(value.toLowerCase());
    },
    render: (rowData) => {
      return (
        <div>
          <span>{rowData.service.name ? `${rowData.service.name}` : ''}</span>
        </div>
      );
    },
  };

  const openDetails = {
    field: 'openDetails',
    render: (rowData) => {
      return (
        <Button
          className="export-apartment-button"
          onClick={() => {
            const handleClick = (rowData) => {
              store.setClientId(rowData.client.id);
              store.setSelectedAppartment(rowData.apartment);
              store.setSelectedNotification(rowData);
              store.setBreadCrumbs('title.apartments');
              store.setEvent({
                id: rowData.id,
                start: rowData.startDate,
                end: rowData.endDate,
                title: rowData.title,
                extendedProps: {
                  type: rowData.reservation_type,
                  note: rowData.notes,
                  phone: rowData.client.tel,
                  email: rowData.client.email,
                  client: rowData.client.firstName + ' ' + rowData.client.lastName,
                  clientId: rowData.client.id,
                  infoText: rowData.notes,
                  avatar: rowData.client.avatar,
                  service: {
                    name: rowData.service.name,
                  },
                },
              });
              const currentData = {
                data: {
                  id: rowData.id,
                  day: moment(rowData.startDate),
                  end: moment(rowData.endDate),
                  start: moment(rowData.start),
                  title: rowData.title,
                  type: rowData.type,
                  vendor: rowData.vendor,
                },
              };
              store.setCurrentEvent(currentData);
              const destination = communityCalendarDetailRoute.to.replace(
                ':id',
                rowData.apartment.id,
              );
              store.setRealLocation(destination);
              history.push(destination);
            };
            handleClick(rowData);
          }}
        >
          <FormattedMessage
            id="apartments.open"
            defaultMessage="Open"
            style={{ marginLeft: '7px' }}
          />
        </Button>
      );
    },
  };

  return [apartment, client, startDate, hours, service, openDetails];
};

export default ListaServicesTableConfig;
