import React from 'react';
import CrmSnackbar from 'components/common/CrmSnackbar';
import CrmButton from 'components/common/CrmButton';
import * as serviceWorker from 'serviceWorker';
import { FormattedMessage } from 'react-intl';
import CrmLoader from 'components/common/CrmLoader';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [showReloading, setShowReloading] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    delete localStorage['store_followup'];
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      waitingWorker.postMessage('SKIP_WAITING');
      setShowReloading(true);
    }
  };

  return (
    <CrmSnackbar open={showReload} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <div className="snackbar-body">
        {!showReloading ? (
          <>
            <FormattedMessage id="crm.newVersion" defaultMessage="a new version available!" />
          </>
        ) : (
          <>
            <div style={{ height: '60px' }}></div>
          </>
        )}
        {showReloading ? (
          <CrmLoader loading={true} hasBackdrop={false} transparency />
        ) : (
          <CrmButton
            className="modal-action-button"
            style={{ backgroundColor: '#6bc1b4' }}
            size="small"
            onClick={reloadPage}
          >
            <FormattedMessage id="crm.update" defaultMessage="Update" />
          </CrmButton>
        )}
      </div>
    </CrmSnackbar>
  );
};

export default ServiceWorkerWrapper;
