import { gql } from '@apollo/client';
import { pollInterval } from 'client/ClientConstants';
import {
  GENERIC_RETURN_FRAGMENT,
  QUOTE_FRAGMENT,
  UPDATE_QUOTE_ERROR_FRAGMENT,
  MOVEMENT,
} from '../fragments';

export const UPDATE_QUOTE_DATA = `
  ... on UpdateQuoteError {
    ...UpdateQuoteErrorFragment
  }
  ...QuoteFragment
`;

export const VALIDATE_QUOTE_DATA = `
  ... on UpdateQuoteError {
    ...UpdateQuoteErrorFragment
  }
  ...GenericReturnFragment
`;
export const UPDATE_QUOTE_PROPOSAL_EXPIRY_ON_DATA = `
  done
  errors
`;

export const GET_TOTAL_RATE_DATA = `
  baseRate
  buildingFees
`;

export const LEAN_QUOTE = `
    id
    client {
      id
      firstName
      lastName
    }
    apartment {
      id
      name
    }
    savedDocuments {
      name
      path
      lastModified
    }
    quoteNumber
    status
    movement {
      ${MOVEMENT}
    }
    proposal
    createdOn
    expiryOn
`;

export const COMPLEX_QUOTE = `
  id
  status
  quoteNumber
  reserved
  pdf
  client{
    id
    firstName
    lastName
  }
  customQuote {
    totalPrice
  }
  proposals
  appartment {
    id
    name
    sides {     
      building {
        name
      }
    }
    selectedBy {
      client {
        id
      }
      expiryOn
    }
    reservedBy {
      status
      expiryOn
      quote
    }
    movements {
      ${MOVEMENT}
    }
  }
  createdOn
  expiryOn
`;

export const LISTA_PREVENTIVI_QUOTE = `
  id
  status
  quoteNumber
  reserved
  pdf
  client{
    id
    firstName
    lastName
  }
  customQuote {
    totalPrice
    expenses {
      name
    }
    features{
      id
      name
      type
    }
    freeNotes
  }
  proposals
  appartment {
    id
    name
    mood
    plan {
      typology {
        name
      }
    }
    building {
      name
      id
    }
    sides {     
      building {
        name
        id
      }
    }
    selectedBy {
      client {
        id
      }
      expiryOn
    }
    reservedBy {
      status
      expiryOn
      quote
    }
    associatedExtraSpaces {
      id
    }
    packsList
    movements {
      ${MOVEMENT}
    }
  }
  createdOn
  expiryOn
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote($project_id: ID!, $quoteId: ID!, $input: UpdateQuoteInput!) {
    updateQuote(project_id: $project_id, quoteId: $quoteId, input: $input) {
      ${UPDATE_QUOTE_DATA}
    }
  }
  ${QUOTE_FRAGMENT}
  ${UPDATE_QUOTE_ERROR_FRAGMENT}
`;

export const UPDATE_QUOTE_OPTIONS = (project_id, quoteId, input) => {
  const options = {
    variables: {
      project_id,
      quoteId,
      input,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

export const UPDATE_QUOTE_ADVANCED_STATUS_CHECKOUT = gql`
  mutation updateQuoteAdvancedStatusCheckout($project_id: ID!, $quoteId: ID!, $checkOut: String!) {
    updateQuoteAdvancedStatusCheckout(
      project_id: $project_id
      quoteId: $quoteId
      checkOut: $checkOut
    ) {
      done
      errors
    }
  }
`;

export const UPDATE_QUOTE_ADVANCED_STATUS_CHECKOUT_OPTIONS = (project_id, quoteId, checkOut) => {
  const options = {
    variables: {
      project_id,
      quoteId,
      checkOut,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

export const GET_TOTAL_RATE = gql`
  query getTotalRate($project_id: ID!, $input: getTotalRateInput!) {
    getTotalRate(project_id: $project_id, input: $input) {
      ${GET_TOTAL_RATE_DATA}
    }
  }
`;

export const GET_TOTAL_RATE_OPTIONS = (project_id, input) => {
  const options = {
    variables: {
      project_id,
      input,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  };

  return options;
};

export const VALIDATE_QUOTE = gql`
  mutation validateQuote($project_id: ID!, $quoteId: ID!, $input: UpdateQuoteInput!) {
    validateQuote(project_id: $project_id, quoteId: $quoteId, input: $input) {
      ${VALIDATE_QUOTE_DATA}
    }
  }
  ${GENERIC_RETURN_FRAGMENT}
  ${UPDATE_QUOTE_ERROR_FRAGMENT}
`;

export const VALIDATE_QUOTE_OPTIONS = (project_id, quoteId, input) => {
  const options = {
    variables: {
      project_id,
      quoteId,
      input,
    },
    fetchPolicy: 'no-cache',
  };

  return options;
};

export const UPDATE_QUOTE_PROPOSAL_EXPIRY_ON = gql`
  mutation updateQuoteProposalExpiryOn( $project_id: ID!, $quoteId: ID!  ,$expiryOn: String! ){
    updateQuoteProposalExpiryOn(project_id: $project_id, quoteId: $quoteId , expiryOn: $expiryOn){
      ${UPDATE_QUOTE_PROPOSAL_EXPIRY_ON_DATA}
    }
  }
`;

export const GET_CONTRACTS = gql`
  query getContracts($project_id: ID!) {
    getContracts(project_id: $project_id) {
      ${LEAN_QUOTE}
    }
  }
`;

export const GET_EXPIRED_CONTRACTS = gql`
  query getExpiredContracts($project_id: ID!) {
    getExpiredContracts(project_id: $project_id) {
      ${LEAN_QUOTE}
    }
  }
`;
export const GET_CONTRACTS_OPTIONS = (project_id) => {
  const options = {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'cache-and-network',
  };

  return options;
};

export const GET_QUOTES_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

export const GET_QUOTES = gql`
query getQuotesWithClients($project_id: ID!) {
  getQuotesWithClients(project_id: $project_id) {
    ${COMPLEX_QUOTE}
  }
}`;

export const GET_QUOTE_LISTA_PREVENTIVI = gql`
  query getQuote($project_id: ID!, $id: ID!) {
    getQuote(project_id: $project_id, id: $id) {
      ${LISTA_PREVENTIVI_QUOTE}
    }
  }`;

export const UPDATE_QUOTE_PROPOSAL_EXPIRY_ON_DEFAULT_OPTIONS = (project_id, quoteId, expiryOn) => {
  return {
    variables: {
      project_id,
      quoteId,
      expiryOn,
    },
  };
};
