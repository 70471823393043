import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Menu, Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { ListItemText } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Client from 'client/Client';
import CrmSimpleAvatar from 'components/common/CrmSimpleAvatar';
import { notificationManagerRoute, notificationDetailRoute } from 'routes/Routes';
import { useStore } from 'store/storeUtils.js';
import colors from 'styles/colors.scss';
import variables from 'styles/variables.scss';
import 'styles/alert.scss';

export const AlertMUITheme = {
  typography: {
    fontFamily: variables.DEFAULT_FONT,
    fontWeight: variables.LIGHT,
  },
  overrides: {
    MuiPaper: {
      root: {
        color: colors.DEFAULT_FONT_COLOR + '!important',
        borderRadius: '0px!important',
        zIndex: '0',
        border: '1px solid #E8E9EB',
        backgroundColor: 'white',
      },
      elevation1: {
        boxShadow: 'none!important',
        border: '0px!important',
      },
      elevation2: {
        boxShadow: 'none!important',
        border: '0px!important',
      },
      elevation4: {
        boxShadow: 'none !important',
      },
      elevation8: {
        boxShadow: 'none !important',
      },
      elevation24: {
        boxShadow: 'none !important',
      },
    },
    MuiMenu: {
      paper: {
        // '&::before,&::after': {
        //   content: "''",
        //   position: 'absolute',
        //   top: 0,
        //   left: '50%',
        //   // borderColor: 'black',
        //   // borderStyle: 'solid',
        //   width: '0',
        //   height: '0',
        //   borderLeft: '5px solid transparent',
        //   borderRight: '5px solid transparent',
        //   borderBottom: '5px solid white',
        // },
        // '&::before': {
        //   borderWidth: '8px',
        //   borderLeftColor: '#efefef',
        //   borderBottomColor: '#efefef',
        // },
        // '&::after': {
        //   borderRadius: '3px',
        //   borderWidth: '5px',
        //   borderLeftColor: '#fffff' /* color of the triangle */,
        //   borderBottomColor: '#fffff' /* color of the triangle */,
        // },
        width: '100px',
        borderRadius: '0px',
      },
      list: {
        padding: '0px!important',
        width: '100%!important',
      },
    },
    MuiMenuItem: {
      root: {
        // paddingLeft: '5px!important',
        width: '100%',
        height: '100px',
        textAlignLast: 'center',
        backgroundColor: '#FFFFFF !important',
        fontSize: '12px',
        border: '0px',
        borderLeft: '1px solid #DBDADA',
        borderRight: '1px solid #DBDADA',
        borderBottom: '1px solid #DBDADA',
        '&$selected': {
          backgroundColor: '#1E201F !important',
          color: 'white',
          border: '0px',
          width: '100%',
        },
        '&:hover': {
          backgroundColor: '#CACBD3!important',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.COLOR_PRIMARY,
    },
    secondary: {
      main: colors.COLOR_SECONDARY,
    },
  },
};

/**
 * Default avatar
 * based on material-ui Avatar
 * @component
 * @see {@link https://material-ui.com/api/avatar/ Avatar API}
 */
const CrmAlert = observer(() => {
  const intl = useIntl();
  const history = useHistory();
  const theme = createMuiTheme(AlertMUITheme);
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const alertButtonEl = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertsNumber, setAlertsNumber] = React.useState('');
  let lastAlerts = [];

  const store = useStore();
  const [alertsCall, alerts] = useLazyQuery(
    Client.GET_ALERTS,
    Client.GET_ALERTS_OPTIONS(store.projectId),
  );

  if (store.projectId && !alerts.called) {
    alertsCall();
  }

  React.useEffect(() => {
    if (alerts?.data?.getAlerts) {
      lastAlerts = alerts.data.getAlerts;
      setAlertsNumber(lastAlerts.length);
    }
    return () => {
      setAlertsNumber({});
    };
  }, [alerts.data]);

  React.useEffect(() => {
    if (alerts.error && !alerts.error.message.includes('Observable cancelled prematurely')) {
      store.setSnackbarError(
        true,
        intl.formatMessage({
          id: 'snackbar.errorUploadApartment',
          defaultMessage: 'Error while loading the apartments',
        }),
      );
    }
    // eslint-disable-next-line
  }, [alerts.error]);

  const openMenu = (e) => {
    if (!anchorEl) {
      setAnchorEl(e.target);
    }
    store.setAlertMenu(true);
  };

  const close = () => {
    store.setAlertMenu(false);
  };

  const goToNotificationManager = () => {
    const destination = notificationManagerRoute;
    // store.setBreadCrumbs(props.title);
    store.setRealLocation(destination.to);
    history.push(destination.to);
    store.setAlertMenu(false);
  };

  const goToNotificationDetail = (el) => {
    store.setSelectedNotification(el);
    store.setClientId(el.client.id);
    store.setSelectedNotificationStatus(el.status);
    const destination = notificationDetailRoute.to.replace(':id', el.id);
    store.setRealLocation(destination);
    history.push(destination);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="alert-wrapper">
          <Button
            aria-controls="simple-menu"
            className="user-button"
            onClick={!matchesPhone ? openMenu : goToNotificationManager}
            disableRipple={true}
            ref={alertButtonEl}
          >
            <div className="alert-header">Alerts</div>
            <div className="alert-number">
              {alertsNumber ? (alertsNumber > 99 ? '99+' : alertsNumber) : ''}
            </div>
          </Button>
          {store?.alertMenu && (
            <Menu
              id="menu2"
              anchorEl={alertButtonEl.current}
              keepMounted
              open={store.alertMenu}
              onClose={close}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              classes={{
                paper: 'no-border user-option-selection',
              }}
            >
              {alerts?.data?.getAlerts.map((item, i) => {
                if (i < store.alertDays) {
                  const date = new Date(item.createdOn);
                  const diff = moment().diff(moment(date), 'days');
                  return (
                    <>
                      <MenuItem
                        classes={{
                          root: 'inline-block',
                        }}
                        onClick={() => {
                          close();
                          goToNotificationDetail(item);
                        }}
                      >
                        <CrmSimpleAvatar
                          src={item.client.avatar}
                          style={{ width: '25px', height: '25px' }}
                        ></CrmSimpleAvatar>
                        <ListItemText key={i} style={{ marginLeft: '15px' }}>
                          {' '}
                          <div className="alert-status" style={{ color: '#DB7A7D' }}>
                            <FormattedMessage id="menu.incoming" defaultMessage="Incoming" />
                          </div>
                          <span style={{ fontFamily: 'SagoeBold', fontSize: '0.9rem' }}>
                            {item.client.firstName
                              ? item.client.firstName + ' ' + item.client.lastName
                              : intl.formatMessage({
                                  id: 'notification.userDeleted',
                                  defaultMessage: 'User deleted',
                                })}
                          </span>{' '}
                          {intl.formatMessage({
                            id: 'notificationMessage.message',
                            defaultMessage: 'sent a notification',
                          })}
                          <div className="alert-date">
                            <span>
                              {' '}
                              {diff}{' '}
                              {intl.formatMessage({
                                id: 'label.notificationDate',
                                defaultMessage: 'days ago at ',
                              })}{' '}
                              {moment(date).format('HH:mm')}
                            </span>
                          </div>
                        </ListItemText>
                      </MenuItem>
                    </>
                  );
                }
              })}
              <MenuItem
                classes={{
                  root: 'inline-block',
                }}
                style={{ justifyContent: 'center', height: '40px' }}
                onClick={goToNotificationManager}
              >
                <div className="view-alerts">
                  <FormattedMessage
                    id="menu.notifications"
                    defaultMessage="View All Notifications"
                  />
                </div>
              </MenuItem>
            </Menu>
          )}
        </div>
      </ThemeProvider>
    </>
  );
});

export default CrmAlert;
